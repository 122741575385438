import PropTypes from "prop-types";
import { m } from "framer-motion";
// @mui
import styled from "styled-components";
//
import Logo from "./Logo";
import { Progress, Result } from "antd";
import { ClockCircleOutlined } from "@ant-design/icons";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";

// ----------------------------------------------------------------------

const RootStyle = styled.div`
  right: 0;
  bottom: 0;
  zindex: 99999;
  width: 100%;
  height: 100%;
  position: fixed;
  display: flex;
  alignitems: center;
  justifycontent: center;
  backgroundcolor: red;
`;

// ----------------------------------------------------------------------

LoadingScreen.propTypes = {
  isDashboard: PropTypes.bool,
};

export default function LoadingScreen({ isDashboard, ...other }) {

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <div
      style={{
        minHeight: "100vh",
      }}
    >
      <Result
        title="Cargando..."
        
        icon={<ClockCircleOutlined className="rotate"/>}
        
      />
    </div>
  );
}
