// @mui
import { Grid } from "antd";
import { useLayoutEffect } from "react";

const { useBreakpoint } = Grid;

export default function useResponsive() {
  const screens = useBreakpoint();
  let islg = screens.lg;
  let ismd = screens.md;
  let issm = screens.sm;
  let isxl = screens.xl;
  let isxs = screens.xs;
  return { islg, ismd, issm, isxl, isxs };
}
