import { notification } from "antd";
import axios from "axios";
// config
import { API_BACK } from "../config";
import { PATH_PAGE } from "../routes/paths";

// ----------------------------------------------------------------------

const MES = ({ comp }) => <div>{comp}</div>;
const urlNotToast = [PATH_PAGE.video_start_with_lawyer,PATH_PAGE.programing_video];
const axiosInstance = axios.create({
   baseURL: API_BACK,
  //baseURL: 'https://milicadm-stage.softok2.com/api/v1',
});

export const axiosInstanceLocal = axios.create({
  baseURL: "http://192.168.1.105:5000",
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) =>
    Promise.reject(
      (error.response && error.response.data) || "Something went wrong"
    )
);

const handleErrors = (e) => {
  if (e.errors) {
    notification.error({
      message: "Se encontro un problema al procesar los datos",
      description: (
        <>
          {Object.keys(e.errors).map((k, i) => (
            <div key={i}>
              &nbsp; - &nbsp; {e.errors[k][0]}
              <br />
            </div>
          ))}
        </>
      ),
    });
  }
};

let config = {};

export const processActionService = (
  route,
  method,
  payload,
  isMultipart = false
) => {
  return new Promise((resolve, reject) => {
    try {
      if (isMultipart)
        config = {
          headers: {
            "content-type": "multipart/form-data",
          },
        };
      else {
        config = {
          headers: {},
        };
      }
      axiosInstance[method](route, payload, config)
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          // console.log(e);
          if (e && e.response) {
            const { data, status } = e.response;

            if (status == 400 || status == 422) handleErrors(data);
            else {
              notification.error({
                message: "Se encontro un problema al procesar los datos",
                description: data.message,
              });
            }
          } else {
            if (!urlNotToast.includes(window.location.pathname)) {
              if (e && e.errors) {
                handleErrors(e);
              } else {
                if (e && e.error_code == 1 && e.message) {
                  if (typeof e.message === "string") {
                    reject(e);
                    notification.error({
                      message: "Se encontró un problema al procesar los datos",
                      description: e.message 
                    })
                  } else {
                    notification.error({
                      message: "Se encontró un problema al procesar los datos",
                      description: (
                        <>
                          {Object.keys(e.message).map((k, i) => (
                            <div key={i}>
                              &nbsp; - &nbsp; {e.message[k][0]}
                              <br />
                            </div>
                          ))}
                        </>
                      ),
                    });
                  }
                } else {
                  //   console.log(e);
                  notification.error({
                    message: "Se encontro un problema al procesar los datos",
                    description: JSON.stringify(e),
                  });
                }
              }
            }
          }
          reject(e);
        });
    } catch (error) {
      if (!urlNotToast.includes(window.location.pathname)) {
        notification.error({
          message: "Se encontro un problema al procesar los datos",
          description: JSON.stringify(error),
        });
      }
    }
  });
};

export default axiosInstance;
