// @antd
import { ClockCircleOutlined } from "@ant-design/icons";
import { Button, Col, Result, Row, Typography } from "antd";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Page from "../../components/Page";
import { PayProvider } from "../../contexts/PayContext";
import useAuth from "../../hooks/useAuth";
import usePay from "../../hooks/usePay";
import { PATH_PAGE } from "../../routes/paths";

function CompletePayFailedComponent() {
  const { checkPay, payPending, payInfo } = usePay();
  const [orderId, setOrderId] = useState(null);
  const { isAuthenticated } = useAuth();

  const [title, setTitle] = useState(
    "Pago de servicio completado exitosamente"
  );

  const [body, setBody] = useState(
    " ¡Hola! Tu servicio fue realizado exitosamente."
  );

  const location = useLocation();
  useEffect(() => {
    if (location.search) {
      setOrderId(new URLSearchParams(location.search).get("order_id"));
    }
  }, [location]);

  useEffect(() => {
    if (orderId) {
      //   console.log(payInfo);
      let tl = "Pago de servicio completado exitosamente";
      let bl = " ¡Hola! Tu servicio fue realizado exitosamente.";
      if (orderId) {
        if (orderId.indexOf("document_for_reviewing") != -1) {
          bl =
            "¡Hola! Tu servicio fue solicitado y será realizado conforme a los tiempos contratados. El tiempo empezará a correr una vez que revisemos que tu documento es legible y coincide con las páginas pagadas.";
          tl = "Documentación enviada exitosamente";
        }

        if (orderId.indexOf("contract") != -1)
          tl = "Contrato creado exitosamente";
        if (orderId.indexOf("consultation") != -1) {
          tl = "Consulta creada exitosamente";
          bl =
            "¡Hola! Tu solicitud de asesoría fue generada exitosamente y se te entregará en el tiempo solicitado.";
        }
        if (orderId.indexOf("videoconsulting") != -1)
          tl = "Consulta creada exitosamente";
      }
      setTitle(tl);
      setBody(bl);
    }
  }, [orderId]);

  return (
    <Page title="Mi-LIC | Pago Completado">
      <Row>
        <Col style={{ margin: "auto" }} xl={12} lg={12} md={24} sm={24} xs={24}>
          <section
            style={{
              marginTop: 30,
              marginBottom: 30,
              padding: "4% 10% 4% 10%",
            }}
          >
            <div>
              <Result
                status="error"
                title="Error durante el pago"
                subTitle={
                  <>
                    <Typography.Text style={{ fontSize: 16 }}>
                      El proceso de pago del servicio ha fallado, por favor
                      inténtelo más tarde
                    </Typography.Text>
                  </>
                }
                extra={[
                  <Button href={"/"} type="primary" key="console">
                    <Typography.Text style={{ color: "#FFF" }}>
                      {" "}
                      Ir al inicio{" "}
                    </Typography.Text>
                  </Button>,

                  ,
                ]}
              />
            </div>
          </section>
        </Col>
      </Row>
    </Page>
  );
}

export default function CompletePayFailed() {
  return (
    <PayProvider>
      <CompletePayFailedComponent />
    </PayProvider>
  );
}
