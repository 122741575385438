// @antd
import {
  Button,
  Form,
  Steps,
  InputNumber,
  Input,
  DatePicker,
  Space,
  Select,
  Grid,
  Row,
  Col,
} from "antd";

import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { getRules } from "../../utils/rules";

export const MultipleInput = ({
  label,
  name,
  value,
  disabled,
  onChange,
  entrie,
}) => {
  //type
  const FieldType = ({ type, placeholder }) => {
    switch (type) {
      case "texto":
        return (
          <Input disabled={disabled} size="large" placeholder={placeholder} />
        );
        break;
      case "numerico":
        return (
          <InputNumber type="number"
            disabled={disabled}
            style={{ width: 125 }}
            size="large"
            placeholder={placeholder}
          />
        );
        break;
      case "moneda":
        return (
          <InputNumber type="number"
            disabled={disabled}
            style={{ width: 125 }}
            size="large"
            prefix="$"
          />
        );
        break;
      case "fecha":
        return (
          <DatePicker placeholder={"Fecha"} disabled={disabled} size="large" />
        );
        break;
      default:
        return (
          <Input disabled={disabled} size="large" placeholder={placeholder} />
        );
        break;
    }
  };
  if (entrie.variable.primary_type === "compuesto")
    return (
      <Form.List name={name}>
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }, index) => (
              <Row wrap key={key} className="multi-field" align="baseline">
                {entrie.variable.childrens.map((children, i) => (
                  <Col xxl={12} xl={12} lg={24} md={24} sm={24} xs={24}>
                    <Form.Item
                      wrapperCol={24}
                      style={{ width: "100%" }}
                      disabled={disabled}
                      {...restField}
                      key={i + "-" + key}
                      name={[name, children.id + ""]}
                      validateTrigger={["onChange", "onBlur"]}
                      rules={getRules(children.rules)}
                    >
                      {FieldType({
                        type: children.primary_type,
                        placeholder: children.name + " #" + (index + 1),
                      })}
                    </Form.Item>
                  </Col>
                ))}

                 

                {!disabled && (
                  <div className="delete-item" size={2}>
                    <MinusCircleOutlined onClick={() => remove(name)} />
                  </div>
                )}
              </Row>
            ))}
            <Form.Item>
              <Button
                disabled={disabled}
                type="dashed"
                onClick={() => add()}
                block
                icon={<PlusOutlined />}
              >
                Adicionar {label}
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
    );
  else
    return (
      <Form.List name={name}>
        {(fields, { add, remove }, { errors }) => (
          <>
            {fields.map((field, index) => (
              <div key={field.key + "-" + index}>
                <Form.Item
                  label={label + " #" + (index + 1)}
                  required={true}
                  key={field.key}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      height: 30,
                    }}
                  >
                    <Form.Item
                      {...field}
                      validateTrigger={["onChange", "onBlur"]}
                      rules={getRules(entrie.variable.rules)}
                    >
                      {FieldType({
                        type: entrie.variable.primary_type,
                        placeholder: label + " #" + (index + 1),
                      })}
                    </Form.Item>
                    {fields.length > 1 ? (
                      <MinusCircleOutlined
                        className="icon-field"
                        onClick={() => remove(field.name)}
                      />
                    ) : null}
                  </div>
                </Form.Item>
              </div>
            ))}
            <Form.Item>
              <Button
                type="dashed"
                onClick={() => add()}
                icon={<PlusOutlined />}
              >
                Adicionar {label}
              </Button>
              <Form.ErrorList errors={errors} />
            </Form.Item>
          </>
        )}
      </Form.List>
    );
};
