export const getRules = (rules = [], options = {}) => {
  let transformRules = [];
  rules.forEach((rule) => {
    switch (rule) {
      case "required":
        transformRules.push({
          required: true,
          message: "Este campo es requerido",
        });
        break;
      case "string":
        transformRules.push({
          type: "string",
          message: "Este campo tiene que ser una cadena de texto",
        });
        break;
      case "numeric":
        transformRules.push({
          type: "number",
          message: "Este campo tiene que ser númerico",
        });
        break;

      case "email":
        transformRules.push({
          type: "email",
          message: "Este campo tiene que ser un correo electronico",
        });
        break;
      case "url":
        transformRules.push({
          pattern:
            /^(?:http(s)?:\/\/)[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/,
          message: "Este campo tiene que ser una url válida",
        });
        break;
        case "phone":
          transformRules.push({
            pattern:
            /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g,
            message: "Este campo tiene que ser una número de télefono válido",
          });
          break;
      case "true":
        transformRules.push({
          pattern: /true/,
          message: "Este campo es requerido",
        });
        break;
      default:
        if (rule && typeof rule === "string" && rule.indexOf("min") !== -1) {
          let count = rule.split("_")[1];
          transformRules.push({
            min: parseInt(count),
            message: "Este campo tiene que ser mayor a " + count,
          });
        }
        if (rule && typeof rule === "string" && rule.indexOf("max") !== -1) {
          let count = rule.split("_")[1];
          transformRules.push({
            max: parseInt(count),
            message: "Este campo tiene que ser menor a " + count,
          });
        }
        break;
    }
  });
 
  return transformRules;
};
