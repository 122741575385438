export const OPENPAY_ERROR = {
  "cvv2 length must be 3 digits":
    "El código debe tener una longitud de 3 dígitos",
  "cvv2 length must be 4 digits":
    "El código debe tener una longitud de 4 dígitos",
  "The card number verification digit is invalid":
    "El código de verificación del número de tarjeta no es válido",
  "card_number must contain only digits":
    "El número de tarjeta debe contener solo dígitos",
  "Fraud risk detected by anti-fraud system":
    "Riesgo de fraude detectado por el sistema antifraude",
  "The card was reported as stolen": "La tarjeta fue reportada como robada",

  "cvv2 must contain only digits": "El código debe contener solo dígitos"
};
