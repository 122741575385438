import PropTypes from "prop-types";
import { Helmet } from "react-helmet-async";
import { forwardRef, useEffect } from "react";
import { useLocation } from "react-router-dom";

// ----------------------------------------------------------------------

const Page = forwardRef(({ children, title = "", meta, ...props }, ref) => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <Helmet>
        <title>{`${title} | Mi Lic`}</title>
        {meta}
      </Helmet>
      <div className="content-page" ref={ref} {...props}>
        {children}
      </div>
    </>
  );
});

Page.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  meta: PropTypes.node,
};

export default Page;
