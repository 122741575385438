// @antd
import { Col, Row, InputNumber } from "antd";

export const NumericInput = (
  { value, disabled, onChange, min = 1, max = 10000000,addonAfter='' },
  props
) => (
  <>
    <Row gutter={10}>
      <Col span={24}>
        <InputNumber
          disabled={disabled}
          min={min}
          max={max}
          type="number"
          size="large"
          style={{ width: 125 }}
          onChange={(v) => onChange(v)}
          value={value}
          addonAfter={addonAfter}
          {...props}
        />
      </Col>
    </Row>
  </>
);
