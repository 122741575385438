import { Link, useLocation, useNavigate } from "react-router-dom";

// hooks
import useAuth from "../../hooks/useAuth";

// utils
// config
import { useEffect, useState } from "react";
import { PATH_AUTH, PATH_PAGE } from "../../routes/paths";
import { Avatar, Dropdown, Menu, Skeleton, Typography } from "antd";
import {
  CommentOutlined,
  DiffOutlined,
  ExclamationCircleOutlined,
  HomeOutlined,
  MailOutlined,
  SnippetsOutlined,
  VideoCameraOutlined,
  CalculatorOutlined,
  UserOutlined,
  FilePdfOutlined,
  UsergroupAddOutlined,
  LogoutOutlined,
} from "@ant-design/icons";
import { cleanStorage, fromStorage } from "../../utils/storage";

/*
{
            label: (
              <Link to={PATH_PAGE.programing_video} onClick={openMenu} >
                <VideoCameraOutlined style={{ color: "#EFAc51" }} />
                <Typography.Text style={{ color: "#FFF" }}>
                  {" "}
                  Mis Videollamadas
                </Typography.Text>
              </Link>
            ),
            key: "1231",
          },
*/

// ----------------------------------------------------------------------

export default function MainHeader() {
  const {
    user,
    logout,
    fetchAccount,
    fetchServices,
    sections,
    pendingSection,
    fetchSections,
  } = useAuth();

  const [hasFetched, setHasFetched] = useState(false);
  useEffect(() => {
    if (!hasFetched) {
      fetchServices();
      fetchSections();
      if (fromStorage("accessToken")) fetchAccount();
      setHasFetched(true);
    }
  }, [hasFetched, fetchServices, fetchSections, fetchAccount]);

 

  
  const openMenu = () => {
    let toggleButton = document.getElementById("header-menu-toggle");
    let nav = document.getElementById("header-nav-wrap");
    if (toggleButton) {
      toggleButton.classList.toggle("is-clicked");
      if (nav.style.display === "block") {
        nav.style.display = "none";
      } else {
        nav.style.display = "block";
      }
    }
  };

  const renderDinamicMenu = () => {
    const getIconByUri = (uri, isCompose = false) => {
      if (isCompose) return <CommentOutlined style={{ color: "#EFAc51" }} />;
      switch (uri) {
        case "contratos-disponibles":
          return <DiffOutlined style={{ color: "#EFAc51" }} />;
        case "revisar-documentos":
          return <SnippetsOutlined style={{ color: "#EFAc51" }} />;
        case "establecer-comunicacion-con-abogado":
          return <CommentOutlined style={{ color: "#EFAc51" }} />;

        case "establecer-videollamada-con-abogado":
          return <VideoCameraOutlined style={{ color: "#EFAc51" }} />;

        case "calculo-finiquito":
          return <CalculatorOutlined style={{ color: "#EFAc51" }} />;

        case "contactanos":
          return <MailOutlined style={{ color: "#EFAc51" }} />;

        case "sobre-nosotros":
          return <ExclamationCircleOutlined style={{ color: "#EFAc51" }} />;

        case "perfil-de-usuario":
          return <UserOutlined style={{ color: "#EFAc51" }} />;
        case "archivero":
          return <FilePdfOutlined style={{ color: "#EFAc51" }} />;
        case "mis-contactos":
          return <UsergroupAddOutlined style={{ color: "#EFAc51" }} />;
        case "video-llamadas-programadas":
          return <VideoCameraOutlined style={{ color: "#EFAc51" }} />;
        case "cerrar-sesion":
          return <LogoutOutlined style={{ color: "#EFAc51" }} />;
        default:
          return <HomeOutlined style={{ color: "#EFAc51" }} />;
      }
    };

    const getItemByGroup = (subItems) => {
      let item = [];
      subItems.forEach((e, i) => {
        item.push({
          label: (
            <Link
              key={i + "LI123"}
              to={e.uri === "cerrar-sesion" ? PATH_PAGE.root : e.uri}
              onClick={() => {
                if (e.uri === "cerrar-sesion") {
                  logout();
                }
                openMenu();
              }}
            >
              {getIconByUri(e.uri)}
              <Typography.Text style={{ color: "#FFF" }}>
                {" "}
                {e.name}
              </Typography.Text>
            </Link>
          ),
          key: i + "gr",
        });
      });
      return item;
    };

    return (
      <>
        {sections.map((e, i) => {
          if (e.subItems.length === 0)
            return (
              <li key={i} onClick={openMenu}>
                <Link to={e.uri}>
                  {getIconByUri(e.uri)}
                  <Typography.Text style={{ color: "#FFF" }}>
                    {" "}
                    {e.name}
                  </Typography.Text>
                </Link>
              </li>
            );
          else
            return (
              <li key={i} style={{ color: "#FFF" }}>
                <Dropdown
                  overlay={
                    <div style={{ width: 220 }}>
                      <Menu
                        style={{ backgroundColor: "#002448" }}
                        className="menu-bottom"
                        items={getItemByGroup(e.subItems)}
                      />
                    </div>
                  }
                  trigger={["click"]}
                >
                  <Link to={"#"} onClick={(e) => e.preventDefault()}>
                    {e.group !== "__profile" ? getIconByUri(e.uri) : ""}
                    <Typography.Text style={{ color: "#FFF" }}>
                      {" "}
                      {e.group !== "__profile" ? e.group : ""}
                      {e.group === "__profile" && user ? user.name : ""}
                      {e.group === "__profile" && user ? (
                        <Avatar style={{ marginLeft: 10 }} src={user.avatar} />
                      ) : (
                        ""
                      )}
                    </Typography.Text>
                  </Link>
                </Dropdown>
              </li>
            );
        })}
      </>
    );
  };

  return (
    <header id="header" className="row" style={{ position: "fixed" }}>
      <div title="Mi-LIC | Web" className="header-logo">
        <a href={PATH_PAGE.root}>.</a>
      </div>

      <nav id="header-nav-wrap">
        <ul className="header-main-nav">
          {renderDinamicMenu()}
          {/*!pendingSection && (
            <a
              key={"member"}
              href={"/#member"}
              onClick={() => {
                openMenu();
              }}
            >
              <UsergroupAddOutlined style={{ color: "#EFAc51" }} />
              <Typography.Text style={{ color: "#FFF" }}>
                {" "}
                Membresia
              </Typography.Text>
            </a>
            )*/}
          {pendingSection && (
            <>
              <Skeleton.Avatar
                style={{ width: 150, height: 20, marginTop: 20 }}
                active={true}
                size={"large"}
                shape={"square"}
              />

              <Skeleton.Avatar
                style={{
                  width: 150,
                  height: 20,
                  marginLeft: 10,
                  marginTop: 20,
                }}
                active={true}
                size={"large"}
                shape={"square"}
              />

              <Skeleton.Avatar
                style={{
                  width: 150,
                  height: 20,
                  marginLeft: 10,
                  marginRight: 10,
                  marginTop: 20,
                }}
                active={true}
                size={"large"}
                shape={"square"}
              />
            </>
          )}
          
        </ul>

        {!user && sections.length > 0 && (
          <Link
            onClick={() => {
              cleanStorage("REDIRECT");
              openMenu();
            }}
            to={PATH_AUTH.login}
            title="sign-up"
            className="button button-white cta"
          >
            Iniciar sesion
          </Link>
        )}
      </nav>

      <span
        onClick={openMenu}
        id="header-menu-toggle"
        className="header-menu-toggle"
        href="/"
      >
        <span>Menu</span>
      </span>
    </header>
  );
}
