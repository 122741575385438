import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage";

// ----------------------------------------------------------------------




const initialState = {
  contacts: [],
  contact: {},
};

const cartReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_CONTACT":
      return {
        ...state,
        contacts: action.payload,
      };
    default:
      return state;
  }
}


const rootPersistConfig = {
  key: "root",
  storage,
  keyPrefix: "redux-",
  whitelist: [],
};

const rootReducer = combineReducers({cartReducer});

export { rootPersistConfig, rootReducer };
