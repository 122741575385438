import jwtDecode from "jwt-decode";
// routes
import { PATH_AUTH } from "../routes/paths";
//
import axios from "./axios";
import { cleanStorage, fromStorage, toStorage } from "./storage";

// ----------------------------------------------------------------------

const isValidToken = (accessToken) => {
  if (!accessToken) {
    return false;
  }
  const decoded = jwtDecode(accessToken);

  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

const handleTokenExpired = (exp) => {
  let expiredTimer;
  const currentTime = Date.now();
  // Test token expires after 10s
  // const timeLeft = currentTime + 10000 - currentTime; // ~10s
  const timeLeft = exp * 1000 - currentTime;
  clearTimeout(expiredTimer);
  expiredTimer = setTimeout(() => {
    // eslint-disable-next-line no-alert
    alert("Token expired");
    cleanStorage("accessToken");
    window.location.href = PATH_AUTH.login;
  }, timeLeft);
};

const setSession = (accessToken) => {
  if (accessToken) {
    toStorage("accessToken", accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

    // This function below will handle when token is expired
    const { exp } = jwtDecode(accessToken); // ~5 days by minimals server

    //handleTokenExpired(exp);
  } else {
    cleanStorage("accessToken");
    cleanStorage("milic-userdata");
    delete axios.defaults.headers.common.Authorization;
  }
};

const setUserSession = (user) => {
  if (user) {
    toStorage("milic-userdata", JSON.stringify(user));
  } else {
    cleanStorage("milic-userdata");
    cleanStorage("accessToken");
    delete axios.defaults.headers.common.Authorization;
  }
};

const getUserSession = () => {
  try {
    if (fromStorage("milic-userdata")) {
      return JSON.parse(fromStorage("milic-userdata"));
    }
    return null;
  } catch (error) {
    return null;
  }
};

export const OPEN_PAY_ID = process.env.REACT_APP_OPEN_PAY_ID;
export const OPEN_PAY_APIKEY = process.env.REACT_APP_OPEN_PAY_APIKEY;
export const OPEN_PAY_MODE_DEV = process.env.REACT_APP_OPEN_PAY_MODE_DEV=="1";
//console.log(OPEN_PAY_MODE_DEV)

export const INITIALICE_OPENPAY = () => {
  cleanStorage("email");
};
export { isValidToken, setSession, setUserSession, getUserSession };
