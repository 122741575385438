// @antd
import { Steps, Select, Typography, Checkbox, Row, Col } from "antd";

const { Option, OptGroup } = Select;

export const OptGroupInput = ({
  mode,
  disabled,
  optionGroup,
  value,
  onChange,
}) => {
  return (
    <Checkbox.Group value={value} style={{ width: "100%" }} onChange={onChange}>
      {optionGroup.map((og, index) => (
        <div key={index} style={{ marginBottom: 10 }}>
          <fieldset>
            <legend style={{fontWeight:'bold'}}>
              {" "}
              <Typography.Text    style={{ fontSize:14, fontFamily: "montserrat-regular" }}>
                {og.header}
              </Typography.Text>
            </legend>
          </fieldset>

          <Row>
            {og.options.map((opt, indexOpt) => (
              <Col key={index + "-" + indexOpt} span={12}>
                <Checkbox value={opt.label}>
                  <Typography.Text type="secondary"  style={{ fontFamily: "montserrat-regular" }}>
                    {" "}
                    {opt.label}
                  </Typography.Text>
                </Checkbox>
              </Col>
            ))}
          </Row>
        </div>
      ))}
    </Checkbox.Group>
  );
};
{
  /* 
        {optionGroup.map((og, index) => (
          <OptGroup key={index} label={og.header}>
            {og.options.map((opt, indexOpt) => (
              <Option key={indexOpt + "-" + index} value={opt.label}>
                {opt.label}
              </Option>
            ))}
          </OptGroup>
        ))} 

    {JSON.stringify(optionGroup)}
      <Select
        disabled={disabled}
        mode={mode}
        style={{ width: "100%" }}
        value={value}
        onChange={onChange}
      >
        {optionGroup.map((og, index) => (
          <OptGroup key={index} label={og.header}>
            {og.options.map((opt, indexOpt) => (
              <Option key={indexOpt + "-" + index} value={opt.label}>
                {opt.label}
              </Option>
            ))}
          </OptGroup>
        ))}
      </Select>
    </>
  
  );
};
  */
}
