import { useLocation, Outlet } from "react-router-dom";
// @mui

// components
import Logo from "../../components/Logo";
//

import { Layout, Menu, Breadcrumb } from "antd";
import MainHeader from "./MainHeader";
import MainFooter from "./MainFooter";

const { Header, Content, Footer } = Layout;
// ----------------------------------------------------------------------

export default function MainLayout() {
  const { pathname } = useLocation();
  const isHome = pathname === "/";
  return (
    <Layout sx={{ minHeight: 1 }}>
      <MainHeader />
      <Content style={{marginTop:72 }} >
        <Outlet />
      </Content>
   
      <MainFooter />
    </Layout>
  );
}
