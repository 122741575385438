// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

export const URL_SITE = process.env.REACT_APP_PUBLIC_URL;
const ROOTS_AUTH = "/web";
const ROOT = "/";

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, "/iniciar-sesion"),
  register: path(ROOTS_AUTH, "/registrar"),
  loginUnprotected: path(ROOTS_AUTH, "/login-unprotected"),
  registerUnprotected: path(ROOTS_AUTH, "/register-unprotected"),
  verify: path(ROOTS_AUTH, "/verify"),
  forgotPassword: path(ROOTS_AUTH, "/clase-de-sesion-olvidada"),
};

export const PATH_PAGE = {
  root: ROOT,
  term: "/terminos-y-condiciones",
  privacy: "/privacidad",

  //Contract
  marketplace: "/contratos-disponibles",
  create_contact: "/crear-contracto",
  review: "/revisar-documentos",
  review_other: "/subir-nuevo-documento",
  create_contract_successfull: "/contrato-creado-exitosamente",

  review_contract_successfull: "/documento-enviado-exitosamente",
  review_pay: "/pago-por-servicio",

  //Consulting
  write_with_lawyer_successfull:
    "/establecer-comunicacion-con-abogado-creada-exitosamente",
  video_with_lawyer_successfull:
    "/establecer-videollamada-con-abogado-creada-exitosamente",

  write_with_lawyer: "/establecer-comunicacion-con-abogado",
  video_with_lawyer: "/establecer-videollamada-con-abogado",
  reprograme_video_with_lawyer: "/reprogramar-videollamada-con-abogado",
  programing_video: "/video-llamadas-programadas",

  video_start_with_lawyer: "/establecer-videollamada",

  video_start: "/comenzar-videollamada",

  consulting_service: "/servicio-consultas",

  //Settlements
  settlements: "/calculo-finiquito",
  settlements_create_successfull: "/calculadora-laboral-creada-exitosamente",
  //User Info
  profile: "/perfil-de-usuario",
  archivero: "/archivero",
  resetPassword: "/restaurar-clave-de-session",
  contact_list: "/mis-contactos",

  //Common
  page403: "/403",
  page404: "/recurso-no-encontrado",
  page500: "/500",
  aboutus: "/sobre-nosotros",
  contactus: "/contactanos",
  complete_service: "/pago-en-proceso",
  complete_pay: "/estado-del-pago-de-servicio",
  complete_pay_success: "/pago-de-servicio-completado",
  complete_pay_failed: "/pago-de-servicio-ha-fallado",


};
