// routes
import Router from "./routes";
// components
import MotionLazyContainer from "./components/animate/MotionLazyContainer";
import React, { useEffect } from "react";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { toStorage } from "./utils/storage";
import { Modal } from "antd";
import { closeMedia } from "./utils/common";
export default function App() {
  /*
  toStorage(
    "accessToken",
    "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIzIiwianRpIjoiYjlkMmRhMmZhNDUwNDVjNmFiZGE1YjMwODQ4ODg2NDdhMTkyNjAzMDVlYzEzMDZmNjYxZTg0MTJkNWIyZTE3MTI4ZGM1ZDM5MTc2NDkzNDUiLCJpYXQiOjE2Nzk0NDkzODMuNzMzNDYsIm5iZiI6MTY3OTQ0OTM4My43MzM0NjQsImV4cCI6MTY5NTM0MzM4My43Mjg2NjMsInN1YiI6IjQ3Iiwic2NvcGVzIjpbXX0.Titmugm4l679ne2qxYt0bbzqoCljIx0PpZzK4MvXDWKxU99Ev_Lu9yYx9g53zPv5CAItS61yvlpnQTQxQ2UqUm0kB8TI4gOsa8ex6LkFCAcU4Z8pN7wO1ZBAhJS3Gv-NYD1zjbyYDq1dq2IV7hZ6focmVydvHdKNslsZgp7fcebdse2svDbDYWRRQo-kXLHLvE7V5EAVgPkF4DYp0Chrt_XkcrYHV-AOhYfiEFGqB0FYxJ7tsjyz8S13IjRpvPw4RL07G9QTl7m1-iVJv8BmowpP8HoHnY1V83nnj2tcGRtkZpdGuKpVTklJE8CNadfx3t_bZeE7R8RH7DoioB0N7AsnHA_psaVWP_71RtCG3b97NOzgdR4W-j77lKAF9K3fPu3gpQ8X-iF9RBP58STAWB1cZXPzQHNHxLSrZt5qpXtcheGKifYo7Qq0ufUP7h_nlXQJ9lwCzkV_1ZQAnoT6gM2SmHXFEXCcTxpkQ-ptzQT17PTYsw8W_N10vZkSx36_RK4KRGgBYxf7yWubY7Vy-9OIri9oHHKa_xo1N-aGq-QVR0tj09AxNNkP-Zfp8Dx4SBm4pdhS6g5Hs5LUzgVlWdPelvrGs6Raaxq2WdciEzxBNle7WNWaYNkepSFed8g8yZDMIKpPB0hupqLQct0V0sHfru5q4s1yDi1SYECsjqI"
  );
  toStorage(
    "milic-userdata",
    JSON.stringify({
      id: 47,
      name: "Yasiel Perez Villazon",
      email: "yasielpv1503@gmail.com",
      avatar: {},
      subscription: null,
      active_subscription: null,
    })
  );
  */

  const initFacebook = () => {};
  useEffect(() => {
    initFacebook();
    closeMedia();
  }, []);
  return (
    <MotionLazyContainer>
      <GoogleOAuthProvider clientId={process.env.REACT_APP_CLIENT_ID_GOOGLE}>
        <Router />
      </GoogleOAuthProvider>
    </MotionLazyContainer>
  );
}
