// @antd
import {
    Steps,
    Col,
    Row,
    InputNumber,
    Select,
  } from "antd";
  
 
   
export const PriceInput = ({ value,disabled, onChange }) => (
    <>
      <Row gutter={10}>
        <Col span={24}>
          <InputNumber type="number"
            size="large"
            prefix="$"
            min={1}
            disabled={disabled}
            style={{ width: 125 }}
            onChange={(v) => onChange(v)}
            value={value}
          />
        </Col>
      </Row>
    </>
  );