import PropTypes from "prop-types";
import { Navigate } from "react-router-dom";
// hooks
import useAuth from "../hooks/useAuth";
import { PATH_PAGE } from "../routes/paths";
// routes

// ----------------------------------------------------------------------

FreeAuthGuard.propTypes = {
  children: PropTypes.node,
};

export default function FreeAuthGuard({ children }) {
  return <>{children}</>;
}
