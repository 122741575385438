// @antd
import { Steps, Select, Typography, Checkbox, Row, Col, Modal } from "antd";
import { useState } from "react";

export const CheckInput = ({
  disabled,
  value,
  color = "#efac51",
  onChange,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const term = () => {
    Modal.info({
      title: null,
      icon: null,
      width: (90 * window.innerWidth) / 100,
      content: (
        <div
          style={{
            overflowY: "scroll",
            height: (60 * window.innerHeight) / 100,
          }}
        >
          {" "}
          <Term />
        </div>
      ),
      onOk() {},
    });
  };

  const privacy = () => {
    Modal.info({
      title: null,
      icon: null,
      width: (90 * window.innerWidth) / 100,
      content: (
        <div
          style={{
            overflowY: "scroll",
            height: (60 * window.innerHeight) / 100,
          }}
        >
          {" "}
          <Cond />
        </div>
      ),
      onOk() {},
    });
  };

  return (
    <>
      <Checkbox
        checked={value}
        style={{ marginLeft: 10 }}
        disabled={disabled}
        onChange={(v) => {
          onChange(v.target.checked);
        }}
        value={value}
      >
        <Typography.Text
          className="label-text"
       
        >
          {" "}
          <strong style={{ fontSize: 14, fontFamily: "montserrat-regular" }}>
            ¿Aceptas nuestros{" "}
            <span style={{ zIndex: 99999999, color: color }} onClick={term}>
              términos y condiciones
            </span>{" "}
            y nuestra{" "}
            <span style={{ zIndex: 99999999, color: color }} onClick={privacy}>
              política de privacidad
            </span>
            ?
          </strong>
        </Typography.Text>
      </Checkbox>
    </>
  );
};

const Cond = () => (
  <div>
    <h5 className="text-dark">Aviso de Privacidad</h5>
    <hr className="divider light my-4" />
    <p className="text-dark-50 mb-4">
      En cumplimiento de la Ley Federal de Protección de Datos Personales en
      Posesión de Particulares, ML Legal App, S.C. (en lo sucesivo mi-lic) pone
      a su disposición el presente Aviso de Privacidad con la finalidad de
      informarle el tipo de datos personales que recabamos de Usted, su uso,
      manejo, aprovechamiento y divulgación. Además haremos de su conocimiento
      la forma en que protegemos la integridad, privacidad y protección de sus
      datos personales en apego estricto de la Ley. Además de ofrecerle nuestra
      gama de servicios, buscamos hacerlo sentir seguro y mantenerlo informado,
      por lo que Usted tendrá acceso en todo momento, este aviso de privacidad
      en nuestro sitio web: www.mi-lic.mx En atención a nuestro giro comercial,
      hacemos de su conocimiento que la información personal que pudiéramos
      solicitar de Usted, vía nuestro sitio web, y que Usted expresamente
      autoriza para nuestro uso, consiste en la siguiente: datos de
      identificación y contacto como nombre, RFC, nacionalidad, domicilio,
      teléfono, correo electrónico, firma, así como datos financieros como
      cuentas bancarias, número de Tarjeta de Crédito e información fiscal.
      <br />
      Además se podrá solicitar y utilizar cualquier otro dato que resulte
      necesario y relevante en relación con las finalidades previstas en este
      aviso de privacidad y/o con los fines distintos que resulten compatibles o
      complementarios relacionados con los servicios que proporcionamos. Por la
      naturaleza de los servicios que maneja mi-lic, el usuario deberá ingresar
      información para la ejecución de los servicios, por lo cual el Usuario
      manifiesta tener el derecho de uso legítimo de dichos datos y será el
      único responsable frente a las autoridades de cualquier tipo de
      consecuencia legal que pudiera derivar del manejo inadecuado. Con
      independencia de lo que antecede, mi-lic manifiesta que toda la
      información ingresada por cualquier usuario, para la prestación de
      cualquiera de los servicios que se ofrecen en el sitio web, será tratada
      de conformidad con el Aviso de Privacidad disponible en www.mi-lic.mx Se
      hace de su conocimiento que este sitio web no recaba datos personales
      sensibles, por lo que no se crean bases de datos que contenga este tipo de
      información. ML Legal App, S.C., para los efectos de este aviso de
      privacidad tienen la calidad de responsable frente a Usted, como titular
      de datos personales; señalando como domicilio para oír y recibir
      notificaciones relacionadas a este aviso de privacidad y la Ley Federal de
      Protección de Datos Personales en Posesión de Particulares, el ubicado en
      Paseo Dinastía 201, Int. 202 D, Fraccionamiento Residencial Dinastía en la
      ciudad de Monterrey, Nuevo León. mi-lic utilizará los datos personales
      recabados con el objeto de brindar, ejecutar, ofertar, vender, promocionar
      e informarle sobre los distintos servicios que ofrece, usando su
      información únicamente para efectos de llevar a cabo su objeto social y
      las obligaciones derivadas del mismo, pero también lo hará para atender y
      dar cumplimiento a los requerimientos legales de autoridades competentes,
      notificaciones sobre cambios de condiciones y mantenimiento de la relación
      comercial y la realización de cualquier actividad complementaria o
      auxiliar necesaria para la realización de los fines anteriores. Además
      haremos el tratamiento de datos personales que resulte necesario, adecuado
      y relevante en relación con las finalidades previstas en este aviso de
      privacidad, así como con los fines distintos que resulten compatibles o
      complementarios relacionados con los servicios que proporcionamos. Para
      prevenir el acceso, daño, pérdida, alteración, destrucción, uso o
      divulgación, no autorizado de sus Datos Personales, hemos establecido
      diversos procedimientos de seguridad, así como las técnicas
      administrativas, digitales y físicas necesarias para protegerlos. Usted
      debe saber que tiene el derecho de limitar el uso y divulgación de su
      información personal a través de una solicitud por escrito, enviada a
      nuestro domicilio y dirigida al mi-lic, o enviando correo electrónico a la
      siguiente dirección electrónica: contacto@mi-lic.mx Conforme a lo señalado
      en la Ley Federal de Protección de Datos Personales en Posesión de los
      Particulares, usted podrá solicitar el acceso, la rectificación, la
      cancelación (en caso que ésta sea legalmente procedente) u oponerse al uso
      de los datos personales que hayan sido proporcionados a mi-lic. mi-lic
      revisará los datos que tiene almacenados de Usted, si así lo solicita en
      ejercicio de sus derechos de Acceso, Rectificación, Cancelación y
      Oposición, por escrito presentado directamente en nuestro domicilio
      dirigido al mi-lic o bien mediante correo electrónico a la dirección:
      contacto@mi-lic.mx
      <br />
      <br />
      La solicitud que realice para el ejercicio de sus derechos ARCO deberá
      contar con la siguiente información y documentación:
      <br />
      A. Su nombre, domicilio y correo electrónico para poder comunicarle la
      respuesta a la Solicitud ARCO;
      <br />
      B. Los documentos que acrediten su identidad (copia de IFE, pasaporte o
      cualquier otra identificación oficial) o en su caso, los documentos que
      acrediten su representación legal;
      <br />
      C. Una descripción clara y precisa de los datos personales respecto de los
      cuales busca ejercer alguno de los Derechos ARCO;
      <br />
      D. Cualquier documento o información que facilite la localización de sus
      datos personales;
      <br />
      E. En caso de solicitar una rectificación de datos, deberá de indicar
      también, las modificaciones a realizarse y aportar la documentación que
      sustente su petición,
      <br />
      F. La indicación del lugar donde mi-lic podrá revisar los originales de la
      documentación que acompañe.
      <br />
      <br />
      En todos los casos en que sea procedente su solicitud en términos de los
      señalado por la Ley Federal de Protección de Datos Personales en Posesión
      de Particulares, la entrega de los datos personales será gratuita, y usted
      deberá cubrir únicamente los gastos justificados de envío o con el costo
      de reproducción en copias u otros formatos que se generen.
      <br />
      mi-lic responderá su solicitud ARCO y los motivos de su decisión mediante
      un correo electrónico en un plazo máximo de 20 días hábiles contados desde
      el día en que se haya recibido su solicitud de ejercicio de derechos ARCO.
      En caso de que la solicitud de ejercicio de derechos ARCO se conteste de
      manera afirmativa o procedente, los cambios solicitados se harán en un
      plazo máximo de 15 días hábiles. Los plazos referidos en este párrafo se
      podrán prorrogar por una sola vez por un periodo igual en caso de ser
      necesario. mi-lic, en atención al artículo 34 de la Ley Federal de
      Protección de Datos Personales en Posesión de Particulares, podrá negar el
      acceso para que Usted ejerza sus Derechos ARCO en los siguientes casos:
      <br />
      <br />
      A. Cuando el solicitante no sea el titular de los datos personales, o no
      pueda acreditar la representación del titular; <br />
      B. Cuando sus datos personales no obren en la base de datos del mi-lic;
      <br />
      C. Cuando se lesionen los derechos de un tercero;
      <br />
      D. Cuando exista un impedimento legal o la resolución de una autoridad
      competente, que restrinja sus Derechos ARCO, y;
      <br />
      E. Cuando la rectificación, cancelación u oposición haya sido previamente
      realizada. <br />
      <br />
      La negativa podrá ser parcial, en cuyo caso mi-lic efectuará el acceso,
      rectificación, cancelación u oposición en la parte procedente.
      <br />
      El titular de los datos personales que presenta una solicitud y/o entabla
      una relación jurídica con mi-lic, consiente la transferencia de sus datos
      personales dentro e incluso fuera de la República Mexicana en los términos
      que se describen. mi-lic se compromete a velar porque se cumplan todos los
      principios legales de protección en torno a la transferencia de sus datos
      personales y manifiesta su compromiso para que se respete en todo momento,
      por nosotros y por nuestros socios comerciales, el presente aviso de
      privacidad y las finalidades a que el titular sujetó su tratamiento. Las
      transferencias nacionales o internacionales de datos podrán llevarse a
      cabo sin el consentimiento del titular, entre otros casos cuando: a) Este
      prevista en una Ley o tratado en los que México sea parte, b) Sea
      efectuada a sociedades controladoras, subsidiarias o afiliadas bajo el
      control común del responsable de que se trate, o a una sociedad matriz o
      cualquier sociedad del mismo grupo del responsable que opere bajo los
      mismos procesos y políticas internas; c) Sea necesaria por virtud de un
      contrato celebrado o por celebrar en interés del titular, por el
      responsable y un tercero, d) Sea precisa para el mantenimiento o
      cumplimiento de una relación jurídica entre el responsable y el titular.
      Cualquiera de las responsables podrá efectuar transferencias de datos
      personales, además de los supuestos antes mencionados, de manera
      enunciativa más no limitativa, con fines de prospección comercial de los
      servicios complementarios a los proporcionados. Nos reservamos el derecho
      de efectuar en cualquier momento cambios o modificaciones al presente
      aviso de privacidad, ya sea para la atención de modificaciones
      legislativas, regulatorias o jurisprudenciales, políticas internas,
      prácticas del mercado o por cualquier otra razón. Cualquier cambio que se
      realice a este aviso de privacidad, será incorporado al mismo por la
      responsable y será dado a conocer en nuestra página de internet:
      www.mi-lic.mx
      <br />
      Mi-lic hace de su conocimiento que al momento de la aceptación del
      presente Aviso de Privacidad, expresamente el Usuario autoriza el uso de
      Cookies por parte de mi-lic en toda actividad de uso que realice en el
      Sitio Web.
      <br />
      En caso de que Usuarios de otros países utilicen el Sitio Web, están de
      acuerdo en sujetarse por completo a lo dispuesto en el presente aviso de
      privacidad y la Ley Federal de Protección de Datos Personales en Posesión
      de Particulares. En la inteligencia de que será obligación del Usuario
      quien deberá informar al mi-lic, que por su nacionalidad o lugar de
      residencia, le es aplicable Ley de privacidad del consumidor de California
      (CCPA), la Regulación General de Protección de Datos (GDPR) y/o cualquier
      otra legislación aplicable, para que mi-lic pueda tomar las medidas
      pertinentes respecto a la información de ese usuario.
      <br />
      No obstante lo anterior y ante la falta de aviso por parte del Usuario a
      mi-lic, este último manifiesta que toda la información ingresada por
      cualquier usuario, para la la ejecución o venta cualquiera de los
      servicios que se ofrecen en el sitio web, será tratada de conformidad con
      este Aviso de Privacidad y la Ley Federal de Protección de Datos
      Personales en Posesión de Particulares.
      <br />
      <br />
      Última modificación: 30 de junio de 2021
    </p>
  </div>
);

const Term = () => (
  <div>
    <h5 className="text-dark">Términos y Condiciones</h5>
    <hr className="divider light my-4" />
    <p className="text-dark-50 mb-4">
      Los presentes términos y condiciones (los “Términos y Condiciones”) tienen
      por objeto regular la relación comercial entre el Usuario y ML Legal App,
      S.C. (en lo sucesivo, “mi-lic” o el “Operador”), así como el acceso o uso
      que Usted haga del sitio www.mi-lic.mx A través del Sitio Web se exhiben
      Servicios, cuya gestión de compra es realizada por los Usuarios a mi-lic,
      siendo que los primeros buscan satisfacer una necesidad privada, personal,
      familiar o comercial, y en ningún momento pretenden la comercialización,
      reventa o cualquier otro tipo de transacción comercial o interés con los
      productos adquiridos. Mediante la aceptación de estos Términos y
      Condiciones se regula el acceso y uso de los Servicios y son los que
      establecen una relación contractual entre usted y mi-lic. Si usted no
      acepta dichos Términos y Condiciones, no podrá acceder o usar los
      Servicios. Estos Términos y Condiciones sustituyen expresamente
      cualesquier acuerdos o compromisos previos celebrados entre las partes.
      mi-lic podrá poner fin de inmediato a estos Términos y Condiciones o
      cualquiera de los Servicios respecto de usted o, en general, dejar de
      ofrecer o denegar el acceso a los Servicios o cualquier parte de ellos, en
      cualquier momento y por cualquier motivo de manera unilateral. Se podrán
      aplicar términos y condiciones suplementarios a determinados Servicios,
      como políticas para un evento, una actividad o una promoción particular, y
      dichas condiciones suplementarias se le comunicarán en relación con los
      Servicios aplicables. Los términos y condiciones suplementarios se
      establecen además de los Términos y Condiciones, y se considerarán una
      parte de éstos, para los fines de los Servicios aplicables. Los términos y
      condiciones suplementarios prevalecerán sobre los Términos y Condiciones
      solamente en cuanto a las estipulaciones que estuvieran en conflicto con
      respecto a los Servicios aplicables.
      <br />
      <br />
      <strong>Definiciones</strong>
      <br />
      Cookies: Cadenas de texto enviadas virtualmente que son almacenadas por el
      uso del Sitio Web por el Operador, para la consulta de las actividades y
      preferencias de los usuarios. Datos Personales: Es toda información que
      permite identificar o hacer identificable a una persona física. Mayor de
      Edad: Persona física mayor de dieciocho (18) años de edad. Mensajes de
      Datos: La información generada, enviada, recibida, almacenada o comunicada
      por medios electrónicos, ópticos o similares, como pudieran ser, entre
      otros, el intercambio electrónico de datos, internet, el correo
      electrónico, el telegrama, el télex o el telefax. Pasarela de Pagos:
      Servicio que permite la realización de pagos por parte de los Usuarios
      directamente a mi-lic a través de medios electrónicos utilizando
      plataformas tecnológicas (software). Partes: Conjuntamente un Usuario y
      mi-lic, o en la individual cada uno de éstos podrá ser referido como una
      Parte. Servicios(s): Exclusivamente aquel(los) servicio(s) exhibido(s) a
      través del Sitio Web. Sitio Web: Página de internet administrada por el
      Operador que permite la concurrencia en de Usuarios para que soliciten la
      prestación de un servicio a mi-lic. Usuario(s): Quien(es) accede(n) y/o
      usa(n) el Sitio Web y solicitan la adquisición de uno o más Servicios,
      para lo cual se entiende que llevan a cabo la celebración de un contrato
      de prestación de servicios y/o cualquier otro tipo de contrato lícito que
      sea aplicable. Ventanas Emergentes (Pop-Ups): Ventana o aviso de internet
      que emerge automáticamente en cualquier momento cuando se utiliza el Sitio
      Web. En el entendido de que será obligación del Usuario habilitar la
      utilización de ventanas emergentes durante la operación del Sitio Web.
      <br />
      <br />
      <strong> Condiciones Generales</strong>
      <br />
      Los presentes Términos y Condiciones regulan el uso que pueda vincular a
      Usuarios o visitantes del Sitio Web y a mi-lic. Los Usuarios que hacen uso
      de mi-lic declaran ser mayores de edad y tener la capacidad jurídica
      suficiente para hacer uso de todos los servicios proporcionados. La
      información ingresada por los usuarios dentro de los servicios
      proporcionados por mi-lic se utilizará tal y como sea alimentada, por lo
      que es plena responsabilidad del usuario que la misma sea correcta, puesto
      que al no ser así, podrían generarse errores en los documentos y/o
      asesorías proporcionadas. El servicio de alerta de terminación de vigencia
      de los contratos será exclusivo para los usuarios que hayan contratado
      alguna membresía con mi-lic, siempre y cuando las mismas se encuentren
      vigentes. mi-lic se reserva el derecho de suspender el servicio y/ó
      cualquier membresía, si a su consideración el Usuario hace mal uso de
      cualquiera de nuestras membresías y servicios. En la aceptación de los
      términos y condiciones los Usuarios se hacen conocedores y están de
      acuerdo en que mi-lic tendrá responsabilidad limitada de cualquier tipo,
      en cualquier situación o hecho, al monto pagado por concepto de
      contraprestación de los servicios contratados.
      <br />
      <br />
      <strong>Licencia</strong>
      <br />
      Sujeto al cumplimiento de los presentes Términos y Condiciones, mi-lic
      otorga a los Usuarios una autorización limitada, no exclusiva, revocable,
      no transferible para: (i) el acceso y uso del Sitio Web en el dispositivo
      personal del Usuario sólo en relación con su uso de los Servicios; y (ii)
      el acceso y uso de cualquier contenido, información y material relacionado
      que pueda ponerse a disposición a través de los Servicios, en cada caso
      sólo para su uso privado, ya sea personal o comercial. mi-lic y sus
      licenciantes se reservan cualquier derecho que no haya sido expresamente
      otorgado por los presentes Términos y Condiciones.
      <br />
      <br />
      <strong>Objeto de los Servicios</strong>
      <br />
      El Usuario reconoce y acepta usar el Sitio Web como un medio para lograr
      la adquisición de servicios legales para su consumo final y la
      satisfacción de sus necesidades, con sujeción a la normatividad mercantil
      mexicana. El uso del Sitio Web lo realiza el Usuario como persona capaz y
      Mayor de Edad, y manifestando que para la celebración del contrato de
      prestación de servicios que celebre con mi-lic, cuenta con plena capacidad
      legal para ser sujeto de derechos y obligaciones, calidades que refrenda
      al momento de generar su registro. El perfeccionamiento del contrato de
      prestación de servicios por medios electrónicos se da en el momento en que
      el Usuario paga la gestión del servicio solicitado. Se aclara que mi-lic
      únicamente puede aceptar el encargo, una vez el Usuario haya pagado. Por
      lo tanto, verificados los pasos del proceso de compra, el Operador puede
      aceptar total o parcialmente la gestión del encargo solicitado por el
      Usuario. En caso de aceptar el encargo únicamente de manera parcial, el
      resumen se actualizará evidenciando los servicios sobre los cuales
      efectivamente se entiende constituido el encargo. Descripción de los
      Servicios Los Servicios exhibidos por el Operador consisten en la
      prestación de servicios legales, incluyendo sin limitar, asesoría legal
      audiovisual y/o por escrito, revisión y/o preparación de documentos. Todos
      los Servicios cuentan con una descripción general ilustrativa pero no
      exhaustiva, que en todo caso depende del dispositivo en donde el Usuario
      observe el (los) Servicio(s). La disponibilidad del(los) Servicio(s) será
      definida en cada caso en concreto al momento en que el Usuario realice la
      gestión encargada dentro del proceso de compra, el Usuario determinará qué
      acción debe realizar el Operador. En caso de no prestarse por el Operador
      el (los) Servicio(s) solicitado(s), se procederá entre las siguientes
      opciones: (i) Cumplir con el pedido excluyendo el (los) Servicio(s)
      solicitado(s) no disponibles, caso en el cual se descontará su costo del
      valor total del pedido y, (ii) en caso de ser sólo un Servicio y no se
      encuentre disponible, deberá elegir entre la cancelación del pedido o el
      cumplimiento con un Servicio sustituto o similar en precio y tipo. En
      virtud de las condiciones de capacidad legal establecidas en la
      legislación mexicana vigente y de la validez de la manifestación de
      voluntad a través de medios electrónicos establecida en el Código Civil
      Federal, los Usuarios al momento de aceptar los presentes Términos y
      Condiciones como también al registrarse y al seleccionar el (los)
      Servicio(s), manifiestan expresamente tener capacidad suficiente para
      celebrar el tipo de contrato de prestación de servicios, que se puede o
      podría llegar a configurar accediendo y/o usando el Sitio Web; y no
      obstante lo anterior, los menores de edad de acuerdo con la ley aplicable,
      no están facultados para hacer uso en ningún momento del Sitio Web. En
      caso de no cumplir con esta obligación, el Operador podrá rechazar la
      transacción y en el evento de haber pagado por el (los) Servicio(s),
      reembolsar el monto pagado. El Operador exhibe a través del Sitio Web los
      Servicios que están a disposición de los Usuarios para su conocimiento
      general. Esta comunicación de productos sirve como referencia a los
      Usuarios para su adquisición, usando el Sitio Web como medio para
      solicitar servicios a mi-lic y celebrar un contrato de prestación de
      servicios por medios electrónicos. Para poder solicitar los servicios, los
      Usuarios deben estar plenamente identificados en su Cuenta de Usuario y
      seguir el siguiente procedimiento:
      <br />
      <br />
      <strong>Ingresar al Sitio Web.</strong>
      <br />
      Seleccionar el (los) Servicio(s). Al seleccionar el (los) Servicio(s) se
      ponen a disposición del Usuario las características y valor total del
      (los) Servicio(s), que permiten la plena individualización del (los)
      Servicio(s) para el análisis detallado del Usuario. Ingreso del (los)
      Servicio(s) al carrito de compras. Este ingreso corresponde a la voluntad
      inequívoca del Usuario de adquirir algún Servicio determinado, ya que se
      ha informado con suficiencia las características del mismo, teniendo la
      posibilidad de adquirirlo o no. El Operador tiene total autonomía para
      limitar el ingreso del (los) Servicio(s) al carrito de compras con motivo
      de la cantidad. Precio. Una vez se han agotado los pasos precedentes, se
      pone a disposición del Usuario el Precio a pagar por el (los) Servicio(s)
      seleccionado(s), el cual se encuentra reflejado unitariamente y en
      conjunto por todos los Servicios a adquirir. El valor corresponderá al
      valor total del (los) Servicio(s) incluyendo costos de transacción e
      impuestos. Forma de pago. El Usuario deberá seleccionar el medio de pago
      que desea utilizar, teniendo como única posibilidad el pago a través de la
      Pasarela de Pago contratada por el Operador, siempre y cuando ésta se
      encuentre disponible en el Sitio Web. En este momento se configura la
      solicitud del encargo que realiza el Usuario a mi-lic. Registro. Al
      momento de definir la forma de pago, el Consumidor deberá crear su cuenta
      personal en la cual se requerirán los datos que permitan su
      identificación, más no su individualización. Adicionalmente, se requerirán
      los datos para poder realizar el pago de manera electrónica. Resumen y
      Correo electrónico. Una vez completados los pasos anteriores se le
      exhibirá a través de una ventana emergente al Usuario, un resumen
      detallado de la transacción. Dicho resumen será enviado por correo
      electrónico al Usuario con esta información de forma detallada. Recibo. Se
      enviará vía correo electrónico un recibo al Usuario con el detalle de la
      transacción.
      <br />
      Entrega. Verificados todos los datos correspondientes a la transacción, el
      Operador prestará los Servicios a través del Sitio Web al finalizar la
      adquisición del (los) Servicio(s) comprado(s). El Operador se reserva el
      derecho de actualizar, modificar y/o descontinuar en cualquier momento
      el(los) Servicio(s) exhibido(s) en el Sitio Web.
      <br />
      <br />
      <strong> Alcances de Servicio</strong>
      <br />
      Todos los servicios que ofrece mi-lic son de conformidad con la
      legislación mexicana vigente y en idioma español, por lo tanto si algún
      usuario requiere algún Servicio en un idioma distinto, estará sujeto a
      disponibilidad y se deberá solicitar a mi-lic la cotización
      correspondiente en cualquiera de los canales de comunicación que pone a su
      disposición.
      <br />
      A través del Sitio Web, mi-lic podrá poner a disposición de los Usuarios
      información de carácter comercial y publicitario, propio o de terceros. En
      estos casos, el Operador no avala, garantiza o compromete su
      responsabilidad frente a los productos o servicios por éstos terceros; en
      consecuencia es total responsabilidad de los Usuarios el acceso a los
      sitios que remite la publicidad, asumiendo la obligación de verificar y
      conocer los términos de los servicios ofrecidos por los terceros.
      <br />
      <br />
      <strong>De la generación de documentos legales</strong>
      <br />
      El usuario es el único responsable del contenido de las respuestas que
      proporcionen a las preguntas realizadas por mi-lic para la generación de
      los documentos. Las respuestas proporcionadas por el usuario únicamente
      serán usadas por mi-lic para la generación de los documentos respectivos
      por el Sitio Web, para lo cual el usuario otorga su pleno consentimiento.
      El alcance de los documentos generados por el Sitio Web es en cuanto a
      tener constancia de la celebración de los actos jurídicos en ellos
      descritos, dependiendo exclusivamente del usuario todas las actividades
      encaminadas al cobro, requerimiento, celebración y/o ejecución de los
      derechos y obligaciones pactadas en el documento, tanto judicial como
      extrajudicialmente. El usuario no podrá reproducir o alterar el contenido
      de los documentos legales una vez generados estos. Una vez pagado el
      documento y habiendo el usuario contestado las respectivas preguntas para
      su generación, el mismo será enviado vía correo electrónico en formato
      .PDF a la dirección de correo electrónico proporcionada y además estará
      disponible en el sitio web de mi-lic, visible únicamente cuando el usuario
      inicie su sesión, por un periodo de 15 días naturales contados a partir de
      su generación.
      <br />
      <br />
      <strong>De las consultas con abogados por videollamada</strong>
      <br />
      El objetivo de la videollamada es orientar en el ámbito legal a los
      usuarios, es decir, brindarle las recomendaciones básicas que debería
      seguir para intentar resolver su situación o problemática.
      <br />
      La duración de las videollamadas es de 20 minutos, los cuales iniciarán a
      correr a partir de que el reloj del sistema de mi-lic marque la hora
      pactada para la consulta, y los cuales se contarán de manera
      ininterrumpida.
      <br />
      El usuario tiene la obligación previo a la celebración de la videollamada,
      de efectuar la descripción y/o preguntas sobre las que desea obtener
      respuesta por escrito de su caso con el fin de que el tiempo de asesoría
      sea más efectivo. En caso de no haber proporcionado la descripción y/o
      preguntas antes referidas, la dinámica de la videollamada consistirá en
      que el usuario brindará un contexto breve de su situación, continuando a
      realizar una pregunta al abogado y éste se la responderá, repitiendo este
      ciclo las veces que pueda concretarse dentro de los 20 minutos de duración
      de la videollamada.
      <br />
      El tiempo de tolerancia de espera para un usuario es de 10 minutos y para
      el abogado que va a dar la consulta es de 5 minutos.
      <br />
      Al transcurrir el tiempo de tolerancia para el usuario, el abogado podrá
      abandonar la sesión de video sin responsabilidad alguna para mi-lic y el
      usuario perderá el derecho a su videollamada y no podrá solicitar un
      reembolso.
      <br />
      Al transcurrir el tiempo de tolerancia para el abogado que dará la
      consulta, el cliente podrá optar por abandonar la sesión y solicitar el
      reembolso o bien agendar nueva cita sin costo adicional para el usuario.
      <br />
      El usuario podrá modificar la fecha y hora de su videollamada, siempre que
      cambie su cita previamente agendada 72 horas antes de la fecha y hora de
      la la videollamada que contrató originalmente. En caso de no realizarse el
      cambio dentro del tiempo establecido, el abogado que atenderá la
      videollamada se conectará a la hora pactada inicialmente, otorgando el
      tiempo de tolerancia para el usuario antes señalado. No existirá reembolso
      alguno para los usuarios que no realicen el cambio dentro del tiempo
      establecido.
      <br />
      Cualquier contacto, comunicación y/o servicio que se pacte con el abogado
      que atienda la videollamada, posterior a la celebración de ésta y
      realizada fuera del Sitio Web, será plena y absoluta responsabilidad del
      usuario, dejándose libre a mi-lic de toda relación y/o responsabilidad.
      <br />
      <br />
      <br />
      <strong>De las consultas con abogado por escrito</strong>
      <br />
      El objetivo y alcance de la consulta por escrito es orientar en el ámbito
      legal a los usuarios, es decir, brindarle las recomendaciones básicas que
      debería seguir para intentar resolver su situación o problemática. La
      asesoría por escrito se brindará exclusivamente sobre las preguntas, dudas
      o comentarios expresados por el usuario dentro del formulario
      proporcionado por mi-lic, por lo que es plena responsabilidad del cliente
      lo expresado en el apartado correspondiente. Una vez pagada la asesoría y
      habiendo el usuario alimentado sus dudas, preguntas y comentarios sobre su
      situación, será enviada, dentro del plazo de respuesta convenido por el
      usuario, vía correo electrónico en formato .PDF a la dirección de correo
      electrónico proporcionada, y además estará disponible en el sitio web de
      mi-lic, visible únicamente cuando el usuario inicie su sesión, por un
      periodo de 15 días naturales contados a partir de su generación. Como
      valor agregado, sin que esto se considere como obligatorio, mi-lic de
      manera opcional podrá hacer llegar al usuario un archivo de audio que
      contendrá la misma asesoría enviada por escrito. De esta forma, el usuario
      tendrá la posibilidad de leer el contenido de su asesoría o bien
      escucharlo mediante el archivo de audio. Cualquier contacto, comunicación
      y/o servicio que se pacte con el abogado que atienda la consulta por
      escrito o alguna de las firmas que se recomienden al Usuario por parte de
      mi-lic, posterior al envío de la asesoría y realizado dicho contacto fuera
      del Sitio Web, será plena y absoluta responsabilidad del usuario,
      dejándose libre a mi-lic de toda relación y/o responsabilidad.
      <br />
      <br />
      <strong>De la revisión de documentos legales</strong>
      <br />
      En este servicio se lleva a cabo el análisis de documentos legales en
      idioma español proporcionados por el usuario, con el objetivo de brindar
      orientación legal sobre el documento proporcionado por el usuario,
      entregando al usuario las recomendaciones básicas, acciones, riesgos o
      advertencias que pudieran presentarse en el documento. mi-lic no se hace
      responsable de las recomendaciones realizadas al usuario en el caso de que
      este último comparta con mi-lic documentos incompletos, con faltante de
      anexos y/o archivos complementarios que formen parte del documento que
      solicitó fuera revisado. mi-lic podrá rechazar la revisión de cualquier
      documento legal que envíe el usuario si éste no está legible y/o si lo
      subió incompleto y/o está redactado en otro idioma. En estos casos mi-lic
      podrá solicitar nuevamente que se cargue el documento en una mejor
      resolución, que se cargue completo o enviará una nueva cotización al
      usuario. Los tiempos de respuesta empezarán a correr a partir de que se
      reciba el documento legible, completo, con la resolución necesaria para su
      análisis y/o se haya aceptado y pagado por el usuario la nueva cotización.
      Si el usuario proporciona un documento incompleto y hecho el aviso
      correspondiente por mi-lic, no lo sube completamente dentro del plazo de
      48 horas posteriores a la emisión del aviso, mi-lic procederá a realizar
      el análisis de los documentos en el estado que se encuentren y efectuando
      la correspondiente asesoría, sin responsabilidad alguna sobre su contenido
      y utilidad para el usuario. Será plena responsabilidad del usuario la
      atención y cumplimiento de plazos, términos fatales y obligaciones a su
      cargo que se presenten en el texto del documento que envía a mi-lic para
      su revisión. Como valor agregado, sin que esto se considere como
      obligatorio, mi-lic de manera opcional podrá hacer llegar al usuario un
      archivo de audio que contendrá la misma revisión de su documento. De esta
      forma, el usuario tendrá la posibilidad de leer el contenido de su
      revisión o bien escucharlo mediante el archivo de audio.
      <br />
      <br />
      <strong>De la calculadora laboral</strong>
      <br />
      Los cálculos por montos de finiquito entregados a los usuarios al
      contratar este servicio son aproximados, con la intención de que el
      usuario tenga una mejor noción del monto que le corresponde por ley al
      momento de dar por terminada una relación laboral. Los cálculos
      proporcionados en este servicio pueden variar para cada persona,
      dependiendo del tipo de patrón y prestaciones con las que cuenta. De forma
      enunciativa más no limitativa, se hace del conocimiento del usuario que
      algunos elementos que pueden variar el cálculo del servicio de calculadora
      laboral son: errores o imprecisiones en la información ingresada por el
      usuario, préstamos o deudas del empleado con su patrón, fondo de ahorro,
      fondo de ahorro para el retiro, préstamos con el patrón, bonos que pueda
      tener el empleado y cualquier otra prestación que por ley pueda ser
      considerada como parte integral del salario. El monto del cálculo de
      finiquito proporcionado por el sitio web no tiene efecto legal alguno, la
      cantidad calculada para el usuario es utilizable para que éste tenga una
      idea muy cercana del monto al que es acreedor y efectuar las acciones que
      así considere.
      <br />
      <br />
      <strong>Membresías</strong>
      <br />
      La membresía de mi-lic seguirá vigente hasta el momento en que sea
      cancelada por el usuario. Para utilizar el servicio de mi-lic debe tener
      acceso a internet y su dispositivo listo para mi-lic, y proporcionar una
      forma de pago. A menos que cancele su membresía antes de la fecha de
      facturación, el usuario autoriza a mi-lic a cobrarle la membresía del
      siguiente ciclo de facturación a su forma de pago. mi-lic ofrece diversos
      planes de membresías, ciertas membresías tienen condiciones y limitaciones
      diferentes que se aclaran en el acuerdo de suscripción o en otras
      comunicaciones que ponemos a su disposición. Podrá encontrar la
      información específica sobre su membresía visitando nuestro sitio web. Así
      mismo, mi-lic se reserva el derecho a modificar los beneficios y/o
      funciones especiales de cualquier membresía, para lo cual bastará la
      publicación en www.mi-lic.mx. Todos los ajustes o modificaciones a las
      membresías serán aplicados de inmediato cuando sea en beneficio del
      usuario. En cambio cuando se trate de incremento en los precios o en la
      limitación en los contenidos de las membresías, los cambios serán
      efectivos y aplicados para cada membresía para el siguiente ciclo de
      renovación mensual y/o anual, según sea el caso. Cumpliendo así con la
      obligación de informar previamente al usuario de estos cambios,
      entendiéndose su implícito consentimiento a tales cambios al no modificar
      éste el estado de su membresía. Los beneficios o funciones especiales así
      como los descuentos u ofertas a que tenga acceso el usuario dependerá del
      tipo de membresía contratado por el usuario, algunas de las ventajas
      incluidas podrían ser:
      <br />
      <br />
      <strong> Modificaciones a los documentos generados</strong>
      <br />
      Únicamente los suscriptores con membresía vigente, dependiendo del tipo de
      suscripción contratada, tendrán la posibilidad de realizar hasta dos
      cambios en el instrumento legal que hayan generado, en el entendido que
      éstos ajustes podrán realizarse únicamente en los campos editables que
      mi-lic proporcionó al momento de la elaboración de dicho documento. Así
      mismo, estas modificaciones deberán hacerse dentro de un periodo no mayor
      a 24 horas contadas a partir de la fecha de generación del mismo. En el
      entendido que cada documento sólo podrá tener dichas modificaciones 1
      (una) vez.
      <br />
      <br />
      <strong> Almacenamiento de Documentos</strong>
      <br />
      El almacenamiento de archivos dentro de www.mi-lic.mx derivado del
      servicio de generación de documentos legales será de hasta 15 días
      naturales contados a partir de la fecha en que se haya realizado cada
      documento. Solo los suscriptores con membresía vigente, dependiendo del
      tipo de suscripción contratada, podrán acceder a este beneficio. mi-lic
      únicamente ofrecerá el servicio de preservar documentos dentro del sitio
      www.mi-lic.mx derivado del servicio de generación de documentos legales,
      por lo que no podrá entenderse que este mismo beneficio se ofrece para
      otro tipo de documentos o servicios. Los Usuarios que tengan una membresía
      vigente con mi-lic tendrán el beneficio de que sus documentos legales
      derivados del servicio de generación de documentos legales, permanezcan
      almacenados en el sitio de mi-lic por todo el tiempo en que el usuario
      mantenga vigente su membresía. En el caso de que un usuario no renueve su
      membresía con mi-lic, todos los archivos almacenados permanecerán
      guardados en el sitio web hasta 10 días naturales posteriores al término
      de vigencia de su membresía. Transcurrido ese tiempo, y si el usuario no
      renovó nuevamente su membresía, mi-lic procederá a eliminar dichos
      archivos, pues el servicio ya se encontrará debidamente concluido. Por
      otra parte, todos los archivos y/ó documentos digitales que el usuario
      haya cargado en el sitio web para su revisión por parte de mi-lic, se
      almacenarán únicamente por un periodo de 10 días naturales posteriores a
      que mi-lic haya enviado la asesoría contratada por el usuario.
      Transcurrido ese tiempo, mi-lic procederá a eliminar los archivos cargados
      por el usuario, pues el servicio ya se encontrará debidamente concluido.
      <br />
      <br />
      <strong>Alerta de terminación de vigencia de contrato</strong>
      <br />
      El mecanismo de alerta de fecha de terminación de los contratos generados
      en mi-lic por el usuario estará vigente únicamente para usuarios que
      contrataron una membresía y por el periodo que tenga activa su membresía,
      es decir, terminará este servicio al día siguiente hábil en dé de baja su
      suscripción.
      <br />
      <br />
      <strong>
        {" "}
        Archivos de audio complementarios para sus asesorías por escrito de
        abogado o revisión legal de documentos.
      </strong>
      <br />
      Para los servicios de revisión de documentos legales y consulta con
      abogado por escrito, solamente los usuarios que tengan una membresía
      vigente con mi-lic tendrán el beneficio adicional de recibir en su correo
      electrónico y/o en su “Archivero Legal” un archivo de audio que contenga
      su revisión de documento legal y/o consulta de abogado por escrito. Esto
      no es aplicable para el resto de los servicios de mi-lic.
      <br />
      <br />
      <strong> Almacenamiento de Audios</strong>
      <br />
      El almacenamiento dentro de www.mi-lic.mx de los audios enviados al
      usuario producto de una revisión de documento legal y/o consulta de
      abogado por escrito, será únicamente de hasta 15 días naturales contados a
      partir de la fecha en que se haya generado la revisión o consulta antes
      descrita. Solo los suscriptores con membresía vigente, dependiendo del
      tipo de suscripción contratada, podrán acceder a este beneficio. Los
      Usuarios que tengan una membresía vigente con mi-lic tendrán el beneficio
      de que los archivos de audio generados por los servicios revisión de
      documento legal y/o consulta de abogado por escrito, permanezcan
      almacenados en el sitio de mi-lic por todo el tiempo en que el usuario
      mantenga vigente su membresía. En el caso de que un usuario no renueve su
      membresía con mi-lic, todos los archivos de audio almacenados permanecerán
      guardados en el sitio web hasta 10 días naturales posteriores al término
      de vigencia de su membresía. Transcurrido ese tiempo, y si el usuario no
      renovó nuevamente su membresía, mi-lic procederá a eliminar dichos
      archivos, pues el(los) servicio(s) del que derivan estos audios ya se
      encontrará(n) debidamente concluido(s).
      <br />
      <br />
      <strong> Libreta de Direcciones</strong>
      <br />
      Todos los usuarios que tengan una membresía vigente con mi-lic, podrán
      acceder al beneficio de la libreta de direcciones, la cual consiste en que
      el usuario podrá ingresar la información de diferentes direcciones,
      nombres de personas y/o empresas, las cuales le serán de utilidad al
      momento de utilizar el servicio de generación de documentos legales. Para
      almacenar la información señalada en el punto anterior, el usuario
      manifiesta tener el derecho de uso legítimo de dichos datos y será el
      único responsable frente a las autoridades de cualquier tipo de
      consecuencia legal que pudiera derivar del manejo inadecuado. No obstante
      lo anterior, mi-lic señala que toda la información ingresada por cualquier
      usuario, para la prestación de cualquiera de los servicios que se ofrecen
      en el sitio web, será tratada de conformidad con el Aviso de Privacidad
      disponible en www.mi-lic.mx En el caso de que un usuario no renueve su
      membresía con mi-lic, todos los contactos almacenados en la libreta de
      direcciones permanecerán guardados en el sitio web hasta 10 días naturales
      posteriores al término de vigencia de su membresía. Transcurrido ese
      tiempo, y si el usuario no renovó nuevamente su membresía, mi-lic
      procederá a eliminar dichos archivos, sin responsabilidad alguna.
      <br />
      <br />
      <strong>Descuentos en servicios</strong>
      <br />
      Los descuentos a los que tiene derecho cualquier usuario que tenga una
      membresía vigente, podrán ejercerse en todo momento y sin limitación en el
      número de servicios que desee contratar el usuario. Los descuentos
      especiales que ofrece cada tipo de membresía, no son acumulables con
      ninguna otra promoción, oferta o descuento que decida implementar mi-lic
      para el público en general. El descuento aplicable a los Usuarios con
      membresía activa será el de mayor beneficio para el cliente.
      <br />
      Todos los beneficios que pudiera incluir la membresía contratada por el
      usuario, dependen del tipo de membresía contratado, y estos concluyen el
      mismo día en que se deje de realizar el pago de renovación
      correspondiente, ya sea por petición expresa del usuario o porque su
      método de pago haya sido rechazado, durante el ciclo de renovación de su
      suscripción.
      <br />
      <br />
      <strong>Cobro y cancelación</strong>
      <br />
      Pasarela de pago
      <br />
      Las transacciones serán efectuadas mediante la pasarela de pago de Openpay
      <br />
      Cargos
      <br />
      Los cargos de membresía por el servicio de mi-lic y cualquier otro cargo
      en el que incurra el usuario en relación con el uso que haga del servicio
      de mi-lic, como impuestos y posibles gastos de transacción, se cobrarán a
      su Forma de pago en la fecha de pago específica indicada en la Cuenta de
      Usuario. La duración de su ciclo de cobro dependerá del tipo de membresía
      que haya seleccionado al suscribirse al servicio. En ciertos casos, su
      fecha de pago podría cambiar, por ejemplo si no se pudo hacer el cobro con
      su Forma de pago satisfactoriamente, cuando cambie su tipo de membresía, o
      si su membresía pagada comenzó un día que no está incluido en un
      determinado mes.
      <br />
      Formas de pago.
      <br />
      Para usar el servicio de membresías de mi-lic debe proveer una Forma de
      pago o más. Usted nos autoriza a hacer cargos del monto de su suscripción
      a cualquier Forma de pago asociada con su cuenta en caso del rechazo o
      indisponibilidad de su Forma de pago principal. Cualquier cargo pendiente
      le corresponderá a usted. Si el pago no se pudiera hacer
      satisfactoriamente, debido a la fecha de vencimiento, la falta de fondos u
      otro motivo, y si usted no cancela su cuenta, podemos suspender su acceso
      al servicio hasta que obtengamos una Forma de pago válida.
      <br />
      Actualización de sus Formas de pago.
      <br />
      Se puede actualizar su Forma de pago en la sección de Cuenta de Usuario.
      También podemos actualizar su Forma de pago usando la información provista
      por el proveedor de servicios de pago correspondiente. Luego de cualquier
      actualización, usted nos autoriza a hacer cargos a la(s) Forma(s) de pago
      correspondiente(s).
      <br />
      Cancelación.
      <br />
      Usted puede cancelar la membresía de mi-lic en cualquier momento, y
      continuará teniendo acceso al servicio hasta el final de su periodo de
      cobro. En la medida permitida por la ley aplicable, los pagos no son
      reembolsables y no se otorgarán reembolsos ni créditos por los periodos de
      membresía utilizados parcialmente o por el contenido de mi-lic no
      utilizado. Para cancelar, visite la sección de Cuenta de Usuario. Si usted
      no renueva su membresía, su Cuenta de Usuario se cerrará automáticamente
      al final de su periodo de facturación actual. Para ver cuándo se cerrará
      su cuenta, haga clic en “Información de facturación” en la Cuenta de
      Usuario.
      <br />
      <br />
      Cambios en el precio y planes de suscripción.
      <br />
      Podemos cambiar nuestros planes de membresías y el precio de nuestros
      servicios en un momento dado. Sin embargo, cualquier cambio en los precios
      o en los planes de membresías se aplicará no antes de los 30 días
      naturales siguientes a la notificación.
      <br />
      <br />
      Cambios en el tipo de membresía contratada por el Usuario
      <br />
      El usuario podrá cambiar el tipo de membresía contratada en cualquier
      momento, para lo cual, autoriza a mi-lic para hacer los ajustes necesarios
      para adecuar proporcionalmente el monto del cobro a realizar.
      <br />
      <br />
      Facturación fiscal
      <br />
      En caso de que el usuario requiera comprobante fiscal de cualquier
      operación, deberá solicitarla al momento de efectuar el pago,
      seleccionando la opción correspondiente y proporcionando los datos
      fiscales (nombre, domicilio, RFC y correo electrónico) sobre los cuales
      desea facturar. Hecho lo anterior, mi-lic enviará al usuario el
      comprobante fiscal dentro de las próximas 72 horas hábiles.
      <br />
      <br />
      Uso y Restricciones
      <br />
      El Usuario acepta que se encuentra estrictamente prohibido grabar,
      reproducir, copiar, transferir y/ó publicar de forma total o parcial las
      sesiones de videollamada, audios, mensajes de texto, sesiones de chat,
      correos electrónicos y en sí cualquier otro medio digital por el cual
      mi-lic brinde sus servicios y asesoría al usuario. En la inteligencia que
      de hacerlo, esto se hace sin el consentimiento y en contra de la voluntad
      de mi-lic, pudiendo éste último iniciar las acciones legales
      correspondientes para la reclamación de daños y perjuicios.
      <br />
      <br />
      Precios, ofertas y descuentos
      <br />
      En caso de existir un error involuntario de parte de mi-lic al momento de
      fijar los precios en el sitio web, la compra por usuario estará limitado a
      máximo 5 servicios. mi-lic respetará el precio, aunque esté plasmado de
      forma imprecisa, sin embargo, se reservará el derecho de cancelar todas
      aquellas compras que excedan en número a la cantidad señalada en el punto
      anterior. Todas las ofertas y descuentos que ofrezca mi-lic a los usuarios
      estarán limitados a un máximo de 5 adquisiciones por cada usuario. mi-lic
      se reserva el derecho a cancelar todas aquellas compras del usuario
      derivado del aprovechamiento de una promoción, oferta o descuento
      ofertado, respetando únicamente el límite señalado en el punto anterior.
      Las promociones, ofertas y/o descuentos que pudiera ofrecer mi-lic a los
      usuarios, en ningún momento podrán ser acumulables. En la inteligencia de
      que aquellos usuarios que tengan una membresía vigente deberán optar entre
      el descuento que les da su membresía o la promoción, oferta o descuento
      que esté vigente al momento de realizar su compra.
      <br />
      <br />
      Política de Reembolso
      <br />
      Todos los reembolsos son menos los cargos administrativos cuando sean
      errores del usuario. Los reembolsos se efectuarán cuando ocurran
      escenarios específicos descritos dentro de los presentes “términos y
      condiciones” y siempre a solicitud del usuario, derivados de errores
      atribuibles a mi-lic. El usuario deberá solicitar el reembolso vía correo
      electrónico a la cuenta contacto@mi-lic.mx, adjuntando la evidencia y
      descripción de la causa generadora del reembolso; únicamente podrá
      solicitar el reembolso dentro del término de 72 horas posteriores a que se
      genere el error por parte de mi-lic. De ser aprobado el reembolso, y en
      caso de que el Usuario haya solicitado factura del servicio, se procederá
      también a la cancelación de la misma. De proceder el reembolso, el usuario
      podrá elegir la forma en que el mismo se efectuará dentro de las
      siguientes opciones: Crédito en la página para el mismo servicio.
      <br />
      Crédito en la página para servicio distinto.
      <br />
      Reembolso total menos los cargos administrativos correspondientes.
      <br />
      <br />
      <strong> Propiedad Intelectual</strong>
      <br />
      Mi-lic es una marca debidamente registrada ante el Instituto Mexicano de
      la Propiedad Industrial, al igual que sus logotipos y diseños, por lo que
      se encuentra estrictamente prohibido el uso de los mismos sin autorización
      previa y por escrito por parte de mi-lic.
      <br />
      Toda la información puesta a disposición en el Sitio Web como imágenes,
      publicidad, nombres, marcas, lemas y demás elementos de propiedad
      intelectual, son utilizados legítimamente por el mi-lic sea porque son de
      su propiedad, porque tienen autorización para ponerlos a disposición o
      porque se encuentra facultada por la legislación aplicable vigente.
      <br />
      <br />
      <strong> Confidencialidad</strong>
      <br />
      Las sesiones de videollamada, audios, mensajes de texto, sesiones de chat,
      correos electrónicos y en sí cualquier otro medio digital por el cual
      mi-lic brinde sus servicios y asesoría al usuario será catalogado como
      información confidencial.
      <br />
      El Usuario y mi-lic se comprometen a guardar absoluta confidencialidad de
      la información revelada o proporcionada entre ellas (ya sea de forma
      escrita, verbal, electrónica, magnética o cualquier otra tecnología) que
      pueda ser considerada como confidencial y propiedad de cada una de las
      partes, así como toda la que se genere derivada de la relación comercial
      entre ambos mediante el uso del sitio www.mi-lic.mx
      <br />
      <br />
      <strong> Política de Privacidad</strong>
      <br />
      La recopilación, tratamiento y el uso que realiza Mi-Lic de la información
      personal en relación con los servicios proporcionados, se realiza de
      conformidad con el Aviso de Privacidad disponible en www.mi-lic.mx
      <br />
      <br />
      <strong> Cookies</strong>
      <br />
      Al momento de la aceptación de los presentes Términos y Condiciones,
      expresamente el Usuario autoriza el uso de Cookies por parte del Operador
      en toda actividad de uso que realice en el Sitio Web.
      <br />
      <br />
      <strong>
        {" "}
        Claves de acceso (usuarios que pierden su contraseña, etc.)
      </strong>
      <br />
      Al momento de registrarse en la página www.mi-lic.mx el usuario generará
      una contraseña, misma que junto a su correo electrónico o red social su
      elección, le permitirá acceder al sitio web mencionado, para que pueda
      contratar los servicios ofertados por mi-lic.
      <br />
      El usuario que creó la cuenta de mi-lic y a quien se le facturan los
      cargos a través de su Forma de Pago es el responsable de cualquier
      actividad que ocurra bajo la cuenta de dicho usuario en mi-lic. Para
      mantener el control sobre la cuenta del usuario y evitar que alguien
      acceda a la misma, el usuario titular de la cuenta deberá mantener el
      control sobre los dispositivos que se utilizan para acceder a la cuenta
      del usuario en el Sitio Web, y no revelar a nadie la contraseña ni la
      información de la forma de pago asociada con la cuenta del usuario, siendo
      su responsabilidad el manejo que haga de la misma, así como actualizar y
      mantener la exactitud de la información personal que nos brinda respecto
      de su cuenta. Mi-lic podrá decidir cancelar o suspender la cuenta con el
      propósito de proteger al usuario, a mi-lic o a terceros contra el robo de
      identidad u otra actividad fraudulenta.
      <br />
      Para la creación de una cuenta de usuario (en adelante la “Cuenta de
      Usuario”) se solicitarán datos como, incluyendo sin limitar, nombre,
      dirección de correo electrónico; esta información se utiliza para (i) la
      plena identificación de los Usuarios que pretenden adquirir los servicios,
      para (ii) el cumplimiento de la prestación de los Servicios, para (iii) la
      prevención de fraudes, (iv) y en general para los fines definidos en el
      capítulo correspondiente al Manejo de información.
      <br />
      Los Usuarios en caso de tener cuentas en las redes sociales Facebook y
      Google +, podrán crear su Cuenta de Usuario con la utilización de las
      credenciales allí definidas, para lo cual el Sitio Web comunicará esta
      opción.
      <br />
      Una vez suministrada la información necesaria para el uso del Sitio Web,
      se validará por parte del Operador el nombre de usuario y contraseña para
      acceder al perfil del Usuario en el Sitio Web.
      <br />
      Podrán los Usuarios, además de la información obligatoria y facultativa
      requerida al momento de la creación de la cuenta, suministrar
      voluntariamente más datos relacionados con su individualización al momento
      en que cree su propio perfil dentro del Sitio Web.
      <br />
      El uso de la Cuenta de Usuario es personal e intransferible, por lo cual
      los Usuarios no se encuentran facultados para ceder los datos de
      validación para el acceso al Sitio Web. En caso de olvido de los datos de
      validación o de usurpación de éstos, es obligación del Usuario informarlo
      al Operador a través de la opción “olvidó su contraseña” o a través de
      comunicación enviada al correo electrónico: contacto@mi-lic.mx
      <br />
      Las cuentas de usuarios serán administradas por el Operador a su
      discreción o por la persona que éste designe, teniendo plena facultad para
      la conservación o no de la cuenta, cuando la información suministrada por
      los Usuarios no sea veraz, completa o segura; o cuando se presente
      incumplimiento de las obligaciones de los Usuarios.
      <br />
      Con la creación de la Cuenta de Usuario, los Usuarios están manifestando
      su voluntad de aceptación expresa e inequívoca de los presentes Términos y
      Condiciones de uso del Sitio Web.
      <br />
      <br />
      <strong> Funcionamiento Sitio Web</strong>
      <br />
      El Operador administra directamente o por medio de terceras personas el
      Sitio Web, toda la información que se comunica allí corresponde a
      información cierta y actualizada. En ningún caso responderá por daños
      directos o indirectos que sufra el Usuario por la utilización o
      incapacidad de utilización del Sitio Web.
      <br />
      El Sitio Web se encuentra disponible las 24 (veinte y cuatro) horas del
      día para su acceso y consulta. Para la realización de transacciones, la
      disponibilidad del Sitio Web es de 24 (veinte y cuatro) horas al día,
      dependiendo en ciertos casos, de la disponibilidad de los abogados y de
      los proveedores de los Servicios. El Operador realizará los mejores
      esfuerzos para mantener en operación el Sitio Web, pero en ningún caso
      garantiza disponibilidad y continuidad permanente del Sitio Web.
      <br />
      El Operador se reserva el derecho de cancelar cualquier Cuenta de Usuario
      y/o de prohibir el acceso al Sitio Web a cualquier Usuario que realice
      conductas violatorias de los presentes Términos y Condiciones o que
      incumplan las obligaciones contraídas.
      <br />
      <br />
      <strong> Contacto </strong>
      <br />
      mi-Lic pone a su disposición el correo electrónico contacto@mi-lic.mx a
      efecto de que usted pueda presentar quejas, sugerencias o aclaraciones del
      servicio.
      <br />
      <br />
      <strong> Modificaciones </strong>
      <br />
      mi-lic podrá modificar libremente y en cualquier momento en aspectos
      formales, procedimentales o sustanciales los Términos y Condiciones de uso
      de www.mi-lic.mx , los cuales serán actualizados y puestos a disposición
      de los Usuarios en el Sitio Web, siendo la última versión publicada la que
      regulará inmediatamente las relaciones comerciales que se generen al
      momento de realizarse la transacción.
      <br />
      mi-lic cuenta con plena autonomía para modificar los usos del Sitio Web
      permitidos, con el único deber de informar a los Usuarios por un medio
      virtual que permita su publicación y comunicación al público en general.
      <br />
      mi-lic podrá modificar los Términos y Condiciones relativos a los
      Servicios cuando lo considere oportuno. Las modificaciones serán efectivas
      después de la publicación por parte de mi-lic de dichos Términos y
      Condiciones actualizados en esta ubicación o las políticas modificadas o
      términos y condiciones suplementarios sobre el Servicio aplicable. Su
      acceso al Sitio Web o uso continuado de los Servicios después de dicha
      publicación constituye su consentimiento a vincularse por los Términos y
      Condiciones y sus modificaciones.
      <br />
      <br />
      <strong> Leyes Aplicables y Jurisdicción</strong>
      <br />
      El usuario y mi-lic están de acuerdo en que para el caso de conflicto o
      disputa la interpretación, ejecución y cumplimiento de los presentes
      términos y condiciones será de conformidad con la legislación vigente en
      el Estado de Nuevo León, así mismo se someten exclusivamente a los
      Tribunales competentes en la ciudad de Monterrey, Nuevo León, renunciando
      en este acto a cualquier otro fuero que les pudiere corresponder en virtud
      de sus domicilios presentes o futuros, o por cualquier otro modo.
      <br />
      <br />
      <strong>Comercio Electrónico:</strong>
      <br />
      En cumplimiento de las disposiciones mexicanas sobre Mensajes de Datos
      según el Código Civil Federal, se comunica que la legislación mexicana
      reconoce validez a los mensajes por medios electrónicos y por tanto ellos
      adquieren carácter y entidad probatoria. En consecuencia, entienden los
      Usuarios, que mediante el cruce de mensajes por medios electrónicos, el
      Usuario y el Operador pueden dar lugar al nacimiento, modificación y
      extinción de obligaciones, siendo de su responsabilidad exclusiva el
      contenido, consecuencias, responsabilidades y efectos de la información
      generada.
      <br />
      La transacción comercial que nace por este medio entre Usuarios y el
      Operador, es la celebración de un contrato de prestación de servicios, el
      cual se describe en la Ventana Emergente que acepta el Consumidor al
      momento de la celebración del negocio jurídico. En ningún momento se
      configura alguna relación contractual diferente como suministro,
      distribución o demás similares.
      <br />
      <br />
      <strong>Manejo de información</strong>
      <br />
      La información recolectada por el Operador en las transacciones
      realizadas, es suministrada por los Usuarios de forma libre y voluntaria,
      para que esta sea administrada por el Operador o por quien éste designe
      para el cumplimiento de los deberes adquiridos, lo que implica su
      recolección; almacenamiento en servidores o repositorios del Operador o de
      terceros; circulación de los mismos dentro de la organización del
      Operador; comunicación a los Usuarios de información comercial,
      publicitaria y de mercadeo relacionada con su actividad.
      <br />
      Asimismo, los datos recolectados serán objeto de análisis para fines de
      mejorar la estrategia de negocios del Sitio Web, apoyada en herramientas,
      incluyendo sin limitar, de inteligencia de negocios y minería de datos,
      que permiten adquirir conocimientos prospectivos para fines de predicción,
      clasificación y segmentación.
      <br />
      El Consumidor podrá ejercer su derecho de conocer, actualizar, modificar y
      suprimir los Datos Personales existentes en las bases de datos asociadas
      al Sitio Web. Para ello, deberá realizar la solicitud de consulta, reclamo
      o supresión a la dirección electrónica: contacto@mi-lic.mx detallando por
      escrito las modificaciones a realizar y aportando los documentos que lo
      soportan.
      <br />
      El Operador es responsable del tratamiento de la información personal
      recolectada a través del Sitio Web, responsabilidad que podrá delegar en
      un tercero, en calidad de responsable o encargado de la información,
      asegurando contractualmente adecuado tratamiento de la misma.
      <br />
      Asimismo, al aceptar los presentes términos y condiciones el Usuario
      manifiesta expresamente haber leído y aceptado el aviso de privacidad del
      Operador, disponible en la dirección electrónica:
      https://www.mi-lic.mx/aviso-privacidad/
      <br />
      <br />
      <strong>Aceptación Total de los Términos</strong>
      <br />
      El Usuario manifiesta expresamente haber suministrado información real,
      veraz y fidedigna. Por ende, de forma expresa e inequívoca declara que ha
      leído, que entiende y que acepta la totalidad de los supuestos previstos y
      regulados en el presente escrito de Términos y Condiciones de uso del
      Sitio Web, por lo que se compromete al cumplimiento total de los deberes,
      obligaciones, acciones y omisiones aquí expresadas.
      <br />
      En caso de que Usuarios de otros países utilicen el Sitio Web, se sujetan
      completamente a lo dispuesto en los presentes Términos y Condiciones de
      uso del Sitio Web.
    </p>
  </div>
);
