// @antd
import { ClockCircleOutlined } from "@ant-design/icons";
import { Button, Col, Result, Row, Typography } from "antd";
import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import Page from "../../components/Page";
import { PayProvider } from "../../contexts/PayContext";
import usePay from "../../hooks/usePay";
import { PATH_PAGE } from "../../routes/paths";
import { cleanStorage } from "../../utils/storage";

function CompletePayStoreComponent() {
  const { checkPay, payPending, payInfo } = usePay();
  const [service, setService] = useState(null);

  const [title, setTitle] = useState(
    "Solicitud de servicio completado exitosamente"
  );

  const location = useLocation();
  useEffect(() => {
    if (location.search) {
      setService(new URLSearchParams(location.search).get("service"));
    }
  }, [location]);

  useEffect(() => {
    if (service) {
      let tl = "Servicio solicitado exitosamente";
      if (service === "document_for_reviewing")
        tl = "Servicio de revisión de documentos solicitado exitosamente";
      if (service === "contract")
        tl = "Servicio de contrato solicitado exitosamente";

      setTitle(tl);
    }
  }, [service]);

  useEffect(() => {
    cleanStorage("FORM");
    cleanStorage("selectedCard");
  }, []);

  return (
    <Page title="Mi-LIC | Pago Completado">
      <Row>
        <Col style={{ margin: "auto" }} xl={12} lg={12} md={24} sm={24} xs={24}>
          <section
            style={{
              marginTop: 30,
              marginBottom: 30,
              padding: "4% 10% 4% 10%",
            }}
          >
            {payPending ? (
              <Result
                icon={<ClockCircleOutlined className="rotate" />}
                title="Procesando servicio, espere por favor..."
                extra={<></>}
              />
            ) : (
              <div>
                <Result
                  status="success"
                  title={title}
                  subTitle={
                    <>
                      <Typography.Text
                        style={{ fontSize: 16, textAlign: "justify" }}
                      >
                        ¡Hola! Tu servicio fue realizado exitosamente.
                      </Typography.Text>
                      <br />
                      <Typography.Text
                        style={{ fontSize: 16, textAlign: "justify" }}
                      >
                        Se le ha enviado a su correo electrónico, un PDF con las{" "}
                        <strong>
                          {" "}
                          instrucciones de donde puede pagar el servicio
                        </strong>
                      </Typography.Text>
                      <br /> <br />
                      <Typography.Text
                        style={{ fontSize: 16, textAlign: "justify" }}
                      >
                        ¡Gracias por tu confianza y por utilizar los servicios
                        de mi-lic!
                      </Typography.Text>
                    </>
                  }
                  extra={[
                    <Button href={"/"} type="primary" key="console">
                      <Typography.Text style={{ color: "#FFF" }}>
                        {" "}
                        Ir al inicio{" "}
                      </Typography.Text>
                    </Button>,

                    ,
                  ]}
                />
              </div>
            )}
          </section>
        </Col>
      </Row>
    </Page>
  );
}

export default function CompletePayStore() {
  return (
    <PayProvider>
      <CompletePayStoreComponent />
    </PayProvider>
  );
}
