import { Col, Row, Typography } from "antd";
import { MultipleInput } from "../components/form/MultipleInput";
import { NumericInput } from "../components/form/NumericInput";
import { OptGroupInput } from "../components/form/OptGroupInput";
import { PriceInput } from "../components/form/PriceInput";
import { getRules } from "./rules";
import * as moment from "moment";

import visa from "../assets/images/card/visa.png";
import america from "../assets/images/card/america.png";
import carnet from "../assets/images/card/carnet.png";
import bbva from "../assets/images/card/bbva.png";
import santander from "../assets/images/card/santander.png";
import hsbc from "../assets/images/card/hsbc.png";
import scotia from "../assets/images/card/scotia.png";
import inbusa from "../assets/images/card/inbusa.png";
import ixe from "../assets/images/card/ixe.png";

import openpay from "../assets/images/card/openpay.jpg";
import mastercard from "../assets/images/card/masterCard.png";

import { fromStorage } from "./storage";
import { CalendarOutlined, ClockCircleOutlined } from "@ant-design/icons";

const render_value = (v) => {
  if (v && typeof v === "string" && v.includes("$"))
    return "$" + formatPrice(v.replace("$", ""));
  return v;
};

export const cleanNullValues = (dict) => {
  let newDict = {};
  Object.keys(dict).forEach((key) => {
    if (dict[key] !== undefined) {
      newDict = Object.assign(newDict, { [key]: dict[key] });
    }
  });
  return newDict;
};

export const defaultFieldBuild = (entrie, required = true) => {
  return {
    label: entrie.title,
    labelCol: 0,
    colSpan: 2,
    title: +" ID:" + entrie.variable.id,
    key: "" + entrie.variable.id,
    placeholder: entrie.title,
    wrapperCol: 24,
    widgetProps: {
      size: "large",
    },

    rules: getRules(entrie.variable.rules),
  };
};

export const generateRadioField = (entrie, options) => {
  let field = defaultFieldBuild(entrie);
  field = Object.assign(field, { widget: "radio-group" });
  if (options.length > 0) field = Object.assign(field, { options });
  return field;
};

//SIMPLE FIELD
export const generateSimpleField = (entrie, widget) => {
  let options = [];
  let field = defaultFieldBuild(entrie);
  //console.log(field);
  field = Object.assign(field, { widget });
  if (options.length > 0) field = Object.assign(field, { options });
  return field;
};

//TIME FIELD
export const generateTimeField = (entrie) => {
  let field = defaultFieldBuild(entrie);
  field = Object.assign(field, {
    widget: "date-picker",
    widgetProps: {
      mode: "time",
      size: "large",
    },
  });

  return field;
};

//Multiple FIELD
export const generateMultipleField = (entrie, pendingFormContract) => {
  let field = defaultFieldBuild(entrie);
  field = Object.assign(field, {
    widget: MultipleInput,
    widgetProps: {
      label: entrie.variable.name,
      name: entrie.variable.id,
      type: entrie.variable.primary_type,
      entrie: entrie,
      disabled: pendingFormContract,
    },
    rules: [
      {
        required: true,
        message: "Este campo es requerido",
      },
    ],
  });
  return [field];
};

//Number FIELD
export const generateNumberField = (entrie) => {
  let field = defaultFieldBuild(entrie);
  field = Object.assign(field, {
    widget: NumericInput,
    rules: [...field.rules],
  });
  return field;
};
//COIN FIELD
export const generateCoinField = (entrie) => {
  let field = defaultFieldBuild(entrie);
  field = Object.assign(field, {
    widget: PriceInput,
    rules: [...field.rules],
  });
  return field;
};

export const generateUrlField = (entrie) => {
  let field = defaultFieldBuild(entrie);
  field = Object.assign(field, {
    widget: "input",
    extra: "Debe comenzar por http:// o https://",
    rules: [...field.rules],
  });
  return field;
};
//LISTA FIELD

//COMPOSE FIELD

export const TitleCardForm = ({ cost }) => (
  <>
    <Typography.Text>
      Costo del servicio:
      <Typography.Text className="price-render" strong>
        {" "}
        {`$${cost}`}{" "}
      </Typography.Text>
      .
      <br />
      {`La operación se ha realizado exitosamente, para que sea válido solo falta un último paso, seleccionar el método y efectuar el pago.`}
    </Typography.Text>
  </>
);

export const OpenPay = ({ cost }) => (
  <>
    <div>
      Transacciones realizadas vía:
      <img
        alt="openpay"
        style={{ maxWidth: 200, maxHeight: 80 }}
        className="card"
        src={openpay}
      />
    </div>
    <Typography.Text style={{ fontSize: 10 }}>
      Tus pagos se realizan de forma segura con encriptación de 256 bits
    </Typography.Text>
  </>
);
export const CardInfo = () => (
  <Row style={{ textAlign: "center" }}>
    <Col xl={12} lg={12} md={12} sm={12} xs={24}>
      <Typography.Text strong>Tarjetas de débito:</Typography.Text>
      <Row style={{ textAlign: "center" }}>
        <Col xl={8} lg={12} md={12} sm={12} xs={24}>
          <img alt="visa" className="card" src={visa} />
        </Col>
        <Col xl={8} lg={12} md={12} sm={12} xs={24}>
          <img alt="mc" className="card" src={mastercard} />
        </Col>
        <Col xl={8} lg={12} md={12} sm={12} xs={24}>
          <img alt="ame" className="card" src={america} />
        </Col>
        <Col xl={8} lg={12} md={12} sm={12} xs={24}>
          <img alt="crt" className="card" src={carnet} />
        </Col>
      </Row>
    </Col>
    <Col xl={12} lg={12} md={12} sm={12} xs={24}>
      <Typography.Text strong>Tarjetas de crédito:</Typography.Text>

      <Row style={{ textAlign: "center" }}>
        <Col xl={8} lg={12} md={12} sm={12} xs={24}>
          <img className="card" src={bbva} />
        </Col>
        <Col xl={8} lg={12} md={12} sm={12} xs={24}>
          <img alt="san" className="card" src={santander} />
        </Col>
        <Col xl={8} lg={12} md={12} sm={12} xs={24}>
          <img alt="jsdd" className="card" src={hsbc} />
        </Col>
        <Col xl={8} lg={12} md={12} sm={12} xs={24}>
          <img alt="sco" className="card" src={scotia} />
        </Col>
        <Col xl={8} lg={12} md={12} sm={12} xs={24}>
          <img alt="inbuda" className="card" src={inbusa} />
        </Col>
        <Col xl={8} lg={12} md={12} sm={12} xs={24}>
          <img alt="ixe" className="card" src={ixe} />
        </Col>
      </Row>
    </Col>
  </Row>
);

const getValue = (values, key) => {
  let v = "";
  if (values[key]) {
    v = values[key];
    if (typeof v === "object")
      if (moment.isMoment(v)) v = v.format("YYYY-MM-DD");
      else v = JSON.stringify(v);
    else v = v;
  }
  return v;
};

const getExtraByType = (primary_type) => {
  switch (primary_type) {
    case "fecha":
      return {
        icon: <CalendarOutlined />,
        prefix: "",
      };
      break;

    case "email":
      return {
        icon: null,
        prefix: "",
      };
      break;
    case "moneda":
      return {
        icon: null,
        prefix: "$",
      };
      break;
    case "hora":
      return {
        icon: <ClockCircleOutlined />,
        prefix: "",
      };
      break;
    default:
      return {
        icon: null,
        prefix: "",
      };
      break;
  }
};
export const getSummary = (dataValues) => {
  let form = JSON.parse(fromStorage("MILIC-FORM-BUILD"));
  let dataValue = [];

  /// { var_id: var_id, value: v }
  let mapData = [];
  form.steps.forEach((step, index) => {
    let stepTitle = `Paso ${index + 1}`;
    let entries = step.entries;
    let values = [];
    entries.forEach((entrie) => {
      let variable = entrie.variable;
      let value;
      let label = "";
      let isCompose = false;
      let isCond = false;
      let isMultiple = false;
      let isLista = false;
      if (variable.is_multiple === 1 && variable.primary_type !== "lista") {
        label = entrie.title;
        let childrens = variable.childrens;
        let multipleValue = JSON.parse(getValue(dataValues, variable.id));
        let isArrayObject =
          multipleValue.length > 0 && typeof multipleValue[0] === "object";
        let composes = [];
        if (isArrayObject) {
          // console.log("Array compose");
          multipleValue.forEach((v) => {
            //console.log(v);
            let item = [];
            Object.keys(v).forEach((key) => {
              if (childrens.find((e) => e.id == key)) {
                item.push({
                  label: childrens.find((e) => e.id == key).name,
                  value:
                    !isNumeric(v[key] + "") && !isNaN(Date.parse(v[key]))
                      ? moment(v[key]).format("YYYY-MM-DD")
                      : v[key],
                  ...getExtraByType(
                    childrens.find((e) => e.id == key).primary_type
                  ),
                });
                dataValue.push({
                  var_id: key,
                  value:
                    !isNumeric(v[key] + "") && !isNaN(Date.parse(v[key]))
                      ? moment(v[key]).format("YYYY-MM-DD")
                      : v[key],
                });
              }
            });
            composes.push(item);
          });
        } else {
          multipleValue.forEach((e, index) => {
            dataValue.push({
              var_index: index,
              var_id: variable.id,
              value:
                !isNumeric(e + "") && !isNaN(Date.parse(e))
                  ? moment(e).format("YYYY-MM-DD")
                  : e,
            });

            values.push({
              label: label + "#" + (index + 1),
              value:
                !isNumeric(e + "") && !isNaN(Date.parse(e))
                  ? moment(e).format("YYYY-MM-DD")
                  : e,
              isCompose: true,
              isMultiple: true,
              isLista: true,
              ...getExtraByType(variable.primary_type),
            });
          });
        }
        isMultiple = true;
        value = composes;
      } else {
        switch (variable.primary_type) {
          case "condicionante":
            isCond = true;
            let conditions = entrie.conditions;
            label = entrie.title;
            let condicionanteValue = getValue(dataValues, variable.id);

            variable.group_answers.forEach((group_answer) => {
              //console.log(group_answer.answer_options);
              group_answer.answer_options.forEach((answer_option) => {
                if (answer_option.id == condicionanteValue)
                  value = answer_option.name;
              });
            });

            dataValue.push({ var_id: variable.id, value: value });

            values.push({
              label: label,
              value: value,
              isCompose,
              isMultiple,
              isLista,
              ...getExtraByType(variable.primary_type),
            });

            conditions.forEach((condition) => {
              if (condition.condition_value === value) {
                //  console.log("condition.condition_value: "+condition.condition_value);

                if (condition.variable.primary_type === "compuesto") {
                  let childrens = condition.variable.childrens;
                  let composes = [];

                  childrens.forEach((children) => {
                    let valueID = getValue(dataValues, children.id);
                    if (children.primary_type === "lista") {
                      children.group_answers.forEach((group_answer) => {
                        //console.log(group_answer.answer_options);
                        group_answer.answer_options.forEach((answer_option) => {
                          if (answer_option.id == valueID)
                            value = answer_option.name;
                        });
                      });

                      composes.push({
                        label: children.name,
                        value: value,
                        ...getExtraByType(children.primary_type),
                      });
                      dataValue.push({
                        var_id: children.id,
                        value: value,
                      });
                    } else {
                      composes.push({
                        label: children.name,
                        value: getValue(dataValues, children.id),
                        ...getExtraByType(children.primary_type),
                      });
                      dataValue.push({
                        var_id: children.id,
                        value: getValue(dataValues, children.id),
                      });
                    }
                  });

                  values.push({
                    label: condition.title,
                    value: composes,
                    isCompose: true,
                    isMultiple: false,
                    isLista: false,
                    ...getExtraByType(variable.primary_type),
                  });
                } else {
                  let vvv;
                  try {
                    vvv = JSON.parse(
                      getValue(dataValues, condition.variable.id)
                    ).join(", ");
                  } catch (error) {
                    vvv = getValue(dataValues, condition.variable.id);
                  }

                  //   console.log("vvv: "+vvv);
                  // console.log("condition.variable.id: "+condition.variable.id);

                  let readeValue = vvv;
                  if (condition.variable.primary_type === "lista") {
                    condition.variable.group_answers.forEach((group_answer) => {
                      group_answer.answer_options.forEach((answer_option) => {
                        if (answer_option.id == readeValue)
                          readeValue = answer_option.name;
                      });
                    });
                  }
                  /*
                  variable.group_answers.forEach((group_answer) => {
                    //console.log(group_answer.answer_options);
                    group_answer.answer_options.forEach((answer_option) => {
                      if (answer_option.id == condicionanteValue)
                      readeValue = answer_option.name;
                    });
                  });
                  console.log(readeValue);
                  */
                  //console.log(vvv);
                  dataValue.push({
                    var_id: condition.variable.id,
                    value: readeValue,
                  });
                  values.push({
                    label: condition.title,
                    value: readeValue,
                    isCompose,
                    isMultiple,
                    isLista,
                    ...getExtraByType(condition.variable.primary_type),
                  });
                }
              }
            });

            // console.log(dataValue);
            //  console.log(values);

            break;

          case "compuesto":
            label = entrie.title;
            isCompose = true;
            let childrens = variable.childrens;
            let composes = [];
            childrens.forEach((children) => {
              if (children.primary_type === "lista") {
                let optID = getValue(dataValues, children.id);
                let optValue = "";
                children.group_answers.forEach((group_answer) => {
                  group_answer.answer_options.forEach((answer_option) => {
                    if (answer_option.id == optID)
                      optValue = answer_option.name;
                  });
                });

                dataValue.push({ var_id: children.id, value: optValue });
                composes.push({
                  label: children.name,
                  value: optValue,
                  ...getExtraByType(children.primary_type),
                });
              } else {
                composes.push({
                  label: children.name,
                  value: getValue(dataValues, children.id),
                  ...getExtraByType(children.primary_type),
                });
                dataValue.push({
                  var_id: children.id,
                  value: getValue(dataValues, children.id),
                });
              }
            });
            value = composes;
            break;
          case "lista":
            label = entrie.title;
            if (variable.is_multiple === 1) {
              value = JSON.parse(getValue(dataValues, variable.id));
              let valueAsString = [];
              let group_answers = variable.group_answers;

              value.forEach((valueID) => {
                let valueAsStringTemp = valueID;
                group_answers.forEach((group_answer) => {
                  //console.log(group_answer.answer_options);
                  group_answer.answer_options.forEach((answer_option) => {
                    if (answer_option.id == valueID)
                      valueAsStringTemp = answer_option.name;
                  });
                });
                valueAsString.push(valueAsStringTemp);
              });

              dataValue.push({
                var_id: variable.id,
                value: valueAsString.join(","),
              });

              value = valueAsString;
            } else {
              let group_answers = variable.group_answers;
              let varValue = getValue(dataValues, variable.id);
              if (varValue == -1) {
                value = getValue(dataValues, variable.id + "-1");
              } else {
                group_answers.forEach((group_answer) => {
                  //console.log(group_answer.answer_options);
                  group_answer.answer_options.forEach((answer_option) => {
                    if (answer_option.id == getValue(dataValues, variable.id))
                      value = answer_option.name;
                  });
                });
              }
              // console.log(value);
              //  console.log(variable.id);
              dataValue.push({
                var_id: variable.id,
                value: value,
              });
            }

            isLista = true;
            break;
          default:
            label = entrie.title;
            value = getValue(dataValues, variable.id);
            dataValue.push({
              var_id: variable.id,
              value: value,
            });
            break;
        }
      }
      if (!isCond)
        values.push({
          label: label,
          value: value,
          isCompose,
          isMultiple,
          isLista,
          ...getExtraByType(variable.primary_type),
        });
    });
    mapData.push({
      title: stepTitle,
      values: values,
    });
  });

  console.log(dataValue);
  console.log(mapData);

  return {
    mapData,
    dataValue,
  };
};

export const listaRender = (values) => {
  return (
    <Row>
      {values.map((c, ci) => (
        <Col key={ci + "-asas"}>
          {" "}
          <Typography.Text
            className="capitalize"
            strong
            style={{ fontSize: 12 }}
          >
            <span style={{ fontSize: 14 }}>{c.label}:&nbsp; </span>
          </Typography.Text>
          <Typography.Text style={{ fontSize: 14 }}>
            {render_value(c.value)}
          </Typography.Text>
          {ci !== values.length - 1 && (
            <Typography.Text>{", "}&nbsp;</Typography.Text>
          )}
        </Col>
      ))}
    </Row>
  );
};

function isNumeric(str) {
  if (typeof str != "string") return false; // we only process strings!
  return (
    !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
    !isNaN(parseFloat(str))
  ); // ...and ensure strings of whitespace fail
}

export const formatPrice = (p) => {
  if (typeof price === "number") return parseFloat(p).toFixed(2) + " pesos";
  if (isNumeric(p + "")) return parseFloat(p + "").toFixed(2) + " pesos";
  else return parseFloat(0).toFixed(2) + " pesos";
};

export const getState = () => {
  return [
    {
      id: 1,
      name: "Aguascalientes",
      var_id: 237,
      show_header: true,
      answer_options: [
        {
          id: 1,
          name: "Aguascalientes",
          group_id: 1,
        },
        {
          id: 9,
          name: "Asientos",
          group_id: 1,
        },
        {
          id: 10,
          name: "Calvillo",
          group_id: 1,
        },
        {
          id: 5,
          name: "Cosío",
          group_id: 1,
        },
        {
          id: 3,
          name: "El Llano",
          group_id: 1,
        },
        {
          id: 11,
          name: "Jesús María",
          group_id: 1,
        },
        {
          id: 8,
          name: "Pabellón de Arteaga",
          group_id: 1,
        },
        {
          id: 4,
          name: "Rincón de Romos",
          group_id: 1,
        },
        {
          id: 2,
          name: "San Francisco de los Romo",
          group_id: 1,
        },
        {
          id: 6,
          name: "San José de Gracia",
          group_id: 1,
        },
        {
          id: 7,
          name: "Tepezalá",
          group_id: 1,
        },
      ],
    },
    {
      id: 2,
      name: "Baja California",
      var_id: 237,
      show_header: true,
      answer_options: [
        {
          id: 16,
          name: "Ensenada",
          group_id: 2,
        },
        {
          id: 12,
          name: "Mexicali",
          group_id: 2,
        },
        {
          id: 15,
          name: "Playas de Rosarito",
          group_id: 2,
        },
        {
          id: 13,
          name: "Tecate",
          group_id: 2,
        },
        {
          id: 14,
          name: "Tijuana",
          group_id: 2,
        },
      ],
    },
    {
      id: 3,
      name: "Baja California Sur",
      var_id: 237,
      show_header: true,
      answer_options: [
        {
          id: 19,
          name: "Comondú",
          group_id: 3,
        },
        {
          id: 17,
          name: "La Paz",
          group_id: 3,
        },
        {
          id: 20,
          name: "Loreto",
          group_id: 3,
        },
        {
          id: 18,
          name: "Los Cabos",
          group_id: 3,
        },
        {
          id: 21,
          name: "Mulegé",
          group_id: 3,
        },
      ],
    },
    {
      id: 4,
      name: "Campeche",
      var_id: 237,
      show_header: true,
      answer_options: [
        {
          id: 29,
          name: "Calakmul",
          group_id: 4,
        },
        {
          id: 32,
          name: "Calkiní",
          group_id: 4,
        },
        {
          id: 22,
          name: "Campeche",
          group_id: 4,
        },
        {
          id: 25,
          name: "Candelaria",
          group_id: 4,
        },
        {
          id: 23,
          name: "Carmen",
          group_id: 4,
        },
        {
          id: 27,
          name: "Champotón",
          group_id: 4,
        },
        {
          id: 26,
          name: "Escárcega",
          group_id: 4,
        },
        {
          id: 31,
          name: "Hecelchakán",
          group_id: 4,
        },
        {
          id: 28,
          name: "Hopelchén",
          group_id: 4,
        },
        {
          id: 24,
          name: "Palizada",
          group_id: 4,
        },
        {
          id: 30,
          name: "Tenabo",
          group_id: 4,
        },
      ],
    },
    {
      id: 7,
      name: "Chiapas",
      var_id: 237,
      show_header: true,
      answer_options: [
        {
          id: 178,
          name: "Acacoyagua",
          group_id: 7,
        },
        {
          id: 92,
          name: "Acala",
          group_id: 7,
        },
        {
          id: 177,
          name: "Acapetahua",
          group_id: 7,
        },
        {
          id: 137,
          name: "Aldama",
          group_id: 7,
        },
        {
          id: 160,
          name: "Altamirano",
          group_id: 7,
        },
        {
          id: 119,
          name: "Amatán",
          group_id: 7,
        },
        {
          id: 194,
          name: "Amatenango de la Frontera",
          group_id: 7,
        },
        {
          id: 96,
          name: "Amatenango del Valle",
          group_id: 7,
        },
        {
          id: 166,
          name: "Angel Albino Corzo",
          group_id: 7,
        },
        {
          id: 171,
          name: "Arriaga",
          group_id: 7,
        },
        {
          id: 195,
          name: "Bejucal de Ocampo",
          group_id: 7,
        },
        {
          id: 154,
          name: "Bella Vista",
          group_id: 7,
        },
        {
          id: 146,
          name: "Benemérito de las Américas",
          group_id: 7,
        },
        {
          id: 83,
          name: "Berriozábal",
          group_id: 7,
        },
        {
          id: 126,
          name: "Bochil",
          group_id: 7,
        },
        {
          id: 191,
          name: "Cacahoatán",
          group_id: 7,
        },
        {
          id: 150,
          name: "Catazajá",
          group_id: 7,
        },
        {
          id: 133,
          name: "Chalchihuitán",
          group_id: 7,
        },
        {
          id: 89,
          name: "Chamula",
          group_id: 7,
        },
        {
          id: 97,
          name: "Chanal",
          group_id: 7,
        },
        {
          id: 118,
          name: "Chapultenango",
          group_id: 7,
        },
        {
          id: 136,
          name: "Chenalhó",
          group_id: 7,
        },
        {
          id: 86,
          name: "Chiapa de Corzo",
          group_id: 7,
        },
        {
          id: 93,
          name: "Chiapilla",
          group_id: 7,
        },
        {
          id: 113,
          name: "Chicoasén",
          group_id: 7,
        },
        {
          id: 153,
          name: "Chicomuselo",
          group_id: 7,
        },
        {
          id: 145,
          name: "Chilón",
          group_id: 7,
        },
        {
          id: 169,
          name: "Cintalapa",
          group_id: 7,
        },
        {
          id: 114,
          name: "Coapilla",
          group_id: 7,
        },
        {
          id: 151,
          name: "Comitán de Domínguez",
          group_id: 7,
        },
        {
          id: 112,
          name: "Copainalá",
          group_id: 7,
        },
        {
          id: 132,
          name: "El Bosque",
          group_id: 7,
        },
        {
          id: 197,
          name: "El Porvenir",
          group_id: 7,
        },
        {
          id: 179,
          name: "Escuintla",
          group_id: 7,
        },
        {
          id: 107,
          name: "Francisco León",
          group_id: 7,
        },
        {
          id: 155,
          name: "Frontera Comalapa",
          group_id: 7,
        },
        {
          id: 187,
          name: "Frontera Hidalgo",
          group_id: 7,
        },
        {
          id: 183,
          name: "Huehuetán",
          group_id: 7,
        },
        {
          id: 120,
          name: "Huitiupán",
          group_id: 7,
        },
        {
          id: 99,
          name: "Huixtán",
          group_id: 7,
        },
        {
          id: 181,
          name: "Huixtla",
          group_id: 7,
        },
        {
          id: 121,
          name: "Ixhuatán",
          group_id: 7,
        },
        {
          id: 108,
          name: "Ixtacomitán",
          group_id: 7,
        },
        {
          id: 90,
          name: "Ixtapa",
          group_id: 7,
        },
        {
          id: 110,
          name: "Ixtapangajoya",
          group_id: 7,
        },
        {
          id: 170,
          name: "Jiquipilas",
          group_id: 7,
        },
        {
          id: 125,
          name: "Jitotol",
          group_id: 7,
        },
        {
          id: 103,
          name: "Juárez",
          group_id: 7,
        },
        {
          id: 165,
          name: "La Concordia",
          group_id: 7,
        },
        {
          id: 196,
          name: "La Grandeza",
          group_id: 7,
        },
        {
          id: 157,
          name: "La Independencia",
          group_id: 7,
        },
        {
          id: 149,
          name: "La Libertad",
          group_id: 7,
        },
        {
          id: 156,
          name: "La Trinitaria",
          group_id: 7,
        },
        {
          id: 134,
          name: "Larráinzar",
          group_id: 7,
        },
        {
          id: 159,
          name: "Las Margaritas",
          group_id: 7,
        },
        {
          id: 164,
          name: "Las Rosas",
          group_id: 7,
        },
        {
          id: 176,
          name: "Mapastepec",
          group_id: 7,
        },
        {
          id: 158,
          name: "Maravilla Tenejapa",
          group_id: 7,
        },
        {
          id: 147,
          name: "Marqués de Comillas",
          group_id: 7,
        },
        {
          id: 193,
          name: "Mazapa de Madero",
          group_id: 7,
        },
        {
          id: 182,
          name: "Mazatán",
          group_id: 7,
        },
        {
          id: 188,
          name: "Metapa",
          group_id: 7,
        },
        {
          id: 101,
          name: "Mitontic",
          group_id: 7,
        },
        {
          id: 167,
          name: "Montecristo de Guerrero",
          group_id: 7,
        },
        {
          id: 192,
          name: "Motozintla",
          group_id: 7,
        },
        {
          id: 163,
          name: "Nicolás Ruíz",
          group_id: 7,
        },
        {
          id: 144,
          name: "Ocosingo",
          group_id: 7,
        },
        {
          id: 117,
          name: "Ocotepec",
          group_id: 7,
        },
        {
          id: 84,
          name: "Ocozocoautla de Espinosa",
          group_id: 7,
        },
        {
          id: 106,
          name: "Ostuacán",
          group_id: 7,
        },
        {
          id: 87,
          name: "Osumacinta",
          group_id: 7,
        },
        {
          id: 98,
          name: "Oxchuc",
          group_id: 7,
        },
        {
          id: 148,
          name: "Palenque",
          group_id: 7,
        },
        {
          id: 138,
          name: "Pantelhó",
          group_id: 7,
        },
        {
          id: 115,
          name: "Pantepec",
          group_id: 7,
        },
        {
          id: 104,
          name: "Pichucalco",
          group_id: 7,
        },
        {
          id: 175,
          name: "Pijijiapan",
          group_id: 7,
        },
        {
          id: 124,
          name: "Pueblo Nuevo Solistahuacán",
          group_id: 7,
        },
        {
          id: 123,
          name: "Rayón",
          group_id: 7,
        },
        {
          id: 102,
          name: "Reforma",
          group_id: 7,
        },
        {
          id: 129,
          name: "Sabanilla",
          group_id: 7,
        },
        {
          id: 140,
          name: "Salto de Agua",
          group_id: 7,
        },
        {
          id: 131,
          name: "San Andrés Duraznal",
          group_id: 7,
        },
        {
          id: 88,
          name: "San Cristóbal de las Casas",
          group_id: 7,
        },
        {
          id: 82,
          name: "San Fernando",
          group_id: 7,
        },
        {
          id: 128,
          name: "San Juan Cancuc",
          group_id: 7,
        },
        {
          id: 94,
          name: "San Lucas",
          group_id: 7,
        },
        {
          id: 135,
          name: "Santiago el Pinar",
          group_id: 7,
        },
        {
          id: 198,
          name: "Siltepec",
          group_id: 7,
        },
        {
          id: 130,
          name: "Simojovel",
          group_id: 7,
        },
        {
          id: 139,
          name: "Sitalá",
          group_id: 7,
        },
        {
          id: 168,
          name: "Socoltenango",
          group_id: 7,
        },
        {
          id: 109,
          name: "Solosuchiapa",
          group_id: 7,
        },
        {
          id: 127,
          name: "Soyaló",
          group_id: 7,
        },
        {
          id: 85,
          name: "Suchiapa",
          group_id: 7,
        },
        {
          id: 186,
          name: "Suchiate",
          group_id: 7,
        },
        {
          id: 105,
          name: "Sunuapa",
          group_id: 7,
        },
        {
          id: 185,
          name: "Tapachula",
          group_id: 7,
        },
        {
          id: 116,
          name: "Tapalapa",
          group_id: 7,
        },
        {
          id: 122,
          name: "Tapilula",
          group_id: 7,
        },
        {
          id: 111,
          name: "Tecpatán",
          group_id: 7,
        },
        {
          id: 100,
          name: "Tenejapa",
          group_id: 7,
        },
        {
          id: 95,
          name: "Teopisca",
          group_id: 7,
        },
        {
          id: 141,
          name: "Tila",
          group_id: 7,
        },
        {
          id: 173,
          name: "Tonalá",
          group_id: 7,
        },
        {
          id: 162,
          name: "Totolapa",
          group_id: 7,
        },
        {
          id: 142,
          name: "Tumbalá",
          group_id: 7,
        },
        {
          id: 189,
          name: "Tuxtla Chico",
          group_id: 7,
        },
        {
          id: 81,
          name: "Tuxtla Gutiérrez",
          group_id: 7,
        },
        {
          id: 184,
          name: "Tuzantán",
          group_id: 7,
        },
        {
          id: 152,
          name: "Tzimol",
          group_id: 7,
        },
        {
          id: 190,
          name: "Unión Juárez",
          group_id: 7,
        },
        {
          id: 161,
          name: "Venustiano Carranza",
          group_id: 7,
        },
        {
          id: 180,
          name: "Villa Comaltitlán",
          group_id: 7,
        },
        {
          id: 174,
          name: "Villa Corzo",
          group_id: 7,
        },
        {
          id: 172,
          name: "Villaflores",
          group_id: 7,
        },
        {
          id: 143,
          name: "Yajalón",
          group_id: 7,
        },
        {
          id: 91,
          name: "Zinacantán",
          group_id: 7,
        },
      ],
    },
    {
      id: 8,
      name: "Chihuahua",
      var_id: 237,
      show_header: true,
      answer_options: [
        {
          id: 220,
          name: "Ahumada",
          group_id: 8,
        },
        {
          id: 223,
          name: "Aldama",
          group_id: 8,
        },
        {
          id: 261,
          name: "Allende",
          group_id: 8,
        },
        {
          id: 202,
          name: "Aquiles Serdán",
          group_id: 8,
        },
        {
          id: 206,
          name: "Ascensión",
          group_id: 8,
        },
        {
          id: 203,
          name: "Bachíniva",
          group_id: 8,
        },
        {
          id: 253,
          name: "Balleza",
          group_id: 8,
        },
        {
          id: 245,
          name: "Batopilas de Manuel Gómez Morín",
          group_id: 8,
        },
        {
          id: 234,
          name: "Bocoyna",
          group_id: 8,
        },
        {
          id: 210,
          name: "Buenaventura",
          group_id: 8,
        },
        {
          id: 255,
          name: "Camargo",
          group_id: 8,
        },
        {
          id: 238,
          name: "Carichí",
          group_id: 8,
        },
        {
          id: 208,
          name: "Casas Grandes",
          group_id: 8,
        },
        {
          id: 199,
          name: "Chihuahua",
          group_id: 8,
        },
        {
          id: 242,
          name: "Chínipas",
          group_id: 8,
        },
        {
          id: 265,
          name: "Coronado",
          group_id: 8,
        },
        {
          id: 221,
          name: "Coyame del Sotol",
          group_id: 8,
        },
        {
          id: 200,
          name: "Cuauhtémoc",
          group_id: 8,
        },
        {
          id: 235,
          name: "Cusihuiriachi",
          group_id: 8,
        },
        {
          id: 226,
          name: "Delicias",
          group_id: 8,
        },
        {
          id: 229,
          name: "Dr. Belisario Domínguez",
          group_id: 8,
        },
        {
          id: 252,
          name: "El Tule",
          group_id: 8,
        },
        {
          id: 209,
          name: "Galeana",
          group_id: 8,
        },
        {
          id: 211,
          name: "Gómez Farías",
          group_id: 8,
        },
        {
          id: 236,
          name: "Gran Morelos",
          group_id: 8,
        },
        {
          id: 233,
          name: "Guachochi",
          group_id: 8,
        },
        {
          id: 218,
          name: "Guadalupe",
          group_id: 8,
        },
        {
          id: 248,
          name: "Guadalupe y Calvo",
          group_id: 8,
        },
        {
          id: 244,
          name: "Guazapares",
          group_id: 8,
        },
        {
          id: 204,
          name: "Guerrero",
          group_id: 8,
        },
        {
          id: 260,
          name: "Hidalgo del Parral",
          group_id: 8,
        },
        {
          id: 251,
          name: "Huejotitán",
          group_id: 8,
        },
        {
          id: 212,
          name: "Ignacio Zaragoza",
          group_id: 8,
        },
        {
          id: 207,
          name: "Janos",
          group_id: 8,
        },
        {
          id: 264,
          name: "Jiménez",
          group_id: 8,
        },
        {
          id: 217,
          name: "Juárez",
          group_id: 8,
        },
        {
          id: 224,
          name: "Julimes",
          group_id: 8,
        },
        {
          id: 258,
          name: "La Cruz",
          group_id: 8,
        },
        {
          id: 262,
          name: "López",
          group_id: 8,
        },
        {
          id: 213,
          name: "Madera",
          group_id: 8,
        },
        {
          id: 243,
          name: "Maguarichi",
          group_id: 8,
        },
        {
          id: 225,
          name: "Manuel Benavides",
          group_id: 8,
        },
        {
          id: 216,
          name: "Matachí",
          group_id: 8,
        },
        {
          id: 263,
          name: "Matamoros",
          group_id: 8,
        },
        {
          id: 228,
          name: "Meoqui",
          group_id: 8,
        },
        {
          id: 247,
          name: "Morelos",
          group_id: 8,
        },
        {
          id: 241,
          name: "Moris",
          group_id: 8,
        },
        {
          id: 214,
          name: "Namiquipa",
          group_id: 8,
        },
        {
          id: 232,
          name: "Nonoava",
          group_id: 8,
        },
        {
          id: 205,
          name: "Nuevo Casas Grandes",
          group_id: 8,
        },
        {
          id: 240,
          name: "Ocampo",
          group_id: 8,
        },
        {
          id: 222,
          name: "Ojinaga",
          group_id: 8,
        },
        {
          id: 219,
          name: "Praxedis G. Guerrero",
          group_id: 8,
        },
        {
          id: 201,
          name: "Riva Palacio",
          group_id: 8,
        },
        {
          id: 227,
          name: "Rosales",
          group_id: 8,
        },
        {
          id: 250,
          name: "Rosario",
          group_id: 8,
        },
        {
          id: 231,
          name: "San Francisco de Borja",
          group_id: 8,
        },
        {
          id: 259,
          name: "San Francisco de Conchos",
          group_id: 8,
        },
        {
          id: 249,
          name: "San Francisco del Oro",
          group_id: 8,
        },
        {
          id: 254,
          name: "Santa Bárbara",
          group_id: 8,
        },
        {
          id: 237,
          name: "Santa Isabel",
          group_id: 8,
        },
        {
          id: 230,
          name: "Satevó",
          group_id: 8,
        },
        {
          id: 256,
          name: "Saucillo",
          group_id: 8,
        },
        {
          id: 215,
          name: "Temósachic",
          group_id: 8,
        },
        {
          id: 246,
          name: "Urique",
          group_id: 8,
        },
        {
          id: 239,
          name: "Uruachi",
          group_id: 8,
        },
        {
          id: 257,
          name: "Valle de Zaragoza",
          group_id: 8,
        },
      ],
    },
    {
      id: 9,
      name: "Ciudad de México",
      var_id: 237,
      show_header: true,
      answer_options: [
        {
          id: 266,
          name: "Álvaro Obregón",
          group_id: 9,
        },
        {
          id: 267,
          name: "Azcapotzalco",
          group_id: 9,
        },
        {
          id: 268,
          name: "Benito Juárez",
          group_id: 9,
        },
        {
          id: 269,
          name: "Coyoacán",
          group_id: 9,
        },
        {
          id: 270,
          name: "Cuajimalpa de Morelos",
          group_id: 9,
        },
        {
          id: 271,
          name: "Cuauhtémoc",
          group_id: 9,
        },
        {
          id: 272,
          name: "Gustavo A. Madero",
          group_id: 9,
        },
        {
          id: 273,
          name: "Iztacalco",
          group_id: 9,
        },
        {
          id: 274,
          name: "Iztapalapa",
          group_id: 9,
        },
        {
          id: 275,
          name: "La Magdalena Contreras",
          group_id: 9,
        },
        {
          id: 276,
          name: "Miguel Hidalgo",
          group_id: 9,
        },
        {
          id: 277,
          name: "Milpa Alta",
          group_id: 9,
        },
        {
          id: 278,
          name: "Tláhuac",
          group_id: 9,
        },
        {
          id: 279,
          name: "Tlalpan",
          group_id: 9,
        },
        {
          id: 280,
          name: "Venustiano Carranza",
          group_id: 9,
        },
        {
          id: 281,
          name: "Xochimilco",
          group_id: 9,
        },
      ],
    },
    {
      id: 5,
      name: "Coahuila de Zaragoza",
      var_id: 237,
      show_header: true,
      answer_options: [
        {
          id: 39,
          name: "Abasolo",
          group_id: 5,
        },
        {
          id: 48,
          name: "Acuña",
          group_id: 5,
        },
        {
          id: 53,
          name: "Allende",
          group_id: 5,
        },
        {
          id: 34,
          name: "Arteaga",
          group_id: 5,
        },
        {
          id: 40,
          name: "Candela",
          group_id: 5,
        },
        {
          id: 43,
          name: "Castaños",
          group_id: 5,
        },
        {
          id: 65,
          name: "Cuatro Ciénegas",
          group_id: 5,
        },
        {
          id: 37,
          name: "Escobedo",
          group_id: 5,
        },
        {
          id: 69,
          name: "Francisco I. Madero",
          group_id: 5,
        },
        {
          id: 41,
          name: "Frontera",
          group_id: 5,
        },
        {
          id: 45,
          name: "General Cepeda",
          group_id: 5,
        },
        {
          id: 55,
          name: "Guerrero",
          group_id: 5,
        },
        {
          id: 56,
          name: "Hidalgo",
          group_id: 5,
        },
        {
          id: 50,
          name: "Jiménez",
          group_id: 5,
        },
        {
          id: 35,
          name: "Juárez",
          group_id: 5,
        },
        {
          id: 66,
          name: "Lamadrid",
          group_id: 5,
        },
        {
          id: 60,
          name: "Matamoros",
          group_id: 5,
        },
        {
          id: 42,
          name: "Monclova",
          group_id: 5,
        },
        {
          id: 52,
          name: "Morelos",
          group_id: 5,
        },
        {
          id: 49,
          name: "Múzquiz",
          group_id: 5,
        },
        {
          id: 63,
          name: "Nadadores",
          group_id: 5,
        },
        {
          id: 47,
          name: "Nava",
          group_id: 5,
        },
        {
          id: 62,
          name: "Ocampo",
          group_id: 5,
        },
        {
          id: 70,
          name: "Parras",
          group_id: 5,
        },
        {
          id: 46,
          name: "Piedras Negras",
          group_id: 5,
        },
        {
          id: 36,
          name: "Progreso",
          group_id: 5,
        },
        {
          id: 44,
          name: "Ramos Arizpe",
          group_id: 5,
        },
        {
          id: 57,
          name: "Sabinas",
          group_id: 5,
        },
        {
          id: 67,
          name: "Sacramento",
          group_id: 5,
        },
        {
          id: 33,
          name: "Saltillo",
          group_id: 5,
        },
        {
          id: 38,
          name: "San Buenaventura",
          group_id: 5,
        },
        {
          id: 58,
          name: "San Juan de Sabinas",
          group_id: 5,
        },
        {
          id: 68,
          name: "San Pedro",
          group_id: 5,
        },
        {
          id: 64,
          name: "Sierra Mojada",
          group_id: 5,
        },
        {
          id: 59,
          name: "Torreón",
          group_id: 5,
        },
        {
          id: 61,
          name: "Viesca",
          group_id: 5,
        },
        {
          id: 54,
          name: "Villa Unión",
          group_id: 5,
        },
        {
          id: 51,
          name: "Zaragoza",
          group_id: 5,
        },
      ],
    },
    {
      id: 6,
      name: "Colima",
      var_id: 237,
      show_header: true,
      answer_options: [
        {
          id: 74,
          name: "Armería",
          group_id: 6,
        },
        {
          id: 71,
          name: "Colima",
          group_id: 6,
        },
        {
          id: 76,
          name: "Comala",
          group_id: 6,
        },
        {
          id: 75,
          name: "Coquimatlán",
          group_id: 6,
        },
        {
          id: 77,
          name: "Cuauhtémoc",
          group_id: 6,
        },
        {
          id: 78,
          name: "Ixtlahuacán",
          group_id: 6,
        },
        {
          id: 73,
          name: "Manzanillo",
          group_id: 6,
        },
        {
          id: 79,
          name: "Minatitlán",
          group_id: 6,
        },
        {
          id: 72,
          name: "Tecomán",
          group_id: 6,
        },
        {
          id: 80,
          name: "Villa de Álvarez",
          group_id: 6,
        },
      ],
    },
    {
      id: 10,
      name: "Durango",
      var_id: 237,
      show_header: true,
      answer_options: [
        {
          id: 283,
          name: "Canatlán",
          group_id: 10,
        },
        {
          id: 287,
          name: "Canelas",
          group_id: 10,
        },
        {
          id: 285,
          name: "Coneto de Comonfort",
          group_id: 10,
        },
        {
          id: 317,
          name: "Cuencamé",
          group_id: 10,
        },
        {
          id: 282,
          name: "Durango",
          group_id: 10,
        },
        {
          id: 313,
          name: "El Oro",
          group_id: 10,
        },
        {
          id: 320,
          name: "General Simón Bolívar",
          group_id: 10,
        },
        {
          id: 302,
          name: "Gómez Palacio",
          group_id: 10,
        },
        {
          id: 293,
          name: "Guadalupe Victoria",
          group_id: 10,
        },
        {
          id: 308,
          name: "Guanaceví",
          group_id: 10,
        },
        {
          id: 306,
          name: "Hidalgo",
          group_id: 10,
        },
        {
          id: 310,
          name: "Indé",
          group_id: 10,
        },
        {
          id: 303,
          name: "Lerdo",
          group_id: 10,
        },
        {
          id: 304,
          name: "Mapimí",
          group_id: 10,
        },
        {
          id: 301,
          name: "Mezquital",
          group_id: 10,
        },
        {
          id: 314,
          name: "Nazas",
          group_id: 10,
        },
        {
          id: 297,
          name: "Nombre de Dios",
          group_id: 10,
        },
        {
          id: 284,
          name: "Nuevo Ideal",
          group_id: 10,
        },
        {
          id: 307,
          name: "Ocampo",
          group_id: 10,
        },
        {
          id: 291,
          name: "Otáez",
          group_id: 10,
        },
        {
          id: 295,
          name: "Pánuco de Coronado",
          group_id: 10,
        },
        {
          id: 294,
          name: "Peñón Blanco",
          group_id: 10,
        },
        {
          id: 296,
          name: "Poanas",
          group_id: 10,
        },
        {
          id: 300,
          name: "Pueblo Nuevo",
          group_id: 10,
        },
        {
          id: 316,
          name: "Rodeo",
          group_id: 10,
        },
        {
          id: 309,
          name: "San Bernardo",
          group_id: 10,
        },
        {
          id: 292,
          name: "San Dimas",
          group_id: 10,
        },
        {
          id: 319,
          name: "San Juan de Guadalupe",
          group_id: 10,
        },
        {
          id: 286,
          name: "San Juan del Río",
          group_id: 10,
        },
        {
          id: 315,
          name: "San Luis del Cordero",
          group_id: 10,
        },
        {
          id: 311,
          name: "San Pedro del Gallo",
          group_id: 10,
        },
        {
          id: 318,
          name: "Santa Clara",
          group_id: 10,
        },
        {
          id: 290,
          name: "Santiago Papasquiaro",
          group_id: 10,
        },
        {
          id: 299,
          name: "Súchil",
          group_id: 10,
        },
        {
          id: 289,
          name: "Tamazula",
          group_id: 10,
        },
        {
          id: 312,
          name: "Tepehuanes",
          group_id: 10,
        },
        {
          id: 305,
          name: "Tlahualilo",
          group_id: 10,
        },
        {
          id: 288,
          name: "Topia",
          group_id: 10,
        },
        {
          id: 298,
          name: "Vicente Guerrero",
          group_id: 10,
        },
      ],
    },
    {
      id: 11,
      name: "Guanajuato",
      var_id: 237,
      show_header: true,
      answer_options: [
        {
          id: 332,
          name: "Abasolo",
          group_id: 11,
        },
        {
          id: 359,
          name: "Acámbaro",
          group_id: 11,
        },
        {
          id: 356,
          name: "Apaseo el Alto",
          group_id: 11,
        },
        {
          id: 349,
          name: "Apaseo el Grande",
          group_id: 11,
        },
        {
          id: 343,
          name: "Atarjea",
          group_id: 11,
        },
        {
          id: 348,
          name: "Celaya",
          group_id: 11,
        },
        {
          id: 350,
          name: "Comonfort",
          group_id: 11,
        },
        {
          id: 358,
          name: "Coroneo",
          group_id: 11,
        },
        {
          id: 353,
          name: "Cortazar",
          group_id: 11,
        },
        {
          id: 331,
          name: "Cuerámaro",
          group_id: 11,
        },
        {
          id: 345,
          name: "Doctor Mora",
          group_id: 11,
        },
        {
          id: 338,
          name: "Dolores Hidalgo Cuna de la Independencia Nacional",
          group_id: 11,
        },
        {
          id: 321,
          name: "Guanajuato",
          group_id: 11,
        },
        {
          id: 333,
          name: "Huanímaro",
          group_id: 11,
        },
        {
          id: 327,
          name: "Irapuato",
          group_id: 11,
        },
        {
          id: 355,
          name: "Jaral del Progreso",
          group_id: 11,
        },
        {
          id: 357,
          name: "Jerécuaro",
          group_id: 11,
        },
        {
          id: 334,
          name: "León",
          group_id: 11,
        },
        {
          id: 326,
          name: "Manuel Doblado",
          group_id: 11,
        },
        {
          id: 362,
          name: "Moroleón",
          group_id: 11,
        },
        {
          id: 336,
          name: "Ocampo",
          group_id: 11,
        },
        {
          id: 330,
          name: "Pénjamo",
          group_id: 11,
        },
        {
          id: 329,
          name: "Pueblo Nuevo",
          group_id: 11,
        },
        {
          id: 325,
          name: "Purísima del Rincón",
          group_id: 11,
        },
        {
          id: 323,
          name: "Romita",
          group_id: 11,
        },
        {
          id: 328,
          name: "Salamanca",
          group_id: 11,
        },
        {
          id: 363,
          name: "Salvatierra",
          group_id: 11,
        },
        {
          id: 339,
          name: "San Diego de la Unión",
          group_id: 11,
        },
        {
          id: 335,
          name: "San Felipe",
          group_id: 11,
        },
        {
          id: 324,
          name: "San Francisco del Rincón",
          group_id: 11,
        },
        {
          id: 347,
          name: "San José Iturbide",
          group_id: 11,
        },
        {
          id: 340,
          name: "San Luis de la Paz",
          group_id: 11,
        },
        {
          id: 337,
          name: "San Miguel de Allende",
          group_id: 11,
        },
        {
          id: 344,
          name: "Santa Catarina",
          group_id: 11,
        },
        {
          id: 351,
          name: "Santa Cruz de Juventino Rosas",
          group_id: 11,
        },
        {
          id: 365,
          name: "Santiago Maravatío",
          group_id: 11,
        },
        {
          id: 322,
          name: "Silao de la Victoria",
          group_id: 11,
        },
        {
          id: 361,
          name: "Tarandacuao",
          group_id: 11,
        },
        {
          id: 360,
          name: "Tarimoro",
          group_id: 11,
        },
        {
          id: 346,
          name: "Tierra Blanca",
          group_id: 11,
        },
        {
          id: 366,
          name: "Uriangato",
          group_id: 11,
        },
        {
          id: 354,
          name: "Valle de Santiago",
          group_id: 11,
        },
        {
          id: 341,
          name: "Victoria",
          group_id: 11,
        },
        {
          id: 352,
          name: "Villagrán",
          group_id: 11,
        },
        {
          id: 342,
          name: "Xichú",
          group_id: 11,
        },
        {
          id: 364,
          name: "Yuriria",
          group_id: 11,
        },
      ],
    },
    {
      id: 12,
      name: "Guerrero",
      var_id: 237,
      show_header: true,
      answer_options: [
        {
          id: 375,
          name: "Acapulco de Juárez",
          group_id: 12,
        },
        {
          id: 426,
          name: "Acatepec",
          group_id: 12,
        },
        {
          id: 416,
          name: "Ahuacuotzingo",
          group_id: 12,
        },
        {
          id: 399,
          name: "Ajuchitlán del Progreso",
          group_id: 12,
        },
        {
          id: 436,
          name: "Alcozauca de Guerrero",
          group_id: 12,
        },
        {
          id: 421,
          name: "Alpoyeca",
          group_id: 12,
        },
        {
          id: 391,
          name: "Apaxtla",
          group_id: 12,
        },
        {
          id: 390,
          name: "Arcelia",
          group_id: 12,
        },
        {
          id: 411,
          name: "Atenango del Río",
          group_id: 12,
        },
        {
          id: 432,
          name: "Atlamajalcingo del Monte",
          group_id: 12,
        },
        {
          id: 427,
          name: "Atlixtac",
          group_id: 12,
        },
        {
          id: 407,
          name: "Atoyac de Álvarez",
          group_id: 12,
        },
        {
          id: 371,
          name: "Ayutla de los Libres",
          group_id: 12,
        },
        {
          id: 443,
          name: "Azoyú",
          group_id: 12,
        },
        {
          id: 408,
          name: "Benito Juárez",
          group_id: 12,
        },
        {
          id: 383,
          name: "Buenavista de Cuéllar",
          group_id: 12,
        },
        {
          id: 414,
          name: "Chilapa de Álvarez",
          group_id: 12,
        },
        {
          id: 367,
          name: "Chilpancingo de los Bravo",
          group_id: 12,
        },
        {
          id: 404,
          name: "Coahuayutla de José María Izazaga",
          group_id: 12,
        },
        {
          id: 435,
          name: "Cochoapa el Grande",
          group_id: 12,
        },
        {
          id: 393,
          name: "Cocula",
          group_id: 12,
        },
        {
          id: 442,
          name: "Copala",
          group_id: 12,
        },
        {
          id: 412,
          name: "Copalillo",
          group_id: 12,
        },
        {
          id: 428,
          name: "Copanatoyac",
          group_id: 12,
        },
        {
          id: 409,
          name: "Coyuca de Benítez",
          group_id: 12,
        },
        {
          id: 398,
          name: "Coyuca de Catalán",
          group_id: 12,
        },
        {
          id: 446,
          name: "Cuajinicuilapa",
          group_id: 12,
        },
        {
          id: 413,
          name: "Cualác",
          group_id: 12,
        },
        {
          id: 441,
          name: "Cuautepec",
          group_id: 12,
        },
        {
          id: 392,
          name: "Cuetzala del Progreso",
          group_id: 12,
        },
        {
          id: 395,
          name: "Cutzamala de Pinzón",
          group_id: 12,
        },
        {
          id: 381,
          name: "Eduardo Neri",
          group_id: 12,
        },
        {
          id: 440,
          name: "Florencio Villarreal",
          group_id: 12,
        },
        {
          id: 389,
          name: "General Canuto A. Neri",
          group_id: 12,
        },
        {
          id: 368,
          name: "General Heliodoro Castillo",
          group_id: 12,
        },
        {
          id: 419,
          name: "Huamuxtitlán",
          group_id: 12,
        },
        {
          id: 379,
          name: "Huitzuco de los Figueroa",
          group_id: 12,
        },
        {
          id: 378,
          name: "Iguala de la Independencia",
          group_id: 12,
        },
        {
          id: 447,
          name: "Igualapa",
          group_id: 12,
        },
        {
          id: 430,
          name: "Iliatenco",
          group_id: 12,
        },
        {
          id: 387,
          name: "Ixcateopan de Cuauhtémoc",
          group_id: 12,
        },
        {
          id: 415,
          name: "José Joaquín de Herrera",
          group_id: 12,
        },
        {
          id: 376,
          name: "Juan R. Escudero",
          group_id: 12,
        },
        {
          id: 444,
          name: "Juchitán",
          group_id: 12,
        },
        {
          id: 402,
          name: "La Unión de Isidoro Montes de Oca",
          group_id: 12,
        },
        {
          id: 369,
          name: "Leonardo Bravo",
          group_id: 12,
        },
        {
          id: 429,
          name: "Malinaltepec",
          group_id: 12,
        },
        {
          id: 445,
          name: "Marquelia",
          group_id: 12,
        },
        {
          id: 418,
          name: "Mártir de Cuilapan",
          group_id: 12,
        },
        {
          id: 434,
          name: "Metlatónoc",
          group_id: 12,
        },
        {
          id: 372,
          name: "Mochitlán",
          group_id: 12,
        },
        {
          id: 410,
          name: "Olinalá",
          group_id: 12,
        },
        {
          id: 437,
          name: "Ometepec",
          group_id: 12,
        },
        {
          id: 388,
          name: "Pedro Ascencio Alquisiras",
          group_id: 12,
        },
        {
          id: 403,
          name: "Petatlán",
          group_id: 12,
        },
        {
          id: 385,
          name: "Pilcaya",
          group_id: 12,
        },
        {
          id: 396,
          name: "Pungarabato",
          group_id: 12,
        },
        {
          id: 373,
          name: "Quechultenango",
          group_id: 12,
        },
        {
          id: 433,
          name: "San Luis Acatlán",
          group_id: 12,
        },
        {
          id: 377,
          name: "San Marcos",
          group_id: 12,
        },
        {
          id: 401,
          name: "San Miguel Totolapan",
          group_id: 12,
        },
        {
          id: 382,
          name: "Taxco de Alarcón",
          group_id: 12,
        },
        {
          id: 374,
          name: "Tecoanapa",
          group_id: 12,
        },
        {
          id: 406,
          name: "Técpan de Galeana",
          group_id: 12,
        },
        {
          id: 386,
          name: "Teloloapan",
          group_id: 12,
        },
        {
          id: 380,
          name: "Tepecoacuilco de Trujano",
          group_id: 12,
        },
        {
          id: 384,
          name: "Tetipac",
          group_id: 12,
        },
        {
          id: 370,
          name: "Tixtla de Guerrero",
          group_id: 12,
        },
        {
          id: 438,
          name: "Tlacoachistlahuaca",
          group_id: 12,
        },
        {
          id: 431,
          name: "Tlacoapa",
          group_id: 12,
        },
        {
          id: 397,
          name: "Tlalchapa",
          group_id: 12,
        },
        {
          id: 423,
          name: "Tlalixtaquilla de Maldonado",
          group_id: 12,
        },
        {
          id: 422,
          name: "Tlapa de Comonfort",
          group_id: 12,
        },
        {
          id: 394,
          name: "Tlapehuala",
          group_id: 12,
        },
        {
          id: 424,
          name: "Xalpatláhuac",
          group_id: 12,
        },
        {
          id: 420,
          name: "Xochihuehuetlán",
          group_id: 12,
        },
        {
          id: 439,
          name: "Xochistlahuaca",
          group_id: 12,
        },
        {
          id: 425,
          name: "Zapotitlán Tablas",
          group_id: 12,
        },
        {
          id: 405,
          name: "Zihuatanejo de Azueta",
          group_id: 12,
        },
        {
          id: 400,
          name: "Zirándaro",
          group_id: 12,
        },
        {
          id: 417,
          name: "Zitlala",
          group_id: 12,
        },
      ],
    },
    {
      id: 13,
      name: "Hidalgo",
      var_id: 237,
      show_header: true,
      answer_options: [
        {
          id: 515,
          name: "Acatlán",
          group_id: 13,
        },
        {
          id: 519,
          name: "Acaxochitlán",
          group_id: 13,
        },
        {
          id: 469,
          name: "Actopan",
          group_id: 13,
        },
        {
          id: 512,
          name: "Agua Blanca de Iturbide",
          group_id: 13,
        },
        {
          id: 451,
          name: "Ajacuba",
          group_id: 13,
        },
        {
          id: 465,
          name: "Alfajayucan",
          group_id: 13,
        },
        {
          id: 529,
          name: "Almoloya",
          group_id: 13,
        },
        {
          id: 527,
          name: "Apan",
          group_id: 13,
        },
        {
          id: 485,
          name: "Atitalaquia",
          group_id: 13,
        },
        {
          id: 491,
          name: "Atlapexco",
          group_id: 13,
        },
        {
          id: 486,
          name: "Atotonilco de Tula",
          group_id: 13,
        },
        {
          id: 505,
          name: "Atotonilco el Grande",
          group_id: 13,
        },
        {
          id: 502,
          name: "Calnali",
          group_id: 13,
        },
        {
          id: 463,
          name: "Cardonal",
          group_id: 13,
        },
        {
          id: 481,
          name: "Chapantongo",
          group_id: 13,
        },
        {
          id: 459,
          name: "Chapulhuacán",
          group_id: 13,
        },
        {
          id: 476,
          name: "Chilcuautla",
          group_id: 13,
        },
        {
          id: 520,
          name: "Cuautepec de Hinojosa",
          group_id: 13,
        },
        {
          id: 473,
          name: "El Arenal",
          group_id: 13,
        },
        {
          id: 506,
          name: "Eloxochitlán",
          group_id: 13,
        },
        {
          id: 530,
          name: "Emiliano Zapata",
          group_id: 13,
        },
        {
          id: 517,
          name: "Epazoyucan",
          group_id: 13,
        },
        {
          id: 472,
          name: "Francisco I. Madero",
          group_id: 13,
        },
        {
          id: 514,
          name: "Huasca de Ocampo",
          group_id: 13,
        },
        {
          id: 490,
          name: "Huautla",
          group_id: 13,
        },
        {
          id: 492,
          name: "Huazalingo",
          group_id: 13,
        },
        {
          id: 510,
          name: "Huehuetla",
          group_id: 13,
        },
        {
          id: 487,
          name: "Huejutla de Reyes",
          group_id: 13,
        },
        {
          id: 466,
          name: "Huichapan",
          group_id: 13,
        },
        {
          id: 460,
          name: "Ixmiquilpan",
          group_id: 13,
        },
        {
          id: 455,
          name: "Jacala de Ledezma",
          group_id: 13,
        },
        {
          id: 489,
          name: "Jaltocán",
          group_id: 13,
        },
        {
          id: 500,
          name: "Juárez Hidalgo",
          group_id: 13,
        },
        {
          id: 458,
          name: "La Misión",
          group_id: 13,
        },
        {
          id: 497,
          name: "Lolotla",
          group_id: 13,
        },
        {
          id: 509,
          name: "Metepec",
          group_id: 13,
        },
        {
          id: 507,
          name: "Metztitlán",
          group_id: 13,
        },
        {
          id: 453,
          name: "Mineral de la Reforma",
          group_id: 13,
        },
        {
          id: 449,
          name: "Mineral del Chico",
          group_id: 13,
        },
        {
          id: 450,
          name: "Mineral del Monte",
          group_id: 13,
        },
        {
          id: 474,
          name: "Mixquiahuala de Juárez",
          group_id: 13,
        },
        {
          id: 495,
          name: "Molango de Escamilla",
          group_id: 13,
        },
        {
          id: 462,
          name: "Nicolás Flores",
          group_id: 13,
        },
        {
          id: 468,
          name: "Nopala de Villagrán",
          group_id: 13,
        },
        {
          id: 516,
          name: "Omitlán de Juárez",
          group_id: 13,
        },
        {
          id: 448,
          name: "Pachuca de Soto",
          group_id: 13,
        },
        {
          id: 457,
          name: "Pacula",
          group_id: 13,
        },
        {
          id: 456,
          name: "Pisaflores",
          group_id: 13,
        },
        {
          id: 475,
          name: "Progreso de Obregón",
          group_id: 13,
        },
        {
          id: 508,
          name: "San Agustín Metzquititlán",
          group_id: 13,
        },
        {
          id: 452,
          name: "San Agustín Tlaxiaca",
          group_id: 13,
        },
        {
          id: 511,
          name: "San Bartolo Tutotepec",
          group_id: 13,
        },
        {
          id: 488,
          name: "San Felipe Orizatlán",
          group_id: 13,
        },
        {
          id: 471,
          name: "San Salvador",
          group_id: 13,
        },
        {
          id: 470,
          name: "Santiago de Anaya",
          group_id: 13,
        },
        {
          id: 521,
          name: "Santiago Tulantepec de Lugo Guerrero",
          group_id: 13,
        },
        {
          id: 522,
          name: "Singuilucan",
          group_id: 13,
        },
        {
          id: 464,
          name: "Tasquillo",
          group_id: 13,
        },
        {
          id: 467,
          name: "Tecozautla",
          group_id: 13,
        },
        {
          id: 513,
          name: "Tenango de Doria",
          group_id: 13,
        },
        {
          id: 531,
          name: "Tepeapulco",
          group_id: 13,
        },
        {
          id: 496,
          name: "Tepehuacán de Guerrero",
          group_id: 13,
        },
        {
          id: 480,
          name: "Tepeji del Río de Ocampo",
          group_id: 13,
        },
        {
          id: 482,
          name: "Tepetitlán",
          group_id: 13,
        },
        {
          id: 483,
          name: "Tetepango",
          group_id: 13,
        },
        {
          id: 477,
          name: "Tezontepec de Aldama",
          group_id: 13,
        },
        {
          id: 504,
          name: "Tianguistengo",
          group_id: 13,
        },
        {
          id: 523,
          name: "Tizayuca",
          group_id: 13,
        },
        {
          id: 478,
          name: "Tlahuelilpan",
          group_id: 13,
        },
        {
          id: 499,
          name: "Tlahuiltepa",
          group_id: 13,
        },
        {
          id: 528,
          name: "Tlanalapa",
          group_id: 13,
        },
        {
          id: 498,
          name: "Tlanchinol",
          group_id: 13,
        },
        {
          id: 484,
          name: "Tlaxcoapan",
          group_id: 13,
        },
        {
          id: 525,
          name: "Tolcayuca",
          group_id: 13,
        },
        {
          id: 479,
          name: "Tula de Allende",
          group_id: 13,
        },
        {
          id: 518,
          name: "Tulancingo de Bravo",
          group_id: 13,
        },
        {
          id: 526,
          name: "Villa de Tezontepec",
          group_id: 13,
        },
        {
          id: 494,
          name: "Xochiatipan",
          group_id: 13,
        },
        {
          id: 503,
          name: "Xochicoatlán",
          group_id: 13,
        },
        {
          id: 493,
          name: "Yahualica",
          group_id: 13,
        },
        {
          id: 501,
          name: "Zacualtipán de Ángeles",
          group_id: 13,
        },
        {
          id: 454,
          name: "Zapotlán de Juárez",
          group_id: 13,
        },
        {
          id: 524,
          name: "Zempoala",
          group_id: 13,
        },
        {
          id: 461,
          name: "Zimapán",
          group_id: 13,
        },
      ],
    },
    {
      id: 14,
      name: "Jalisco",
      var_id: 237,
      show_header: true,
      answer_options: [
        {
          id: 541,
          name: "Acatic",
          group_id: 14,
        },
        {
          id: 546,
          name: "Acatlán de Juárez",
          group_id: 14,
        },
        {
          id: 573,
          name: "Ahualulco de Mercado",
          group_id: 14,
        },
        {
          id: 639,
          name: "Amacueca",
          group_id: 14,
        },
        {
          id: 538,
          name: "Amatitán",
          group_id: 14,
        },
        {
          id: 572,
          name: "Ameca",
          group_id: 14,
        },
        {
          id: 584,
          name: "Arandas",
          group_id: 14,
        },
        {
          id: 549,
          name: "Atemajac de Brizuela",
          group_id: 14,
        },
        {
          id: 610,
          name: "Atengo",
          group_id: 14,
        },
        {
          id: 608,
          name: "Atenguillo",
          group_id: 14,
        },
        {
          id: 599,
          name: "Atotonilco el Alto",
          group_id: 14,
        },
        {
          id: 634,
          name: "Atoyac",
          group_id: 14,
        },
        {
          id: 627,
          name: "Autlán de Navarro",
          group_id: 14,
        },
        {
          id: 603,
          name: "Ayotlán",
          group_id: 14,
        },
        {
          id: 607,
          name: "Ayutla",
          group_id: 14,
        },
        {
          id: 559,
          name: "Bolaños",
          group_id: 14,
        },
        {
          id: 613,
          name: "Cabo Corrientes",
          group_id: 14,
        },
        {
          id: 591,
          name: "Cañadas de Obregón",
          group_id: 14,
        },
        {
          id: 628,
          name: "Casimiro Castillo",
          group_id: 14,
        },
        {
          id: 553,
          name: "Chapala",
          group_id: 14,
        },
        {
          id: 564,
          name: "Chimaltitán",
          group_id: 14,
        },
        {
          id: 619,
          name: "Chiquilistlán",
          group_id: 14,
        },
        {
          id: 630,
          name: "Cihuatlán",
          group_id: 14,
        },
        {
          id: 615,
          name: "Cocula",
          group_id: 14,
        },
        {
          id: 561,
          name: "Colotlán",
          group_id: 14,
        },
        {
          id: 633,
          name: "Concepción de Buenos Aires",
          group_id: 14,
        },
        {
          id: 629,
          name: "Cuautitlán de García Barragán",
          group_id: 14,
        },
        {
          id: 609,
          name: "Cuautla",
          group_id: 14,
        },
        {
          id: 542,
          name: "Cuquío",
          group_id: 14,
        },
        {
          id: 605,
          name: "Degollado",
          group_id: 14,
        },
        {
          id: 620,
          name: "Ejutla",
          group_id: 14,
        },
        {
          id: 537,
          name: "El Arenal",
          group_id: 14,
        },
        {
          id: 622,
          name: "El Grullo",
          group_id: 14,
        },
        {
          id: 621,
          name: "El Limón",
          group_id: 14,
        },
        {
          id: 545,
          name: "El Salto",
          group_id: 14,
        },
        {
          id: 588,
          name: "Encarnación de Díaz",
          group_id: 14,
        },
        {
          id: 569,
          name: "Etzatlán",
          group_id: 14,
        },
        {
          id: 632,
          name: "Gómez Farías",
          group_id: 14,
        },
        {
          id: 576,
          name: "Guachinango",
          group_id: 14,
        },
        {
          id: 532,
          name: "Guadalajara",
          group_id: 14,
        },
        {
          id: 567,
          name: "Hostotipaquillo",
          group_id: 14,
        },
        {
          id: 563,
          name: "Huejúcar",
          group_id: 14,
        },
        {
          id: 556,
          name: "Huejuquilla el Alto",
          group_id: 14,
        },
        {
          id: 551,
          name: "Ixtlahuacán de los Membrillos",
          group_id: 14,
        },
        {
          id: 535,
          name: "Ixtlahuacán del Río",
          group_id: 14,
        },
        {
          id: 581,
          name: "Jalostotitlán",
          group_id: 14,
        },
        {
          id: 601,
          name: "Jamay",
          group_id: 14,
        },
        {
          id: 604,
          name: "Jesús María",
          group_id: 14,
        },
        {
          id: 655,
          name: "Jilotlán de los Dolores",
          group_id: 14,
        },
        {
          id: 550,
          name: "Jocotepec",
          group_id: 14,
        },
        {
          id: 552,
          name: "Juanacatlán",
          group_id: 14,
        },
        {
          id: 618,
          name: "Juchitlán",
          group_id: 14,
        },
        {
          id: 602,
          name: "La Barca",
          group_id: 14,
        },
        {
          id: 626,
          name: "La Huerta",
          group_id: 14,
        },
        {
          id: 642,
          name: "La Manzanilla de la Paz",
          group_id: 14,
        },
        {
          id: 593,
          name: "Lagos de Moreno",
          group_id: 14,
        },
        {
          id: 568,
          name: "Magdalena",
          group_id: 14,
        },
        {
          id: 578,
          name: "Mascota",
          group_id: 14,
        },
        {
          id: 643,
          name: "Mazamitla",
          group_id: 14,
        },
        {
          id: 590,
          name: "Mexticacán",
          group_id: 14,
        },
        {
          id: 557,
          name: "Mezquitic",
          group_id: 14,
        },
        {
          id: 577,
          name: "Mixtlán",
          group_id: 14,
        },
        {
          id: 600,
          name: "Ocotlán",
          group_id: 14,
        },
        {
          id: 594,
          name: "Ojuelos de Jalisco",
          group_id: 14,
        },
        {
          id: 653,
          name: "Pihuamo",
          group_id: 14,
        },
        {
          id: 554,
          name: "Poncitlán",
          group_id: 14,
        },
        {
          id: 612,
          name: "Puerto Vallarta",
          group_id: 14,
        },
        {
          id: 645,
          name: "Quitupan",
          group_id: 14,
        },
        {
          id: 534,
          name: "San Cristóbal de la Barranca",
          group_id: 14,
        },
        {
          id: 596,
          name: "San Diego de Alejandría",
          group_id: 14,
        },
        {
          id: 648,
          name: "San Gabriel",
          group_id: 14,
        },
        {
          id: 585,
          name: "San Ignacio Cerro Gordo",
          group_id: 14,
        },
        {
          id: 580,
          name: "San Juan de los Lagos",
          group_id: 14,
        },
        {
          id: 571,
          name: "San Juanito de Escobedo",
          group_id: 14,
        },
        {
          id: 583,
          name: "San Julián",
          group_id: 14,
        },
        {
          id: 570,
          name: "San Marcos",
          group_id: 14,
        },
        {
          id: 565,
          name: "San Martín de Bolaños",
          group_id: 14,
        },
        {
          id: 575,
          name: "San Martín Hidalgo",
          group_id: 14,
        },
        {
          id: 582,
          name: "San Miguel el Alto",
          group_id: 14,
        },
        {
          id: 543,
          name: "San Pedro Tlaquepaque",
          group_id: 14,
        },
        {
          id: 579,
          name: "San Sebastián del Oeste",
          group_id: 14,
        },
        {
          id: 562,
          name: "Santa María de los Ángeles",
          group_id: 14,
        },
        {
          id: 656,
          name: "Santa María del Oro",
          group_id: 14,
        },
        {
          id: 637,
          name: "Sayula",
          group_id: 14,
        },
        {
          id: 536,
          name: "Tala",
          group_id: 14,
        },
        {
          id: 611,
          name: "Talpa de Allende",
          group_id: 14,
        },
        {
          id: 647,
          name: "Tamazula de Gordiano",
          group_id: 14,
        },
        {
          id: 638,
          name: "Tapalpa",
          group_id: 14,
        },
        {
          id: 654,
          name: "Tecalitlán",
          group_id: 14,
        },
        {
          id: 635,
          name: "Techaluta de Montenegro",
          group_id: 14,
        },
        {
          id: 616,
          name: "Tecolotlán",
          group_id: 14,
        },
        {
          id: 617,
          name: "Tenamaxtlán",
          group_id: 14,
        },
        {
          id: 586,
          name: "Teocaltiche",
          group_id: 14,
        },
        {
          id: 636,
          name: "Teocuitatlán de Corona",
          group_id: 14,
        },
        {
          id: 597,
          name: "Tepatitlán de Morelos",
          group_id: 14,
        },
        {
          id: 566,
          name: "Tequila",
          group_id: 14,
        },
        {
          id: 574,
          name: "Teuchitlán",
          group_id: 14,
        },
        {
          id: 640,
          name: "Tizapán el Alto",
          group_id: 14,
        },
        {
          id: 544,
          name: "Tlajomulco de Zúñiga",
          group_id: 14,
        },
        {
          id: 649,
          name: "Tolimán",
          group_id: 14,
        },
        {
          id: 614,
          name: "Tomatlán",
          group_id: 14,
        },
        {
          id: 539,
          name: "Tonalá",
          group_id: 14,
        },
        {
          id: 623,
          name: "Tonaya",
          group_id: 14,
        },
        {
          id: 652,
          name: "Tonila",
          group_id: 14,
        },
        {
          id: 560,
          name: "Totatiche",
          group_id: 14,
        },
        {
          id: 598,
          name: "Tototlán",
          group_id: 14,
        },
        {
          id: 624,
          name: "Tuxcacuesco",
          group_id: 14,
        },
        {
          id: 641,
          name: "Tuxcueca",
          group_id: 14,
        },
        {
          id: 651,
          name: "Tuxpan",
          group_id: 14,
        },
        {
          id: 595,
          name: "Unión de San Antonio",
          group_id: 14,
        },
        {
          id: 606,
          name: "Unión de Tula",
          group_id: 14,
        },
        {
          id: 592,
          name: "Valle de Guadalupe",
          group_id: 14,
        },
        {
          id: 644,
          name: "Valle de Juárez",
          group_id: 14,
        },
        {
          id: 547,
          name: "Villa Corona",
          group_id: 14,
        },
        {
          id: 558,
          name: "Villa Guerrero",
          group_id: 14,
        },
        {
          id: 587,
          name: "Villa Hidalgo",
          group_id: 14,
        },
        {
          id: 625,
          name: "Villa Purificación",
          group_id: 14,
        },
        {
          id: 589,
          name: "Yahualica de González Gallo",
          group_id: 14,
        },
        {
          id: 548,
          name: "Zacoalco de Torres",
          group_id: 14,
        },
        {
          id: 533,
          name: "Zapopan",
          group_id: 14,
        },
        {
          id: 646,
          name: "Zapotiltic",
          group_id: 14,
        },
        {
          id: 650,
          name: "Zapotitlán de Vadillo",
          group_id: 14,
        },
        {
          id: 555,
          name: "Zapotlán del Rey",
          group_id: 14,
        },
        {
          id: 631,
          name: "Zapotlán el Grande",
          group_id: 14,
        },
        {
          id: 540,
          name: "Zapotlanejo",
          group_id: 14,
        },
      ],
    },
    {
      id: 15,
      name: "México",
      var_id: 237,
      show_header: true,
      answer_options: [
        {
          id: 658,
          name: "Acambay de Ruíz Castañeda",
          group_id: 15,
        },
        {
          id: 752,
          name: "Acolman",
          group_id: 15,
        },
        {
          id: 659,
          name: "Aculco",
          group_id: 15,
        },
        {
          id: 693,
          name: "Almoloya de Alquisiras",
          group_id: 15,
        },
        {
          id: 671,
          name: "Almoloya de Juárez",
          group_id: 15,
        },
        {
          id: 713,
          name: "Almoloya del Río",
          group_id: 15,
        },
        {
          id: 680,
          name: "Amanalco",
          group_id: 15,
        },
        {
          id: 686,
          name: "Amatepec",
          group_id: 15,
        },
        {
          id: 778,
          name: "Amecameca",
          group_id: 15,
        },
        {
          id: 743,
          name: "Apaxco",
          group_id: 15,
        },
        {
          id: 763,
          name: "Atenco",
          group_id: 15,
        },
        {
          id: 712,
          name: "Atizapán",
          group_id: 15,
        },
        {
          id: 720,
          name: "Atizapán de Zaragoza",
          group_id: 15,
        },
        {
          id: 661,
          name: "Atlacomulco",
          group_id: 15,
        },
        {
          id: 779,
          name: "Atlautla",
          group_id: 15,
        },
        {
          id: 754,
          name: "Axapusco",
          group_id: 15,
        },
        {
          id: 773,
          name: "Ayapango",
          group_id: 15,
        },
        {
          id: 703,
          name: "Calimaya",
          group_id: 15,
        },
        {
          id: 717,
          name: "Capulhuac",
          group_id: 15,
        },
        {
          id: 768,
          name: "Chalco",
          group_id: 15,
        },
        {
          id: 727,
          name: "Chapa de Mota",
          group_id: 15,
        },
        {
          id: 704,
          name: "Chapultepec",
          group_id: 15,
        },
        {
          id: 758,
          name: "Chiautla",
          group_id: 15,
        },
        {
          id: 765,
          name: "Chicoloapan",
          group_id: 15,
        },
        {
          id: 762,
          name: "Chiconcuac",
          group_id: 15,
        },
        {
          id: 764,
          name: "Chimalhuacán",
          group_id: 15,
        },
        {
          id: 745,
          name: "Coacalco de Berriozábal",
          group_id: 15,
        },
        {
          id: 690,
          name: "Coatepec Harinas",
          group_id: 15,
        },
        {
          id: 771,
          name: "Cocotitlán",
          group_id: 15,
        },
        {
          id: 732,
          name: "Coyotepec",
          group_id: 15,
        },
        {
          id: 736,
          name: "Cuautitlán",
          group_id: 15,
        },
        {
          id: 734,
          name: "Cuautitlán Izcalli",
          group_id: 15,
        },
        {
          id: 674,
          name: "Donato Guerra",
          group_id: 15,
        },
        {
          id: 740,
          name: "Ecatepec de Morelos",
          group_id: 15,
        },
        {
          id: 780,
          name: "Ecatzingo",
          group_id: 15,
        },
        {
          id: 664,
          name: "El Oro",
          group_id: 15,
        },
        {
          id: 733,
          name: "Huehuetoca",
          group_id: 15,
        },
        {
          id: 744,
          name: "Hueypoxtla",
          group_id: 15,
        },
        {
          id: 719,
          name: "Huixquilucan",
          group_id: 15,
        },
        {
          id: 729,
          name: "Isidro Fabela",
          group_id: 15,
        },
        {
          id: 767,
          name: "Ixtapaluca",
          group_id: 15,
        },
        {
          id: 694,
          name: "Ixtapan de la Sal",
          group_id: 15,
        },
        {
          id: 675,
          name: "Ixtapan del Oro",
          group_id: 15,
        },
        {
          id: 668,
          name: "Ixtlahuaca",
          group_id: 15,
        },
        {
          id: 747,
          name: "Jaltenco",
          group_id: 15,
        },
        {
          id: 724,
          name: "Jilotepec",
          group_id: 15,
        },
        {
          id: 730,
          name: "Jilotzingo",
          group_id: 15,
        },
        {
          id: 669,
          name: "Jiquipilco",
          group_id: 15,
        },
        {
          id: 667,
          name: "Jocotitlán",
          group_id: 15,
        },
        {
          id: 708,
          name: "Joquicingo",
          group_id: 15,
        },
        {
          id: 776,
          name: "Juchitepec",
          group_id: 15,
        },
        {
          id: 766,
          name: "La Paz",
          group_id: 15,
        },
        {
          id: 697,
          name: "Lerma",
          group_id: 15,
        },
        {
          id: 684,
          name: "Luvianos",
          group_id: 15,
        },
        {
          id: 710,
          name: "Malinalco",
          group_id: 15,
        },
        {
          id: 737,
          name: "Melchor Ocampo",
          group_id: 15,
        },
        {
          id: 701,
          name: "Metepec",
          group_id: 15,
        },
        {
          id: 702,
          name: "Mexicaltzingo",
          group_id: 15,
        },
        {
          id: 663,
          name: "Morelos",
          group_id: 15,
        },
        {
          id: 721,
          name: "Naucalpan de Juárez",
          group_id: 15,
        },
        {
          id: 749,
          name: "Nextlalpan",
          group_id: 15,
        },
        {
          id: 781,
          name: "Nezahualcóyotl",
          group_id: 15,
        },
        {
          id: 728,
          name: "Nicolás Romero",
          group_id: 15,
        },
        {
          id: 755,
          name: "Nopaltepec",
          group_id: 15,
        },
        {
          id: 718,
          name: "Ocoyoacac",
          group_id: 15,
        },
        {
          id: 711,
          name: "Ocuilan",
          group_id: 15,
        },
        {
          id: 753,
          name: "Otumba",
          group_id: 15,
        },
        {
          id: 677,
          name: "Otzoloapan",
          group_id: 15,
        },
        {
          id: 699,
          name: "Otzolotepec",
          group_id: 15,
        },
        {
          id: 775,
          name: "Ozumba",
          group_id: 15,
        },
        {
          id: 759,
          name: "Papalotla",
          group_id: 15,
        },
        {
          id: 723,
          name: "Polotitlán",
          group_id: 15,
        },
        {
          id: 707,
          name: "Rayón",
          group_id: 15,
        },
        {
          id: 705,
          name: "San Antonio la Isla",
          group_id: 15,
        },
        {
          id: 665,
          name: "San Felipe del Progreso",
          group_id: 15,
        },
        {
          id: 666,
          name: "San José del Rincón",
          group_id: 15,
        },
        {
          id: 751,
          name: "San Martín de las Pirámides",
          group_id: 15,
        },
        {
          id: 700,
          name: "San Mateo Atenco",
          group_id: 15,
        },
        {
          id: 685,
          name: "San Simón de Guerrero",
          group_id: 15,
        },
        {
          id: 676,
          name: "Santo Tomás",
          group_id: 15,
        },
        {
          id: 725,
          name: "Soyaniquilpan de Juárez",
          group_id: 15,
        },
        {
          id: 688,
          name: "Sultepec",
          group_id: 15,
        },
        {
          id: 746,
          name: "Tecámac",
          group_id: 15,
        },
        {
          id: 683,
          name: "Tejupilco",
          group_id: 15,
        },
        {
          id: 770,
          name: "Temamatla",
          group_id: 15,
        },
        {
          id: 756,
          name: "Temascalapa",
          group_id: 15,
        },
        {
          id: 660,
          name: "Temascalcingo",
          group_id: 15,
        },
        {
          id: 681,
          name: "Temascaltepec",
          group_id: 15,
        },
        {
          id: 670,
          name: "Temoaya",
          group_id: 15,
        },
        {
          id: 709,
          name: "Tenancingo",
          group_id: 15,
        },
        {
          id: 774,
          name: "Tenango del Aire",
          group_id: 15,
        },
        {
          id: 706,
          name: "Tenango del Valle",
          group_id: 15,
        },
        {
          id: 735,
          name: "Teoloyucan",
          group_id: 15,
        },
        {
          id: 750,
          name: "Teotihuacán",
          group_id: 15,
        },
        {
          id: 760,
          name: "Tepetlaoxtoc",
          group_id: 15,
        },
        {
          id: 777,
          name: "Tepetlixpa",
          group_id: 15,
        },
        {
          id: 731,
          name: "Tepotzotlán",
          group_id: 15,
        },
        {
          id: 742,
          name: "Tequixquiac",
          group_id: 15,
        },
        {
          id: 689,
          name: "Texcaltitlán",
          group_id: 15,
        },
        {
          id: 714,
          name: "Texcalyacac",
          group_id: 15,
        },
        {
          id: 761,
          name: "Texcoco",
          group_id: 15,
        },
        {
          id: 757,
          name: "Tezoyuca",
          group_id: 15,
        },
        {
          id: 715,
          name: "Tianguistenco",
          group_id: 15,
        },
        {
          id: 662,
          name: "Timilpan",
          group_id: 15,
        },
        {
          id: 772,
          name: "Tlalmanalco",
          group_id: 15,
        },
        {
          id: 722,
          name: "Tlalnepantla de Baz",
          group_id: 15,
        },
        {
          id: 687,
          name: "Tlatlaya",
          group_id: 15,
        },
        {
          id: 657,
          name: "Toluca",
          group_id: 15,
        },
        {
          id: 748,
          name: "Tonanitla",
          group_id: 15,
        },
        {
          id: 695,
          name: "Tonatico",
          group_id: 15,
        },
        {
          id: 739,
          name: "Tultepec",
          group_id: 15,
        },
        {
          id: 738,
          name: "Tultitlán",
          group_id: 15,
        },
        {
          id: 679,
          name: "Valle de Bravo",
          group_id: 15,
        },
        {
          id: 769,
          name: "Valle de Chalco Solidaridad",
          group_id: 15,
        },
        {
          id: 673,
          name: "Villa de Allende",
          group_id: 15,
        },
        {
          id: 726,
          name: "Villa del Carbón",
          group_id: 15,
        },
        {
          id: 691,
          name: "Villa Guerrero",
          group_id: 15,
        },
        {
          id: 672,
          name: "Villa Victoria",
          group_id: 15,
        },
        {
          id: 716,
          name: "Xalatlaco",
          group_id: 15,
        },
        {
          id: 698,
          name: "Xonacatlán",
          group_id: 15,
        },
        {
          id: 678,
          name: "Zacazonapan",
          group_id: 15,
        },
        {
          id: 692,
          name: "Zacualpan",
          group_id: 15,
        },
        {
          id: 682,
          name: "Zinacantepec",
          group_id: 15,
        },
        {
          id: 696,
          name: "Zumpahuacán",
          group_id: 15,
        },
        {
          id: 741,
          name: "Zumpango",
          group_id: 15,
        },
      ],
    },
    {
      id: 16,
      name: "Michoacán de Ocampo",
      var_id: 237,
      show_header: true,
      answer_options: [
        {
          id: 788,
          name: "Acuitzio",
          group_id: 16,
        },
        {
          id: 848,
          name: "Aguililla",
          group_id: 16,
        },
        {
          id: 805,
          name: "Álvaro Obregón",
          group_id: 16,
        },
        {
          id: 792,
          name: "Angamacutiro",
          group_id: 16,
        },
        {
          id: 869,
          name: "Angangueo",
          group_id: 16,
        },
        {
          id: 849,
          name: "Apatzingán",
          group_id: 16,
        },
        {
          id: 868,
          name: "Aporo",
          group_id: 16,
        },
        {
          id: 854,
          name: "Aquila",
          group_id: 16,
        },
        {
          id: 888,
          name: "Ario",
          group_id: 16,
        },
        {
          id: 856,
          name: "Arteaga",
          group_id: 16,
        },
        {
          id: 810,
          name: "Briseñas",
          group_id: 16,
        },
        {
          id: 846,
          name: "Buenavista",
          group_id: 16,
        },
        {
          id: 892,
          name: "Carácuaro",
          group_id: 16,
        },
        {
          id: 837,
          name: "Charapan",
          group_id: 16,
        },
        {
          id: 865,
          name: "Charo",
          group_id: 16,
        },
        {
          id: 826,
          name: "Chavinda",
          group_id: 16,
        },
        {
          id: 839,
          name: "Cherán",
          group_id: 16,
        },
        {
          id: 830,
          name: "Chilchota",
          group_id: 16,
        },
        {
          id: 852,
          name: "Chinicuila",
          group_id: 16,
        },
        {
          id: 801,
          name: "Chucándiro",
          group_id: 16,
        },
        {
          id: 820,
          name: "Churintzio",
          group_id: 16,
        },
        {
          id: 890,
          name: "Churumuco",
          group_id: 16,
        },
        {
          id: 851,
          name: "Coahuayana",
          group_id: 16,
        },
        {
          id: 853,
          name: "Coalcomán de Vázquez Pallares",
          group_id: 16,
        },
        {
          id: 784,
          name: "Coeneo",
          group_id: 16,
        },
        {
          id: 811,
          name: "Cojumatlán de Régules",
          group_id: 16,
        },
        {
          id: 859,
          name: "Contepec",
          group_id: 16,
        },
        {
          id: 802,
          name: "Copándaro",
          group_id: 16,
        },
        {
          id: 833,
          name: "Cotija",
          group_id: 16,
        },
        {
          id: 800,
          name: "Cuitzeo",
          group_id: 16,
        },
        {
          id: 828,
          name: "Ecuandureo",
          group_id: 16,
        },
        {
          id: 858,
          name: "Epitacio Huerta",
          group_id: 16,
        },
        {
          id: 878,
          name: "Erongarícuaro",
          group_id: 16,
        },
        {
          id: 884,
          name: "Gabriel Zamora",
          group_id: 16,
        },
        {
          id: 861,
          name: "Hidalgo",
          group_id: 16,
        },
        {
          id: 799,
          name: "Huandacareo",
          group_id: 16,
        },
        {
          id: 783,
          name: "Huaniqueo",
          group_id: 16,
        },
        {
          id: 893,
          name: "Huetamo",
          group_id: 16,
        },
        {
          id: 879,
          name: "Huiramba",
          group_id: 16,
        },
        {
          id: 807,
          name: "Indaparapeo",
          group_id: 16,
        },
        {
          id: 863,
          name: "Irimbo",
          group_id: 16,
        },
        {
          id: 817,
          name: "Ixtlán",
          group_id: 16,
        },
        {
          id: 831,
          name: "Jacona",
          group_id: 16,
        },
        {
          id: 797,
          name: "Jiménez",
          group_id: 16,
        },
        {
          id: 824,
          name: "Jiquilpan",
          group_id: 16,
        },
        {
          id: 791,
          name: "José Sixto Verduzco",
          group_id: 16,
        },
        {
          id: 875,
          name: "Juárez",
          group_id: 16,
        },
        {
          id: 872,
          name: "Jungapeo",
          group_id: 16,
        },
        {
          id: 889,
          name: "La Huacana",
          group_id: 16,
        },
        {
          id: 818,
          name: "La Piedad",
          group_id: 16,
        },
        {
          id: 787,
          name: "Lagunillas",
          group_id: 16,
        },
        {
          id: 857,
          name: "Lázaro Cárdenas",
          group_id: 16,
        },
        {
          id: 842,
          name: "Los Reyes",
          group_id: 16,
        },
        {
          id: 789,
          name: "Madero",
          group_id: 16,
        },
        {
          id: 862,
          name: "Maravatío",
          group_id: 16,
        },
        {
          id: 823,
          name: "Marcos Castellanos",
          group_id: 16,
        },
        {
          id: 782,
          name: "Morelia",
          group_id: 16,
        },
        {
          id: 798,
          name: "Morelos",
          group_id: 16,
        },
        {
          id: 886,
          name: "Múgica",
          group_id: 16,
        },
        {
          id: 840,
          name: "Nahuatzen",
          group_id: 16,
        },
        {
          id: 891,
          name: "Nocupétaro",
          group_id: 16,
        },
        {
          id: 845,
          name: "Nuevo Parangaricutiro",
          group_id: 16,
        },
        {
          id: 885,
          name: "Nuevo Urecho",
          group_id: 16,
        },
        {
          id: 819,
          name: "Numarán",
          group_id: 16,
        },
        {
          id: 871,
          name: "Ocampo",
          group_id: 16,
        },
        {
          id: 813,
          name: "Pajacuarán",
          group_id: 16,
        },
        {
          id: 793,
          name: "Panindícuaro",
          group_id: 16,
        },
        {
          id: 838,
          name: "Paracho",
          group_id: 16,
        },
        {
          id: 850,
          name: "Parácuaro",
          group_id: 16,
        },
        {
          id: 877,
          name: "Pátzcuaro",
          group_id: 16,
        },
        {
          id: 822,
          name: "Penjamillo",
          group_id: 16,
        },
        {
          id: 843,
          name: "Peribán",
          group_id: 16,
        },
        {
          id: 796,
          name: "Purépero",
          group_id: 16,
        },
        {
          id: 790,
          name: "Puruándiro",
          group_id: 16,
        },
        {
          id: 808,
          name: "Queréndaro",
          group_id: 16,
        },
        {
          id: 785,
          name: "Quiroga",
          group_id: 16,
        },
        {
          id: 809,
          name: "Sahuayo",
          group_id: 16,
        },
        {
          id: 887,
          name: "Salvador Escalante",
          group_id: 16,
        },
        {
          id: 894,
          name: "San Lucas",
          group_id: 16,
        },
        {
          id: 804,
          name: "Santa Ana Maya",
          group_id: 16,
        },
        {
          id: 864,
          name: "Senguio",
          group_id: 16,
        },
        {
          id: 876,
          name: "Susupuato",
          group_id: 16,
        },
        {
          id: 880,
          name: "Tacámbaro",
          group_id: 16,
        },
        {
          id: 844,
          name: "Tancítaro",
          group_id: 16,
        },
        {
          id: 832,
          name: "Tangamandapio",
          group_id: 16,
        },
        {
          id: 829,
          name: "Tangancícuaro",
          group_id: 16,
        },
        {
          id: 815,
          name: "Tanhuato",
          group_id: 16,
        },
        {
          id: 883,
          name: "Taretan",
          group_id: 16,
        },
        {
          id: 803,
          name: "Tarímbaro",
          group_id: 16,
        },
        {
          id: 847,
          name: "Tepalcatepec",
          group_id: 16,
        },
        {
          id: 841,
          name: "Tingambato",
          group_id: 16,
        },
        {
          id: 835,
          name: "Tingüindín",
          group_id: 16,
        },
        {
          id: 867,
          name: "Tiquicheo de Nicolás Romero",
          group_id: 16,
        },
        {
          id: 860,
          name: "Tlalpujahua",
          group_id: 16,
        },
        {
          id: 795,
          name: "Tlazazalca",
          group_id: 16,
        },
        {
          id: 834,
          name: "Tocumbo",
          group_id: 16,
        },
        {
          id: 855,
          name: "Tumbiscatío",
          group_id: 16,
        },
        {
          id: 881,
          name: "Turicato",
          group_id: 16,
        },
        {
          id: 870,
          name: "Tuxpan",
          group_id: 16,
        },
        {
          id: 874,
          name: "Tuzantla",
          group_id: 16,
        },
        {
          id: 786,
          name: "Tzintzuntzan",
          group_id: 16,
        },
        {
          id: 866,
          name: "Tzitzio",
          group_id: 16,
        },
        {
          id: 836,
          name: "Uruapan",
          group_id: 16,
        },
        {
          id: 812,
          name: "Venustiano Carranza",
          group_id: 16,
        },
        {
          id: 825,
          name: "Villamar",
          group_id: 16,
        },
        {
          id: 814,
          name: "Vista Hermosa",
          group_id: 16,
        },
        {
          id: 816,
          name: "Yurécuaro",
          group_id: 16,
        },
        {
          id: 794,
          name: "Zacapu",
          group_id: 16,
        },
        {
          id: 827,
          name: "Zamora",
          group_id: 16,
        },
        {
          id: 821,
          name: "Zináparo",
          group_id: 16,
        },
        {
          id: 806,
          name: "Zinapécuaro",
          group_id: 16,
        },
        {
          id: 882,
          name: "Ziracuaretiro",
          group_id: 16,
        },
        {
          id: 873,
          name: "Zitácuaro",
          group_id: 16,
        },
      ],
    },
    {
      id: 17,
      name: "Morelos",
      var_id: 237,
      show_header: true,
      answer_options: [
        {
          id: 906,
          name: "Amacuzac",
          group_id: 17,
        },
        {
          id: 918,
          name: "Atlatlahucan",
          group_id: 17,
        },
        {
          id: 925,
          name: "Axochiapan",
          group_id: 17,
        },
        {
          id: 908,
          name: "Ayala",
          group_id: 17,
        },
        {
          id: 903,
          name: "Coatlán del Río",
          group_id: 17,
        },
        {
          id: 910,
          name: "Cuautla",
          group_id: 17,
        },
        {
          id: 895,
          name: "Cuernavaca",
          group_id: 17,
        },
        {
          id: 911,
          name: "Emiliano Zapata",
          group_id: 17,
        },
        {
          id: 896,
          name: "Huitzilac",
          group_id: 17,
        },
        {
          id: 926,
          name: "Jantetelco",
          group_id: 17,
        },
        {
          id: 900,
          name: "Jiutepec",
          group_id: 17,
        },
        {
          id: 922,
          name: "Jojutla",
          group_id: 17,
        },
        {
          id: 924,
          name: "Jonacatepec de Leandro Valle",
          group_id: 17,
        },
        {
          id: 905,
          name: "Mazatepec",
          group_id: 17,
        },
        {
          id: 902,
          name: "Miacatlán",
          group_id: 17,
        },
        {
          id: 919,
          name: "Ocuituco",
          group_id: 17,
        },
        {
          id: 907,
          name: "Puente de Ixtla",
          group_id: 17,
        },
        {
          id: 901,
          name: "Temixco",
          group_id: 17,
        },
        {
          id: 920,
          name: "Temoac",
          group_id: 17,
        },
        {
          id: 923,
          name: "Tepalcingo",
          group_id: 17,
        },
        {
          id: 897,
          name: "Tepoztlán",
          group_id: 17,
        },
        {
          id: 904,
          name: "Tetecala",
          group_id: 17,
        },
        {
          id: 915,
          name: "Tetela del Volcán",
          group_id: 17,
        },
        {
          id: 898,
          name: "Tlalnepantla",
          group_id: 17,
        },
        {
          id: 912,
          name: "Tlaltizapán de Zapata",
          group_id: 17,
        },
        {
          id: 927,
          name: "Tlaquiltenango",
          group_id: 17,
        },
        {
          id: 899,
          name: "Tlayacapan",
          group_id: 17,
        },
        {
          id: 917,
          name: "Totolapan",
          group_id: 17,
        },
        {
          id: 914,
          name: "Xochitepec",
          group_id: 17,
        },
        {
          id: 909,
          name: "Yautepec",
          group_id: 17,
        },
        {
          id: 916,
          name: "Yecapixtla",
          group_id: 17,
        },
        {
          id: 913,
          name: "Zacatepec",
          group_id: 17,
        },
        {
          id: 921,
          name: "Zacualpan de Amilpas",
          group_id: 17,
        },
      ],
    },
    {
      id: 18,
      name: "Nayarit",
      var_id: 237,
      show_header: true,
      answer_options: [
        {
          id: 931,
          name: "Acaponeta",
          group_id: 18,
        },
        {
          id: 945,
          name: "Ahuacatlán",
          group_id: 18,
        },
        {
          id: 947,
          name: "Amatlán de Cañas",
          group_id: 18,
        },
        {
          id: 939,
          name: "Bahía de Banderas",
          group_id: 18,
        },
        {
          id: 938,
          name: "Compostela",
          group_id: 18,
        },
        {
          id: 934,
          name: "Del Nayar",
          group_id: 18,
        },
        {
          id: 933,
          name: "Huajicori",
          group_id: 18,
        },
        {
          id: 946,
          name: "Ixtlán del Río",
          group_id: 18,
        },
        {
          id: 944,
          name: "Jala",
          group_id: 18,
        },
        {
          id: 935,
          name: "La Yesca",
          group_id: 18,
        },
        {
          id: 937,
          name: "Rosamorada",
          group_id: 18,
        },
        {
          id: 936,
          name: "Ruíz",
          group_id: 18,
        },
        {
          id: 940,
          name: "San Blas",
          group_id: 18,
        },
        {
          id: 942,
          name: "San Pedro Lagunillas",
          group_id: 18,
        },
        {
          id: 943,
          name: "Santa María del Oro",
          group_id: 18,
        },
        {
          id: 930,
          name: "Santiago Ixcuintla",
          group_id: 18,
        },
        {
          id: 932,
          name: "Tecuala",
          group_id: 18,
        },
        {
          id: 928,
          name: "Tepic",
          group_id: 18,
        },
        {
          id: 929,
          name: "Tuxpan",
          group_id: 18,
        },
        {
          id: 941,
          name: "Xalisco",
          group_id: 18,
        },
      ],
    },
    {
      id: 19,
      name: "Nuevo León",
      var_id: 237,
      show_header: true,
      answer_options: [
        {
          id: 960,
          name: "Abasolo",
          group_id: 19,
        },
        {
          id: 963,
          name: "Agualeguas",
          group_id: 19,
        },
        {
          id: 986,
          name: "Allende",
          group_id: 19,
        },
        {
          id: 949,
          name: "Anáhuac",
          group_id: 19,
        },
        {
          id: 973,
          name: "Apodaca",
          group_id: 19,
        },
        {
          id: 996,
          name: "Aramberri",
          group_id: 19,
        },
        {
          id: 952,
          name: "Bustamante",
          group_id: 19,
        },
        {
          id: 988,
          name: "Cadereyta Jiménez",
          group_id: 19,
        },
        {
          id: 965,
          name: "Cerralvo",
          group_id: 19,
        },
        {
          id: 982,
          name: "China",
          group_id: 19,
        },
        {
          id: 958,
          name: "Ciénega de Flores",
          group_id: 19,
        },
        {
          id: 995,
          name: "Doctor Arroyo",
          group_id: 19,
        },
        {
          id: 980,
          name: "Doctor Coss",
          group_id: 19,
        },
        {
          id: 976,
          name: "Doctor González",
          group_id: 19,
        },
        {
          id: 972,
          name: "El Carmen",
          group_id: 19,
        },
        {
          id: 993,
          name: "Galeana",
          group_id: 19,
        },
        {
          id: 967,
          name: "García",
          group_id: 19,
        },
        {
          id: 981,
          name: "General Bravo",
          group_id: 19,
        },
        {
          id: 968,
          name: "General Escobedo",
          group_id: 19,
        },
        {
          id: 987,
          name: "General Terán",
          group_id: 19,
        },
        {
          id: 964,
          name: "General Treviño",
          group_id: 19,
        },
        {
          id: 997,
          name: "General Zaragoza",
          group_id: 19,
        },
        {
          id: 962,
          name: "General Zuazua",
          group_id: 19,
        },
        {
          id: 983,
          name: "Guadalupe",
          group_id: 19,
        },
        {
          id: 959,
          name: "Hidalgo",
          group_id: 19,
        },
        {
          id: 961,
          name: "Higueras",
          group_id: 19,
        },
        {
          id: 994,
          name: "Hualahuises",
          group_id: 19,
        },
        {
          id: 992,
          name: "Iturbide",
          group_id: 19,
        },
        {
          id: 984,
          name: "Juárez",
          group_id: 19,
        },
        {
          id: 950,
          name: "Lampazos de Naranjo",
          group_id: 19,
        },
        {
          id: 991,
          name: "Linares",
          group_id: 19,
        },
        {
          id: 979,
          name: "Los Aldamas",
          group_id: 19,
        },
        {
          id: 978,
          name: "Los Herreras",
          group_id: 19,
        },
        {
          id: 977,
          name: "Los Ramones",
          group_id: 19,
        },
        {
          id: 975,
          name: "Marín",
          group_id: 19,
        },
        {
          id: 966,
          name: "Melchor Ocampo",
          group_id: 19,
        },
        {
          id: 998,
          name: "Mier y Noriega",
          group_id: 19,
        },
        {
          id: 951,
          name: "Mina",
          group_id: 19,
        },
        {
          id: 989,
          name: "Montemorelos",
          group_id: 19,
        },
        {
          id: 948,
          name: "Monterrey",
          group_id: 19,
        },
        {
          id: 956,
          name: "Parás",
          group_id: 19,
        },
        {
          id: 974,
          name: "Pesquería",
          group_id: 19,
        },
        {
          id: 990,
          name: "Rayones",
          group_id: 19,
        },
        {
          id: 953,
          name: "Sabinas Hidalgo",
          group_id: 19,
        },
        {
          id: 957,
          name: "Salinas Victoria",
          group_id: 19,
        },
        {
          id: 971,
          name: "San Nicolás de los Garza",
          group_id: 19,
        },
        {
          id: 970,
          name: "San Pedro Garza García",
          group_id: 19,
        },
        {
          id: 969,
          name: "Santa Catarina",
          group_id: 19,
        },
        {
          id: 985,
          name: "Santiago",
          group_id: 19,
        },
        {
          id: 955,
          name: "Vallecillo",
          group_id: 19,
        },
        {
          id: 954,
          name: "Villaldama",
          group_id: 19,
        },
      ],
    },
    {
      id: 20,
      name: "Oaxaca",
      var_id: 237,
      show_header: true,
      answer_options: [
        {
          id: 1092,
          name: "Abejones",
          group_id: 20,
        },
        {
          id: 1033,
          name: "Acatlán de Pérez Figueroa",
          group_id: 20,
        },
        {
          id: 1464,
          name: "Ánimas Trujano",
          group_id: 20,
        },
        {
          id: 1317,
          name: "Asunción Cacalotepec",
          group_id: 20,
        },
        {
          id: 1156,
          name: "Asunción Cuyotepeji",
          group_id: 20,
        },
        {
          id: 1295,
          name: "Asunción Ixtaltepec",
          group_id: 20,
        },
        {
          id: 1226,
          name: "Asunción Nochixtlán",
          group_id: 20,
        },
        {
          id: 1508,
          name: "Asunción Ocotlán",
          group_id: 20,
        },
        {
          id: 1359,
          name: "Asunción Tlacolulita",
          group_id: 20,
        },
        {
          id: 1482,
          name: "Ayoquezco de Aldama",
          group_id: 20,
        },
        {
          id: 1035,
          name: "Ayotzintepec",
          group_id: 20,
        },
        {
          id: 1200,
          name: "Calihualá",
          group_id: 20,
        },
        {
          id: 1423,
          name: "Candelaria Loxicha",
          group_id: 20,
        },
        {
          id: 1104,
          name: "Capulálpam de Méndez",
          group_id: 20,
        },
        {
          id: 1303,
          name: "Chahuites",
          group_id: 20,
        },
        {
          id: 1436,
          name: "Chalcatongo de Hidalgo",
          group_id: 20,
        },
        {
          id: 1071,
          name: "Chiquihuitlán de Benito Juárez",
          group_id: 20,
        },
        {
          id: 1471,
          name: "Ciénega de Zimatlán",
          group_id: 20,
        },
        {
          id: 1289,
          name: "Ciudad Ixtepec",
          group_id: 20,
        },
        {
          id: 1526,
          name: "Coatecas Altas",
          group_id: 20,
        },
        {
          id: 1248,
          name: "Coicoyán de las Flores",
          group_id: 20,
        },
        {
          id: 1185,
          name: "Concepción Buenavista",
          group_id: 20,
        },
        {
          id: 1077,
          name: "Concepción Pápalo",
          group_id: 20,
        },
        {
          id: 1430,
          name: "Constancia del Rosario",
          group_id: 20,
        },
        {
          id: 1032,
          name: "Cosolapa",
          group_id: 20,
        },
        {
          id: 1147,
          name: "Cosoltepec",
          group_id: 20,
        },
        {
          id: 1491,
          name: "Cuilápam de Guerrero",
          group_id: 20,
        },
        {
          id: 1069,
          name: "Cuyamecalco Villa de Zaragoza",
          group_id: 20,
        },
        {
          id: 1326,
          name: "El Barrio de la Soledad",
          group_id: 20,
        },
        {
          id: 1290,
          name: "El Espinal",
          group_id: 20,
        },
        {
          id: 1050,
          name: "Eloxochitlán de Flores Magón",
          group_id: 20,
        },
        {
          id: 1170,
          name: "Fresnillo de Trujano",
          group_id: 20,
        },
        {
          id: 1164,
          name: "Guadalupe de Ramírez",
          group_id: 20,
        },
        {
          id: 1017,
          name: "Guadalupe Etla",
          group_id: 20,
        },
        {
          id: 1107,
          name: "Guelatao de Juárez",
          group_id: 20,
        },
        {
          id: 1369,
          name: "Guevea de Humboldt",
          group_id: 20,
        },
        {
          id: 1500,
          name: "Heroica Ciudad de Ejutla de Crespo",
          group_id: 20,
        },
        {
          id: 1145,
          name: "Heroica Ciudad de Huajuapan de León",
          group_id: 20,
        },
        {
          id: 1288,
          name: "Heroica Ciudad de Juchitán de Zaragoza",
          group_id: 20,
        },
        {
          id: 1249,
          name: "Heroica Ciudad de Tlaxiaco",
          group_id: 20,
        },
        {
          id: 1057,
          name: "Huautepec",
          group_id: 20,
        },
        {
          id: 1043,
          name: "Huautla de Jiménez",
          group_id: 20,
        },
        {
          id: 1202,
          name: "Ixpantepec Nieves",
          group_id: 20,
        },
        {
          id: 1094,
          name: "Ixtlán de Juárez",
          group_id: 20,
        },
        {
          id: 1525,
          name: "La Compañía",
          group_id: 20,
        },
        {
          id: 1524,
          name: "La Pe",
          group_id: 20,
        },
        {
          id: 1434,
          name: "La Reforma",
          group_id: 20,
        },
        {
          id: 1206,
          name: "La Trinidad Vista Hermosa",
          group_id: 20,
        },
        {
          id: 1030,
          name: "Loma Bonita",
          group_id: 20,
        },
        {
          id: 1008,
          name: "Magdalena Apasco",
          group_id: 20,
        },
        {
          id: 1272,
          name: "Magdalena Jaltepec",
          group_id: 20,
        },
        {
          id: 1480,
          name: "Magdalena Mixtepec",
          group_id: 20,
        },
        {
          id: 1511,
          name: "Magdalena Ocotlán",
          group_id: 20,
        },
        {
          id: 1261,
          name: "Magdalena Peñasco",
          group_id: 20,
        },
        {
          id: 1341,
          name: "Magdalena Teitipac",
          group_id: 20,
        },
        {
          id: 1372,
          name: "Magdalena Tequisistlán",
          group_id: 20,
        },
        {
          id: 1373,
          name: "Magdalena Tlacotepec",
          group_id: 20,
        },
        {
          id: 1273,
          name: "Magdalena Yodocono de Porfirio Díaz",
          group_id: 20,
        },
        {
          id: 1275,
          name: "Magdalena Zahuatlán",
          group_id: 20,
        },
        {
          id: 1175,
          name: "Mariscala de Juárez",
          group_id: 20,
        },
        {
          id: 1536,
          name: "Mártires de Tacubaya",
          group_id: 20,
        },
        {
          id: 1321,
          name: "Matías Romero Avendaño",
          group_id: 20,
        },
        {
          id: 1064,
          name: "Mazatlán Villa de Flores",
          group_id: 20,
        },
        {
          id: 1431,
          name: "Mesones Hidalgo",
          group_id: 20,
        },
        {
          id: 1383,
          name: "Miahuatlán de Porfirio Díaz",
          group_id: 20,
        },
        {
          id: 1309,
          name: "Mixistlán de la Reforma",
          group_id: 20,
        },
        {
          id: 1392,
          name: "Monjas",
          group_id: 20,
        },
        {
          id: 1088,
          name: "Natividad",
          group_id: 20,
        },
        {
          id: 1012,
          name: "Nazareno Etla",
          group_id: 20,
        },
        {
          id: 1354,
          name: "Nejapa de Madero",
          group_id: 20,
        },
        {
          id: 1105,
          name: "Nuevo Zoquiápam",
          group_id: 20,
        },
        {
          id: 999,
          name: "Oaxaca de Juárez",
          group_id: 20,
        },
        {
          id: 1504,
          name: "Ocotlán de Morelos",
          group_id: 20,
        },
        {
          id: 1550,
          name: "Pinotepa de Don Luis",
          group_id: 20,
        },
        {
          id: 1424,
          name: "Pluma Hidalgo",
          group_id: 20,
        },
        {
          id: 1429,
          name: "Putla Villa de Guerrero",
          group_id: 20,
        },
        {
          id: 1300,
          name: "Reforma de Pineda",
          group_id: 20,
        },
        {
          id: 1011,
          name: "Reyes Etla",
          group_id: 20,
        },
        {
          id: 1338,
          name: "Rojas de Cuauhtémoc",
          group_id: 20,
        },
        {
          id: 1364,
          name: "Salina Cruz",
          group_id: 20,
        },
        {
          id: 1528,
          name: "San Agustín Amatengo",
          group_id: 20,
        },
        {
          id: 1199,
          name: "San Agustín Atenango",
          group_id: 20,
        },
        {
          id: 1552,
          name: "San Agustín Chayuco",
          group_id: 20,
        },
        {
          id: 1462,
          name: "San Agustín de las Juntas",
          group_id: 20,
        },
        {
          id: 1014,
          name: "San Agustín Etla",
          group_id: 20,
        },
        {
          id: 1418,
          name: "San Agustín Loxicha",
          group_id: 20,
        },
        {
          id: 1264,
          name: "San Agustín Tlacotepec",
          group_id: 20,
        },
        {
          id: 1026,
          name: "San Agustín Yatareni",
          group_id: 20,
        },
        {
          id: 1447,
          name: "San Andrés Cabecera Nueva",
          group_id: 20,
        },
        {
          id: 1154,
          name: "San Andrés Dinicuiti",
          group_id: 20,
        },
        {
          id: 1553,
          name: "San Andrés Huaxpaltepec",
          group_id: 20,
        },
        {
          id: 1025,
          name: "San Andrés Huayápam",
          group_id: 20,
        },
        {
          id: 1456,
          name: "San Andrés Ixtlahuaca",
          group_id: 20,
        },
        {
          id: 1215,
          name: "San Andrés Lagunas",
          group_id: 20,
        },
        {
          id: 1281,
          name: "San Andrés Nuxiño",
          group_id: 20,
        },
        {
          id: 1405,
          name: "San Andrés Paxtlán",
          group_id: 20,
        },
        {
          id: 1235,
          name: "San Andrés Sinaxtla",
          group_id: 20,
        },
        {
          id: 1126,
          name: "San Andrés Solaga",
          group_id: 20,
        },
        {
          id: 1075,
          name: "San Andrés Teotilálpam",
          group_id: 20,
        },
        {
          id: 1165,
          name: "San Andrés Tepetlapa",
          group_id: 20,
        },
        {
          id: 1129,
          name: "San Andrés Yaá",
          group_id: 20,
        },
        {
          id: 1518,
          name: "San Andrés Zabache",
          group_id: 20,
        },
        {
          id: 1013,
          name: "San Andrés Zautla",
          group_id: 20,
        },
        {
          id: 1507,
          name: "San Antonino Castillo Velasco",
          group_id: 20,
        },
        {
          id: 1479,
          name: "San Antonino el Alto",
          group_id: 20,
        },
        {
          id: 1214,
          name: "San Antonino Monte Verde",
          group_id: 20,
        },
        {
          id: 1210,
          name: "San Antonio Acutla",
          group_id: 20,
        },
        {
          id: 1461,
          name: "San Antonio de la Cal",
          group_id: 20,
        },
        {
          id: 1472,
          name: "San Antonio Huitepec",
          group_id: 20,
        },
        {
          id: 1060,
          name: "San Antonio Nanahuatípam",
          group_id: 20,
        },
        {
          id: 1266,
          name: "San Antonio Sinicahua",
          group_id: 20,
        },
        {
          id: 1538,
          name: "San Antonio Tepetlapa",
          group_id: 20,
        },
        {
          id: 1514,
          name: "San Baltazar Chichicápam",
          group_id: 20,
        },
        {
          id: 1419,
          name: "San Baltazar Loxicha",
          group_id: 20,
        },
        {
          id: 1131,
          name: "San Baltazar Yatzachi el Bajo",
          group_id: 20,
        },
        {
          id: 1469,
          name: "San Bartolo Coyotepec",
          group_id: 20,
        },
        {
          id: 1218,
          name: "San Bartolo Soyaltepec",
          group_id: 20,
        },
        {
          id: 1357,
          name: "San Bartolo Yautepec",
          group_id: 20,
        },
        {
          id: 1063,
          name: "San Bartolomé Ayautla",
          group_id: 20,
        },
        {
          id: 1421,
          name: "San Bartolomé Loxicha",
          group_id: 20,
        },
        {
          id: 1346,
          name: "San Bartolomé Quialana",
          group_id: 20,
        },
        {
          id: 1262,
          name: "San Bartolomé Yucuañe",
          group_id: 20,
        },
        {
          id: 1130,
          name: "San Bartolomé Zoogocho",
          group_id: 20,
        },
        {
          id: 1452,
          name: "San Bernardo Mixtepec",
          group_id: 20,
        },
        {
          id: 1380,
          name: "San Blas Atempa",
          group_id: 20,
        },
        {
          id: 1352,
          name: "San Carlos Yautepec",
          group_id: 20,
        },
        {
          id: 1396,
          name: "San Cristóbal Amatlán",
          group_id: 20,
        },
        {
          id: 1258,
          name: "San Cristóbal Amoltepec",
          group_id: 20,
        },
        {
          id: 1122,
          name: "San Cristóbal Lachirioag",
          group_id: 20,
        },
        {
          id: 1194,
          name: "San Cristóbal Suchixtlahuaca",
          group_id: 20,
        },
        {
          id: 1294,
          name: "San Dionisio del Mar",
          group_id: 20,
        },
        {
          id: 1351,
          name: "San Dionisio Ocotepec",
          group_id: 20,
        },
        {
          id: 1510,
          name: "San Dionisio Ocotlán",
          group_id: 20,
        },
        {
          id: 1438,
          name: "San Esteban Atatlahuca",
          group_id: 20,
        },
        {
          id: 1038,
          name: "San Felipe Jalapa de Díaz",
          group_id: 20,
        },
        {
          id: 1019,
          name: "San Felipe Tejalápam",
          group_id: 20,
        },
        {
          id: 1042,
          name: "San Felipe Usila",
          group_id: 20,
        },
        {
          id: 1485,
          name: "San Francisco Cahuacuá",
          group_id: 20,
        },
        {
          id: 1134,
          name: "San Francisco Cajonos",
          group_id: 20,
        },
        {
          id: 1076,
          name: "San Francisco Chapulapa",
          group_id: 20,
        },
        {
          id: 1239,
          name: "San Francisco Chindúa",
          group_id: 20,
        },
        {
          id: 1296,
          name: "San Francisco del Mar",
          group_id: 20,
        },
        {
          id: 1047,
          name: "San Francisco Huehuetlán",
          group_id: 20,
        },
        {
          id: 1301,
          name: "San Francisco Ixhuatán",
          group_id: 20,
        },
        {
          id: 1278,
          name: "San Francisco Jaltepetongo",
          group_id: 20,
        },
        {
          id: 1332,
          name: "San Francisco Lachigoló",
          group_id: 20,
        },
        {
          id: 1389,
          name: "San Francisco Logueche",
          group_id: 20,
        },
        {
          id: 1276,
          name: "San Francisco Nuxaño",
          group_id: 20,
        },
        {
          id: 1411,
          name: "San Francisco Ozolotepec",
          group_id: 20,
        },
        {
          id: 1497,
          name: "San Francisco Sola",
          group_id: 20,
        },
        {
          id: 1004,
          name: "San Francisco Telixtlahuaca",
          group_id: 20,
        },
        {
          id: 1188,
          name: "San Francisco Teopan",
          group_id: 20,
        },
        {
          id: 1203,
          name: "San Francisco Tlapancingo",
          group_id: 20,
        },
        {
          id: 1567,
          name: "San Gabriel Mixtepec",
          group_id: 20,
        },
        {
          id: 1393,
          name: "San Ildefonso Amatlán",
          group_id: 20,
        },
        {
          id: 1498,
          name: "San Ildefonso Sola",
          group_id: 20,
        },
        {
          id: 1114,
          name: "San Ildefonso Villa Alta",
          group_id: 20,
        },
        {
          id: 1024,
          name: "San Jacinto Amilpas",
          group_id: 20,
        },
        {
          id: 1465,
          name: "San Jacinto Tlacotepec",
          group_id: 20,
        },
        {
          id: 1400,
          name: "San Jerónimo Coatlán",
          group_id: 20,
        },
        {
          id: 1152,
          name: "San Jerónimo Silacayoapilla",
          group_id: 20,
        },
        {
          id: 1002,
          name: "San Jerónimo Sosola",
          group_id: 20,
        },
        {
          id: 1517,
          name: "San Jerónimo Taviche",
          group_id: 20,
        },
        {
          id: 1055,
          name: "San Jerónimo Tecóatl",
          group_id: 20,
        },
        {
          id: 1342,
          name: "San Jerónimo Tlacochahuaya",
          group_id: 20,
        },
        {
          id: 1179,
          name: "San Jorge Nuchita",
          group_id: 20,
        },
        {
          id: 1172,
          name: "San José Ayuquila",
          group_id: 20,
        },
        {
          id: 1037,
          name: "San José Chiltepec",
          group_id: 20,
        },
        {
          id: 1395,
          name: "San José del Peñasco",
          group_id: 20,
        },
        {
          id: 1519,
          name: "San José del Progreso",
          group_id: 20,
        },
        {
          id: 1543,
          name: "San José Estancia Grande",
          group_id: 20,
        },
        {
          id: 1031,
          name: "San José Independencia",
          group_id: 20,
        },
        {
          id: 1387,
          name: "San José Lachiguiri",
          group_id: 20,
        },
        {
          id: 1061,
          name: "San José Tenango",
          group_id: 20,
        },
        {
          id: 1256,
          name: "San Juan Achiutla",
          group_id: 20,
        },
        {
          id: 1095,
          name: "San Juan Atepec",
          group_id: 20,
        },
        {
          id: 1001,
          name: "San Juan Bautista Atatlahuca",
          group_id: 20,
        },
        {
          id: 1183,
          name: "San Juan Bautista Coixtlahuaca",
          group_id: 20,
        },
        {
          id: 1068,
          name: "San Juan Bautista Cuicatlán",
          group_id: 20,
        },
        {
          id: 1010,
          name: "San Juan Bautista Guelache",
          group_id: 20,
        },
        {
          id: 1003,
          name: "San Juan Bautista Jayacatlán",
          group_id: 20,
        },
        {
          id: 1535,
          name: "San Juan Bautista Lo de Soto",
          group_id: 20,
        },
        {
          id: 1149,
          name: "San Juan Bautista Suchitepec",
          group_id: 20,
        },
        {
          id: 1168,
          name: "San Juan Bautista Tlachichilco",
          group_id: 20,
        },
        {
          id: 1079,
          name: "San Juan Bautista Tlacoatzintepec",
          group_id: 20,
        },
        {
          id: 1029,
          name: "San Juan Bautista Tuxtepec",
          group_id: 20,
        },
        {
          id: 1041,
          name: "San Juan Bautista Valle Nacional",
          group_id: 20,
        },
        {
          id: 1534,
          name: "San Juan Cacahuatepec",
          group_id: 20,
        },
        {
          id: 1103,
          name: "San Juan Chicomezúchil",
          group_id: 20,
        },
        {
          id: 1503,
          name: "San Juan Chilateca",
          group_id: 20,
        },
        {
          id: 1160,
          name: "San Juan Cieneguilla",
          group_id: 20,
        },
        {
          id: 1058,
          name: "San Juan Coatzóspam",
          group_id: 20,
        },
        {
          id: 1547,
          name: "San Juan Colorado",
          group_id: 20,
        },
        {
          id: 1144,
          name: "San Juan Comaltepec",
          group_id: 20,
        },
        {
          id: 1306,
          name: "San Juan Cotzocón",
          group_id: 20,
        },
        {
          id: 1065,
          name: "San Juan de los Cués",
          group_id: 20,
        },
        {
          id: 1007,
          name: "San Juan del Estado",
          group_id: 20,
        },
        {
          id: 1345,
          name: "San Juan del Río",
          group_id: 20,
        },
        {
          id: 1280,
          name: "San Juan Diuxi",
          group_id: 20,
        },
        {
          id: 1100,
          name: "San Juan Evangelista Analco",
          group_id: 20,
        },
        {
          id: 1343,
          name: "San Juan Guelavía",
          group_id: 20,
        },
        {
          id: 1322,
          name: "San Juan Guichicovi",
          group_id: 20,
        },
        {
          id: 1162,
          name: "San Juan Ihualtepec",
          group_id: 20,
        },
        {
          id: 1353,
          name: "San Juan Juquila Mixes",
          group_id: 20,
        },
        {
          id: 1121,
          name: "San Juan Juquila Vijanos",
          group_id: 20,
        },
        {
          id: 1564,
          name: "San Juan Lachao",
          group_id: 20,
        },
        {
          id: 1527,
          name: "San Juan Lachigalla",
          group_id: 20,
        },
        {
          id: 1356,
          name: "San Juan Lajarcia",
          group_id: 20,
        },
        {
          id: 1141,
          name: "San Juan Lalana",
          group_id: 20,
        },
        {
          id: 1307,
          name: "San Juan Mazatlán",
          group_id: 20,
        },
        {
          id: 1246,
          name: "San Juan Mixtepec -Dto. 08 -",
          group_id: 20,
        },
        {
          id: 1397,
          name: "San Juan Mixtepec -Dto. 26 -",
          group_id: 20,
        },
        {
          id: 1250,
          name: "San Juan Ñumí",
          group_id: 20,
        },
        {
          id: 1414,
          name: "San Juan Ozolotepec",
          group_id: 20,
        },
        {
          id: 1143,
          name: "San Juan Petlapa",
          group_id: 20,
        },
        {
          id: 1559,
          name: "San Juan Quiahije",
          group_id: 20,
        },
        {
          id: 1089,
          name: "San Juan Quiotepec",
          group_id: 20,
        },
        {
          id: 1237,
          name: "San Juan Sayultepec",
          group_id: 20,
        },
        {
          id: 1127,
          name: "San Juan Tabaá",
          group_id: 20,
        },
        {
          id: 1282,
          name: "San Juan Tamazola",
          group_id: 20,
        },
        {
          id: 1271,
          name: "San Juan Teita",
          group_id: 20,
        },
        {
          id: 1339,
          name: "San Juan Teitipac",
          group_id: 20,
        },
        {
          id: 1081,
          name: "San Juan Tepeuxila",
          group_id: 20,
        },
        {
          id: 1217,
          name: "San Juan Teposcolula",
          group_id: 20,
        },
        {
          id: 1116,
          name: "San Juan Yaeé",
          group_id: 20,
        },
        {
          id: 1118,
          name: "San Juan Yatzona",
          group_id: 20,
        },
        {
          id: 1236,
          name: "San Juan Yucuita",
          group_id: 20,
        },
        {
          id: 1551,
          name: "San Lorenzo",
          group_id: 20,
        },
        {
          id: 1347,
          name: "San Lorenzo Albarradas",
          group_id: 20,
        },
        {
          id: 1020,
          name: "San Lorenzo Cacaotepec",
          group_id: 20,
        },
        {
          id: 1046,
          name: "San Lorenzo Cuaunecuiltitla",
          group_id: 20,
        },
        {
          id: 1495,
          name: "San Lorenzo Texmelúcan",
          group_id: 20,
        },
        {
          id: 1198,
          name: "San Lorenzo Victoria",
          group_id: 20,
        },
        {
          id: 1319,
          name: "San Lucas Camotlán",
          group_id: 20,
        },
        {
          id: 1040,
          name: "San Lucas Ojitlán",
          group_id: 20,
        },
        {
          id: 1344,
          name: "San Lucas Quiaviní",
          group_id: 20,
        },
        {
          id: 1059,
          name: "San Lucas Zoquiápam",
          group_id: 20,
        },
        {
          id: 1386,
          name: "San Luis Amatlán",
          group_id: 20,
        },
        {
          id: 1413,
          name: "San Marcial Ozolotepec",
          group_id: 20,
        },
        {
          id: 1178,
          name: "San Marcos Arteaga",
          group_id: 20,
        },
        {
          id: 1522,
          name: "San Martín de los Cansecos",
          group_id: 20,
        },
        {
          id: 1252,
          name: "San Martín Huamelúlpam",
          group_id: 20,
        },
        {
          id: 1260,
          name: "San Martín Itunyoso",
          group_id: 20,
        },
        {
          id: 1523,
          name: "San Martín Lachilá",
          group_id: 20,
        },
        {
          id: 1247,
          name: "San Martín Peras",
          group_id: 20,
        },
        {
          id: 1501,
          name: "San Martín Tilcajete",
          group_id: 20,
        },
        {
          id: 1054,
          name: "San Martín Toxpalan",
          group_id: 20,
        },
        {
          id: 1173,
          name: "San Martín Zacatepec",
          group_id: 20,
        },
        {
          id: 1135,
          name: "San Mateo Cajonos",
          group_id: 20,
        },
        {
          id: 1379,
          name: "San Mateo del Mar",
          group_id: 20,
        },
        {
          id: 1240,
          name: "San Mateo Etlatongo",
          group_id: 20,
        },
        {
          id: 1167,
          name: "San Mateo Nejápam",
          group_id: 20,
        },
        {
          id: 1267,
          name: "San Mateo Peñasco",
          group_id: 20,
        },
        {
          id: 1426,
          name: "San Mateo Piñas",
          group_id: 20,
        },
        {
          id: 1403,
          name: "San Mateo Río Hondo",
          group_id: 20,
        },
        {
          id: 1287,
          name: "San Mateo Sindihui",
          group_id: 20,
        },
        {
          id: 1190,
          name: "San Mateo Tlapiltepec",
          group_id: 20,
        },
        {
          id: 1062,
          name: "San Mateo Yoloxochitlán",
          group_id: 20,
        },
        {
          id: 1486,
          name: "San Mateo Yucutindoo",
          group_id: 20,
        },
        {
          id: 1128,
          name: "San Melchor Betaza",
          group_id: 20,
        },
        {
          id: 1259,
          name: "San Miguel Achiutla",
          group_id: 20,
        },
        {
          id: 1166,
          name: "San Miguel Ahuehuetitlán",
          group_id: 20,
        },
        {
          id: 1097,
          name: "San Miguel Aloápam",
          group_id: 20,
        },
        {
          id: 1174,
          name: "San Miguel Amatitlán",
          group_id: 20,
        },
        {
          id: 1111,
          name: "San Miguel Amatlán",
          group_id: 20,
        },
        {
          id: 1228,
          name: "San Miguel Chicahua",
          group_id: 20,
        },
        {
          id: 1298,
          name: "San Miguel Chimalapa",
          group_id: 20,
        },
        {
          id: 1407,
          name: "San Miguel Coatlán",
          group_id: 20,
        },
        {
          id: 1428,
          name: "San Miguel del Puerto",
          group_id: 20,
        },
        {
          id: 1102,
          name: "San Miguel del Río",
          group_id: 20,
        },
        {
          id: 1530,
          name: "San Miguel Ejutla",
          group_id: 20,
        },
        {
          id: 1442,
          name: "San Miguel el Grande",
          group_id: 20,
        },
        {
          id: 1227,
          name: "San Miguel Huautla",
          group_id: 20,
        },
        {
          id: 1454,
          name: "San Miguel Mixtepec",
          group_id: 20,
        },
        {
          id: 1560,
          name: "San Miguel Panixtlahuaca",
          group_id: 20,
        },
        {
          id: 1475,
          name: "San Miguel Peras",
          group_id: 20,
        },
        {
          id: 1286,
          name: "San Miguel Piedras",
          group_id: 20,
        },
        {
          id: 1316,
          name: "San Miguel Quetzaltepec",
          group_id: 20,
        },
        {
          id: 1073,
          name: "San Miguel Santa Flor",
          group_id: 20,
        },
        {
          id: 1034,
          name: "San Miguel Soyaltepec",
          group_id: 20,
        },
        {
          id: 1409,
          name: "San Miguel Suchixtepec",
          group_id: 20,
        },
        {
          id: 1274,
          name: "San Miguel Tecomatlán",
          group_id: 20,
        },
        {
          id: 1382,
          name: "San Miguel Tenango",
          group_id: 20,
        },
        {
          id: 1191,
          name: "San Miguel Tequixtepec",
          group_id: 20,
        },
        {
          id: 1512,
          name: "San Miguel Tilquiápam",
          group_id: 20,
        },
        {
          id: 1541,
          name: "San Miguel Tlacamama",
          group_id: 20,
        },
        {
          id: 1243,
          name: "San Miguel Tlacotepec",
          group_id: 20,
        },
        {
          id: 1192,
          name: "San Miguel Tulancingo",
          group_id: 20,
        },
        {
          id: 1109,
          name: "San Miguel Yotao",
          group_id: 20,
        },
        {
          id: 1384,
          name: "San Nicolás",
          group_id: 20,
        },
        {
          id: 1163,
          name: "San Nicolás Hidalgo",
          group_id: 20,
        },
        {
          id: 1402,
          name: "San Pablo Coatlán",
          group_id: 20,
        },
        {
          id: 1476,
          name: "San Pablo Cuatro Venados",
          group_id: 20,
        },
        {
          id: 1018,
          name: "San Pablo Etla",
          group_id: 20,
        },
        {
          id: 1006,
          name: "San Pablo Huitzo",
          group_id: 20,
        },
        {
          id: 1463,
          name: "San Pablo Huixtepec",
          group_id: 20,
        },
        {
          id: 1093,
          name: "San Pablo Macuiltianguis",
          group_id: 20,
        },
        {
          id: 1444,
          name: "San Pablo Tijaltepec",
          group_id: 20,
        },
        {
          id: 1335,
          name: "San Pablo Villa de Mitla",
          group_id: 20,
        },
        {
          id: 1138,
          name: "San Pablo Yaganiza",
          group_id: 20,
        },
        {
          id: 1433,
          name: "San Pedro Amuzgos",
          group_id: 20,
        },
        {
          id: 1515,
          name: "San Pedro Apóstol",
          group_id: 20,
        },
        {
          id: 1546,
          name: "San Pedro Atoyac",
          group_id: 20,
        },
        {
          id: 1136,
          name: "San Pedro Cajonos",
          group_id: 20,
        },
        {
          id: 1374,
          name: "San Pedro Comitancillo",
          group_id: 20,
        },
        {
          id: 1232,
          name: "San Pedro Coxcaltepec Cántaros",
          group_id: 20,
        },
        {
          id: 1425,
          name: "San Pedro el Alto",
          group_id: 20,
        },
        {
          id: 1377,
          name: "San Pedro Huamelula",
          group_id: 20,
        },
        {
          id: 1378,
          name: "San Pedro Huilotepec",
          group_id: 20,
        },
        {
          id: 1036,
          name: "San Pedro Ixcatlán",
          group_id: 20,
        },
        {
          id: 1460,
          name: "San Pedro Ixtlahuaca",
          group_id: 20,
        },
        {
          id: 1086,
          name: "San Pedro Jaltepetongo",
          group_id: 20,
        },
        {
          id: 1549,
          name: "San Pedro Jicayán",
          group_id: 20,
        },
        {
          id: 1084,
          name: "San Pedro Jocotipac",
          group_id: 20,
        },
        {
          id: 1562,
          name: "San Pedro Juchatengo",
          group_id: 20,
        },
        {
          id: 1509,
          name: "San Pedro Mártir",
          group_id: 20,
        },
        {
          id: 1360,
          name: "San Pedro Mártir Quiechapa",
          group_id: 20,
        },
        {
          id: 1251,
          name: "San Pedro Mártir Yucuxaco",
          group_id: 20,
        },
        {
          id: 1568,
          name: "San Pedro Mixtepec -Dto. 22 -",
          group_id: 20,
        },
        {
          id: 1398,
          name: "San Pedro Mixtepec -Dto. 26 -",
          group_id: 20,
        },
        {
          id: 1269,
          name: "San Pedro Molinos",
          group_id: 20,
        },
        {
          id: 1208,
          name: "San Pedro Nopala",
          group_id: 20,
        },
        {
          id: 1048,
          name: "San Pedro Ocopetatillo",
          group_id: 20,
        },
        {
          id: 1318,
          name: "San Pedro Ocotepec",
          group_id: 20,
        },
        {
          id: 1415,
          name: "San Pedro Pochutla",
          group_id: 20,
        },
        {
          id: 1349,
          name: "San Pedro Quiatoni",
          group_id: 20,
        },
        {
          id: 1082,
          name: "San Pedro Sochiápam",
          group_id: 20,
        },
        {
          id: 1302,
          name: "San Pedro Tapanatepec",
          group_id: 20,
        },
        {
          id: 1521,
          name: "San Pedro Taviche",
          group_id: 20,
        },
        {
          id: 1285,
          name: "San Pedro Teozacoalco",
          group_id: 20,
        },
        {
          id: 1072,
          name: "San Pedro Teutila",
          group_id: 20,
        },
        {
          id: 1277,
          name: "San Pedro Tidaá",
          group_id: 20,
        },
        {
          id: 1224,
          name: "San Pedro Topiltepec",
          group_id: 20,
        },
        {
          id: 1348,
          name: "San Pedro Totolápam",
          group_id: 20,
        },
        {
          id: 1314,
          name: "San Pedro y San Pablo Ayutla",
          group_id: 20,
        },
        {
          id: 1205,
          name: "San Pedro y San Pablo Teposcolula",
          group_id: 20,
        },
        {
          id: 1148,
          name: "San Pedro y San Pablo Tequixtepec",
          group_id: 20,
        },
        {
          id: 1096,
          name: "San Pedro Yaneri",
          group_id: 20,
        },
        {
          id: 1090,
          name: "San Pedro Yólox",
          group_id: 20,
        },
        {
          id: 1216,
          name: "San Pedro Yucunama",
          group_id: 20,
        },
        {
          id: 1466,
          name: "San Raymundo Jalpan",
          group_id: 20,
        },
        {
          id: 1328,
          name: "San Sebastián Abasolo",
          group_id: 20,
        },
        {
          id: 1401,
          name: "San Sebastián Coatlán",
          group_id: 20,
        },
        {
          id: 1537,
          name: "San Sebastián Ixcapa",
          group_id: 20,
        },
        {
          id: 1220,
          name: "San Sebastián Nicananduta",
          group_id: 20,
        },
        {
          id: 1408,
          name: "San Sebastián Río Hondo",
          group_id: 20,
        },
        {
          id: 1244,
          name: "San Sebastián Tecomaxtlahuaca",
          group_id: 20,
        },
        {
          id: 1333,
          name: "San Sebastián Teitipac",
          group_id: 20,
        },
        {
          id: 1474,
          name: "San Sebastián Tutla",
          group_id: 20,
        },
        {
          id: 1385,
          name: "San Simón Almolongas",
          group_id: 20,
        },
        {
          id: 1177,
          name: "San Simón Zahuatlán",
          group_id: 20,
        },
        {
          id: 1532,
          name: "San Vicente Coatlán",
          group_id: 20,
        },
        {
          id: 1494,
          name: "San Vicente Lachixío",
          group_id: 20,
        },
        {
          id: 1223,
          name: "San Vicente Nuñú",
          group_id: 20,
        },
        {
          id: 1390,
          name: "Santa Ana",
          group_id: 20,
        },
        {
          id: 1045,
          name: "Santa Ana Ateixtlahuaca",
          group_id: 20,
        },
        {
          id: 1070,
          name: "Santa Ana Cuauhtémoc",
          group_id: 20,
        },
        {
          id: 1334,
          name: "Santa Ana del Valle",
          group_id: 20,
        },
        {
          id: 1355,
          name: "Santa Ana Tavela",
          group_id: 20,
        },
        {
          id: 1483,
          name: "Santa Ana Tlapacoyan",
          group_id: 20,
        },
        {
          id: 1099,
          name: "Santa Ana Yareni",
          group_id: 20,
        },
        {
          id: 1505,
          name: "Santa Ana Zegache",
          group_id: 20,
        },
        {
          id: 1363,
          name: "Santa Catalina Quierí",
          group_id: 20,
        },
        {
          id: 1394,
          name: "Santa Catarina Cuixtla",
          group_id: 20,
        },
        {
          id: 1108,
          name: "Santa Catarina Ixtepeji",
          group_id: 20,
        },
        {
          id: 1561,
          name: "Santa Catarina Juquila",
          group_id: 20,
        },
        {
          id: 1110,
          name: "Santa Catarina Lachatao",
          group_id: 20,
        },
        {
          id: 1417,
          name: "Santa Catarina Loxicha",
          group_id: 20,
        },
        {
          id: 1554,
          name: "Santa Catarina Mechoacán",
          group_id: 20,
        },
        {
          id: 1513,
          name: "Santa Catarina Minas",
          group_id: 20,
        },
        {
          id: 1481,
          name: "Santa Catarina Quiané",
          group_id: 20,
        },
        {
          id: 1362,
          name: "Santa Catarina Quioquitani",
          group_id: 20,
        },
        {
          id: 1257,
          name: "Santa Catarina Tayata",
          group_id: 20,
        },
        {
          id: 1439,
          name: "Santa Catarina Ticuá",
          group_id: 20,
        },
        {
          id: 1441,
          name: "Santa Catarina Yosonotú",
          group_id: 20,
        },
        {
          id: 1150,
          name: "Santa Catarina Zapoquila",
          group_id: 20,
        },
        {
          id: 1049,
          name: "Santa Cruz Acatepec",
          group_id: 20,
        },
        {
          id: 1457,
          name: "Santa Cruz Amilpas",
          group_id: 20,
        },
        {
          id: 1201,
          name: "Santa Cruz de Bravo",
          group_id: 20,
        },
        {
          id: 1450,
          name: "Santa Cruz Itundujia",
          group_id: 20,
        },
        {
          id: 1453,
          name: "Santa Cruz Mixtepec",
          group_id: 20,
        },
        {
          id: 1263,
          name: "Santa Cruz Nundaco",
          group_id: 20,
        },
        {
          id: 1340,
          name: "Santa Cruz Papalutla",
          group_id: 20,
        },
        {
          id: 1176,
          name: "Santa Cruz Tacache de Mina",
          group_id: 20,
        },
        {
          id: 1445,
          name: "Santa Cruz Tacahua",
          group_id: 20,
        },
        {
          id: 1253,
          name: "Santa Cruz Tayata",
          group_id: 20,
        },
        {
          id: 1391,
          name: "Santa Cruz Xitla",
          group_id: 20,
        },
        {
          id: 1458,
          name: "Santa Cruz Xoxocotlán",
          group_id: 20,
        },
        {
          id: 1484,
          name: "Santa Cruz Zenzontepec",
          group_id: 20,
        },
        {
          id: 1478,
          name: "Santa Gertrudis",
          group_id: 20,
        },
        {
          id: 1241,
          name: "Santa Inés de Zaragoza",
          group_id: 20,
        },
        {
          id: 1477,
          name: "Santa Inés del Monte",
          group_id: 20,
        },
        {
          id: 1470,
          name: "Santa Inés Yatzeche",
          group_id: 20,
        },
        {
          id: 1459,
          name: "Santa Lucía del Camino",
          group_id: 20,
        },
        {
          id: 1399,
          name: "Santa Lucía Miahuatlán",
          group_id: 20,
        },
        {
          id: 1446,
          name: "Santa Lucía Monteverde",
          group_id: 20,
        },
        {
          id: 1516,
          name: "Santa Lucía Ocotlán",
          group_id: 20,
        },
        {
          id: 1189,
          name: "Santa Magdalena Jicotlán",
          group_id: 20,
        },
        {
          id: 1311,
          name: "Santa María Alotepec",
          group_id: 20,
        },
        {
          id: 1229,
          name: "Santa María Apazco",
          group_id: 20,
        },
        {
          id: 1455,
          name: "Santa María Atzompa",
          group_id: 20,
        },
        {
          id: 1157,
          name: "Santa María Camotlán",
          group_id: 20,
        },
        {
          id: 1231,
          name: "Santa María Chachoápam",
          group_id: 20,
        },
        {
          id: 1044,
          name: "Santa María Chilchotla",
          group_id: 20,
        },
        {
          id: 1324,
          name: "Santa María Chimalapa",
          group_id: 20,
        },
        {
          id: 1420,
          name: "Santa María Colotepec",
          group_id: 20,
        },
        {
          id: 1539,
          name: "Santa María Cortijo",
          group_id: 20,
        },
        {
          id: 1468,
          name: "Santa María Coyotepec",
          group_id: 20,
        },
        {
          id: 1255,
          name: "Santa María del Rosario",
          group_id: 20,
        },
        {
          id: 1028,
          name: "Santa María del Tule",
          group_id: 20,
        },
        {
          id: 1358,
          name: "Santa María Ecatepec",
          group_id: 20,
        },
        {
          id: 1330,
          name: "Santa María Guelacé",
          group_id: 20,
        },
        {
          id: 1371,
          name: "Santa María Guienagati",
          group_id: 20,
        },
        {
          id: 1427,
          name: "Santa María Huatulco",
          group_id: 20,
        },
        {
          id: 1556,
          name: "Santa María Huazolotitlán",
          group_id: 20,
        },
        {
          id: 1435,
          name: "Santa María Ipalapa",
          group_id: 20,
        },
        {
          id: 1067,
          name: "Santa María Ixcatlán",
          group_id: 20,
        },
        {
          id: 1039,
          name: "Santa María Jacatepec",
          group_id: 20,
        },
        {
          id: 1366,
          name: "Santa María Jalapa del Marqués",
          group_id: 20,
        },
        {
          id: 1101,
          name: "Santa María Jaltianguis",
          group_id: 20,
        },
        {
          id: 1056,
          name: "Santa María la Asunción",
          group_id: 20,
        },
        {
          id: 1493,
          name: "Santa María Lachixío",
          group_id: 20,
        },
        {
          id: 1375,
          name: "Santa María Mixtequilla",
          group_id: 20,
        },
        {
          id: 1195,
          name: "Santa María Nativitas",
          group_id: 20,
        },
        {
          id: 1222,
          name: "Santa María Nduayaco",
          group_id: 20,
        },
        {
          id: 1406,
          name: "Santa María Ozolotepec",
          group_id: 20,
        },
        {
          id: 1080,
          name: "Santa María Pápalo",
          group_id: 20,
        },
        {
          id: 1021,
          name: "Santa María Peñoles",
          group_id: 20,
        },
        {
          id: 1325,
          name: "Santa María Petapa",
          group_id: 20,
        },
        {
          id: 1361,
          name: "Santa María Quiegolani",
          group_id: 20,
        },
        {
          id: 1496,
          name: "Santa María Sola",
          group_id: 20,
        },
        {
          id: 1268,
          name: "Santa María Tataltepec",
          group_id: 20,
        },
        {
          id: 1066,
          name: "Santa María Tecomavaca",
          group_id: 20,
        },
        {
          id: 1123,
          name: "Santa María Temaxcalapa",
          group_id: 20,
        },
        {
          id: 1565,
          name: "Santa María Temaxcaltepec",
          group_id: 20,
        },
        {
          id: 1053,
          name: "Santa María Teopoxco",
          group_id: 20,
        },
        {
          id: 1315,
          name: "Santa María Tepantlali",
          group_id: 20,
        },
        {
          id: 1085,
          name: "Santa María Texcatitlán",
          group_id: 20,
        },
        {
          id: 1310,
          name: "Santa María Tlahuitoltepec",
          group_id: 20,
        },
        {
          id: 1074,
          name: "Santa María Tlalixtac",
          group_id: 20,
        },
        {
          id: 1422,
          name: "Santa María Tonameca",
          group_id: 20,
        },
        {
          id: 1367,
          name: "Santa María Totolapilla",
          group_id: 20,
        },
        {
          id: 1292,
          name: "Santa María Xadani",
          group_id: 20,
        },
        {
          id: 1125,
          name: "Santa María Yalina",
          group_id: 20,
        },
        {
          id: 1112,
          name: "Santa María Yavesía",
          group_id: 20,
        },
        {
          id: 1448,
          name: "Santa María Yolotepec",
          group_id: 20,
        },
        {
          id: 1270,
          name: "Santa María Yosoyúa",
          group_id: 20,
        },
        {
          id: 1437,
          name: "Santa María Yucuhiti",
          group_id: 20,
        },
        {
          id: 1432,
          name: "Santa María Zacatepec",
          group_id: 20,
        },
        {
          id: 1489,
          name: "Santa María Zaniza",
          group_id: 20,
        },
        {
          id: 1350,
          name: "Santa María Zoquitlán",
          group_id: 20,
        },
        {
          id: 1488,
          name: "Santiago Amoltepec",
          group_id: 20,
        },
        {
          id: 1230,
          name: "Santiago Apoala",
          group_id: 20,
        },
        {
          id: 1506,
          name: "Santiago Apóstol",
          group_id: 20,
        },
        {
          id: 1381,
          name: "Santiago Astata",
          group_id: 20,
        },
        {
          id: 1312,
          name: "Santiago Atitlán",
          group_id: 20,
        },
        {
          id: 1171,
          name: "Santiago Ayuquililla",
          group_id: 20,
        },
        {
          id: 1155,
          name: "Santiago Cacaloxtepec",
          group_id: 20,
        },
        {
          id: 1115,
          name: "Santiago Camotlán",
          group_id: 20,
        },
        {
          id: 1146,
          name: "Santiago Chazumba",
          group_id: 20,
        },
        {
          id: 1139,
          name: "Santiago Choápam",
          group_id: 20,
        },
        {
          id: 1091,
          name: "Santiago Comaltepec",
          group_id: 20,
        },
        {
          id: 1204,
          name: "Santiago del Río",
          group_id: 20,
        },
        {
          id: 1158,
          name: "Santiago Huajolotitlán",
          group_id: 20,
        },
        {
          id: 1233,
          name: "Santiago Huauclilla",
          group_id: 20,
        },
        {
          id: 1186,
          name: "Santiago Ihuitlán Plumas",
          group_id: 20,
        },
        {
          id: 1320,
          name: "Santiago Ixcuintepec",
          group_id: 20,
        },
        {
          id: 1548,
          name: "Santiago Ixtayutla",
          group_id: 20,
        },
        {
          id: 1545,
          name: "Santiago Jamiltepec",
          group_id: 20,
        },
        {
          id: 1140,
          name: "Santiago Jocotepec",
          group_id: 20,
        },
        {
          id: 1242,
          name: "Santiago Juxtlahuaca",
          group_id: 20,
        },
        {
          id: 1365,
          name: "Santiago Lachiguiri",
          group_id: 20,
        },
        {
          id: 1117,
          name: "Santiago Lalopa",
          group_id: 20,
        },
        {
          id: 1368,
          name: "Santiago Laollaga",
          group_id: 20,
        },
        {
          id: 1113,
          name: "Santiago Laxopa",
          group_id: 20,
        },
        {
          id: 1540,
          name: "Santiago Llano Grande",
          group_id: 20,
        },
        {
          id: 1336,
          name: "Santiago Matatlán",
          group_id: 20,
        },
        {
          id: 1151,
          name: "Santiago Miltepec",
          group_id: 20,
        },
        {
          id: 1499,
          name: "Santiago Minas",
          group_id: 20,
        },
        {
          id: 1087,
          name: "Santiago Nacaltepec",
          group_id: 20,
        },
        {
          id: 1225,
          name: "Santiago Nejapilla",
          group_id: 20,
        },
        {
          id: 1293,
          name: "Santiago Niltepec",
          group_id: 20,
        },
        {
          id: 1254,
          name: "Santiago Nundiche",
          group_id: 20,
        },
        {
          id: 1440,
          name: "Santiago Nuyoó",
          group_id: 20,
        },
        {
          id: 1533,
          name: "Santiago Pinotepa Nacional",
          group_id: 20,
        },
        {
          id: 1009,
          name: "Santiago Suchilquitongo",
          group_id: 20,
        },
        {
          id: 1159,
          name: "Santiago Tamazola",
          group_id: 20,
        },
        {
          id: 1542,
          name: "Santiago Tapextla",
          group_id: 20,
        },
        {
          id: 1005,
          name: "Santiago Tenango",
          group_id: 20,
        },
        {
          id: 1193,
          name: "Santiago Tepetlapa",
          group_id: 20,
        },
        {
          id: 1555,
          name: "Santiago Tetepec",
          group_id: 20,
        },
        {
          id: 1051,
          name: "Santiago Texcalcingo",
          group_id: 20,
        },
        {
          id: 1487,
          name: "Santiago Textitlán",
          group_id: 20,
        },
        {
          id: 1279,
          name: "Santiago Tilantongo",
          group_id: 20,
        },
        {
          id: 1238,
          name: "Santiago Tillo",
          group_id: 20,
        },
        {
          id: 1022,
          name: "Santiago Tlazoyaltepec",
          group_id: 20,
        },
        {
          id: 1412,
          name: "Santiago Xanica",
          group_id: 20,
        },
        {
          id: 1106,
          name: "Santiago Xiacuí",
          group_id: 20,
        },
        {
          id: 1563,
          name: "Santiago Yaitepec",
          group_id: 20,
        },
        {
          id: 1142,
          name: "Santiago Yaveo",
          group_id: 20,
        },
        {
          id: 1219,
          name: "Santiago Yolomécatl",
          group_id: 20,
        },
        {
          id: 1449,
          name: "Santiago Yosondúa",
          group_id: 20,
        },
        {
          id: 1197,
          name: "Santiago Yucuyachi",
          group_id: 20,
        },
        {
          id: 1304,
          name: "Santiago Zacatepec",
          group_id: 20,
        },
        {
          id: 1132,
          name: "Santiago Zoochila",
          group_id: 20,
        },
        {
          id: 1337,
          name: "Santo Domingo Albarradas",
          group_id: 20,
        },
        {
          id: 1544,
          name: "Santo Domingo Armenta",
          group_id: 20,
        },
        {
          id: 1370,
          name: "Santo Domingo Chihuitán",
          group_id: 20,
        },
        {
          id: 1416,
          name: "Santo Domingo de Morelos",
          group_id: 20,
        },
        {
          id: 1291,
          name: "Santo Domingo Ingenio",
          group_id: 20,
        },
        {
          id: 1443,
          name: "Santo Domingo Ixcatlán",
          group_id: 20,
        },
        {
          id: 1283,
          name: "Santo Domingo Nuxaá",
          group_id: 20,
        },
        {
          id: 1410,
          name: "Santo Domingo Ozolotepec",
          group_id: 20,
        },
        {
          id: 1323,
          name: "Santo Domingo Petapa",
          group_id: 20,
        },
        {
          id: 1124,
          name: "Santo Domingo Roayaga",
          group_id: 20,
        },
        {
          id: 1376,
          name: "Santo Domingo Tehuantepec",
          group_id: 20,
        },
        {
          id: 1490,
          name: "Santo Domingo Teojomulco",
          group_id: 20,
        },
        {
          id: 1305,
          name: "Santo Domingo Tepuxtepec",
          group_id: 20,
        },
        {
          id: 1221,
          name: "Santo Domingo Tlatayápam",
          group_id: 20,
        },
        {
          id: 1027,
          name: "Santo Domingo Tomaltepec",
          group_id: 20,
        },
        {
          id: 1181,
          name: "Santo Domingo Tonalá",
          group_id: 20,
        },
        {
          id: 1212,
          name: "Santo Domingo Tonaltepec",
          group_id: 20,
        },
        {
          id: 1137,
          name: "Santo Domingo Xagacía",
          group_id: 20,
        },
        {
          id: 1234,
          name: "Santo Domingo Yanhuitlán",
          group_id: 20,
        },
        {
          id: 1182,
          name: "Santo Domingo Yodohino",
          group_id: 20,
        },
        {
          id: 1299,
          name: "Santo Domingo Zanatepec",
          group_id: 20,
        },
        {
          id: 1502,
          name: "Santo Tomás Jalieza",
          group_id: 20,
        },
        {
          id: 1016,
          name: "Santo Tomás Mazaltepec",
          group_id: 20,
        },
        {
          id: 1265,
          name: "Santo Tomás Ocotepec",
          group_id: 20,
        },
        {
          id: 1404,
          name: "Santo Tomás Tamazulapan",
          group_id: 20,
        },
        {
          id: 1566,
          name: "Santos Reyes Nopala",
          group_id: 20,
        },
        {
          id: 1078,
          name: "Santos Reyes Pápalo",
          group_id: 20,
        },
        {
          id: 1245,
          name: "Santos Reyes Tepejillo",
          group_id: 20,
        },
        {
          id: 1180,
          name: "Santos Reyes Yucuná",
          group_id: 20,
        },
        {
          id: 1196,
          name: "Silacayoápam",
          group_id: 20,
        },
        {
          id: 1388,
          name: "Sitio de Xitlapehua",
          group_id: 20,
        },
        {
          id: 1015,
          name: "Soledad Etla",
          group_id: 20,
        },
        {
          id: 1313,
          name: "Tamazulápam del Espíritu Santo",
          group_id: 20,
        },
        {
          id: 1120,
          name: "Tanetze de Zaragoza",
          group_id: 20,
        },
        {
          id: 1529,
          name: "Taniche",
          group_id: 20,
        },
        {
          id: 1558,
          name: "Tataltepec de Valdés",
          group_id: 20,
        },
        {
          id: 1098,
          name: "Teococuilco de Marcos Pérez",
          group_id: 20,
        },
        {
          id: 1052,
          name: "Teotitlán de Flores Magón",
          group_id: 20,
        },
        {
          id: 1331,
          name: "Teotitlán del Valle",
          group_id: 20,
        },
        {
          id: 1209,
          name: "Teotongo",
          group_id: 20,
        },
        {
          id: 1184,
          name: "Tepelmeme Villa de Morelos",
          group_id: 20,
        },
        {
          id: 1169,
          name: "Tezoatlán de Segura y Luna",
          group_id: 20,
        },
        {
          id: 1327,
          name: "Tlacolula de Matamoros",
          group_id: 20,
        },
        {
          id: 1187,
          name: "Tlacotepec Plumas",
          group_id: 20,
        },
        {
          id: 1023,
          name: "Tlalixtac de Cabrera",
          group_id: 20,
        },
        {
          id: 1308,
          name: "Totontepec Villa de Morelos",
          group_id: 20,
        },
        {
          id: 1467,
          name: "Trinidad Zaachila",
          group_id: 20,
        },
        {
          id: 1297,
          name: "Unión Hidalgo",
          group_id: 20,
        },
        {
          id: 1083,
          name: "Valerio Trujano",
          group_id: 20,
        },
        {
          id: 1213,
          name: "Villa de Chilapa de Díaz",
          group_id: 20,
        },
        {
          id: 1000,
          name: "Villa de Etla",
          group_id: 20,
        },
        {
          id: 1207,
          name: "Villa de Tamazulápam del Progreso",
          group_id: 20,
        },
        {
          id: 1557,
          name: "Villa de Tututepec de Melchor Ocampo",
          group_id: 20,
        },
        {
          id: 1473,
          name: "Villa de Zaachila",
          group_id: 20,
        },
        {
          id: 1329,
          name: "Villa Díaz Ordaz",
          group_id: 20,
        },
        {
          id: 1133,
          name: "Villa Hidalgo",
          group_id: 20,
        },
        {
          id: 1492,
          name: "Villa Sola de Vega",
          group_id: 20,
        },
        {
          id: 1119,
          name: "Villa Talea de Castro",
          group_id: 20,
        },
        {
          id: 1211,
          name: "Villa Tejúpam de la Unión",
          group_id: 20,
        },
        {
          id: 1520,
          name: "Yaxe",
          group_id: 20,
        },
        {
          id: 1531,
          name: "Yogana",
          group_id: 20,
        },
        {
          id: 1284,
          name: "Yutanduchi de Guerrero",
          group_id: 20,
        },
        {
          id: 1161,
          name: "Zapotitlán Lagunas",
          group_id: 20,
        },
        {
          id: 1153,
          name: "Zapotitlán Palmas",
          group_id: 20,
        },
        {
          id: 1451,
          name: "Zimatlán de Álvarez",
          group_id: 20,
        },
      ],
    },
    {
      id: 21,
      name: "Puebla",
      var_id: 237,
      show_header: true,
      answer_options: [
        {
          id: 1727,
          name: "Acajete",
          group_id: 21,
        },
        {
          id: 1625,
          name: "Acateno",
          group_id: 21,
        },
        {
          id: 1710,
          name: "Acatlán",
          group_id: 21,
        },
        {
          id: 1731,
          name: "Acatzingo",
          group_id: 21,
        },
        {
          id: 1668,
          name: "Acteopan",
          group_id: 21,
        },
        {
          id: 1599,
          name: "Ahuacatlán",
          group_id: 21,
        },
        {
          id: 1680,
          name: "Ahuatlán",
          group_id: 21,
        },
        {
          id: 1590,
          name: "Ahuazotepec",
          group_id: 21,
        },
        {
          id: 1701,
          name: "Ahuehuetitla",
          group_id: 21,
        },
        {
          id: 1777,
          name: "Ajalpan",
          group_id: 21,
        },
        {
          id: 1694,
          name: "Albino Zertuche",
          group_id: 21,
        },
        {
          id: 1734,
          name: "Aljojuca",
          group_id: 21,
        },
        {
          id: 1781,
          name: "Altepexi",
          group_id: 21,
        },
        {
          id: 1602,
          name: "Amixtlán",
          group_id: 21,
        },
        {
          id: 1578,
          name: "Amozoc",
          group_id: 21,
        },
        {
          id: 1627,
          name: "Aquixtla",
          group_id: 21,
        },
        {
          id: 1641,
          name: "Atempan",
          group_id: 21,
        },
        {
          id: 1771,
          name: "Atexcal",
          group_id: 21,
        },
        {
          id: 1615,
          name: "Atlequizayan",
          group_id: 21,
        },
        {
          id: 1656,
          name: "Atlixco",
          group_id: 21,
        },
        {
          id: 1758,
          name: "Atoyatempan",
          group_id: 21,
        },
        {
          id: 1675,
          name: "Atzala",
          group_id: 21,
        },
        {
          id: 1667,
          name: "Atzitzihuacán",
          group_id: 21,
        },
        {
          id: 1753,
          name: "Atzitzintla",
          group_id: 21,
        },
        {
          id: 1699,
          name: "Axutla",
          group_id: 21,
        },
        {
          id: 1623,
          name: "Ayotoxco de Guerrero",
          group_id: 21,
        },
        {
          id: 1654,
          name: "Calpan",
          group_id: 21,
        },
        {
          id: 1775,
          name: "Caltepec",
          group_id: 21,
        },
        {
          id: 1608,
          name: "Camocuautla",
          group_id: 21,
        },
        {
          id: 1755,
          name: "Cañada Morelos",
          group_id: 21,
        },
        {
          id: 1618,
          name: "Caxhuacan",
          group_id: 21,
        },
        {
          id: 1752,
          name: "Chalchicomula de Sesma",
          group_id: 21,
        },
        {
          id: 1768,
          name: "Chapulco",
          group_id: 21,
        },
        {
          id: 1689,
          name: "Chiautla",
          group_id: 21,
        },
        {
          id: 1651,
          name: "Chiautzingo",
          group_id: 21,
        },
        {
          id: 1725,
          name: "Chichiquila",
          group_id: 21,
        },
        {
          id: 1598,
          name: "Chiconcuautla",
          group_id: 21,
        },
        {
          id: 1674,
          name: "Chietla",
          group_id: 21,
        },
        {
          id: 1683,
          name: "Chigmecatitlán",
          group_id: 21,
        },
        {
          id: 1596,
          name: "Chignahuapan",
          group_id: 21,
        },
        {
          id: 1642,
          name: "Chignautla",
          group_id: 21,
        },
        {
          id: 1715,
          name: "Chila",
          group_id: 21,
        },
        {
          id: 1692,
          name: "Chila de la Sal",
          group_id: 21,
        },
        {
          id: 1723,
          name: "Chilchotla",
          group_id: 21,
        },
        {
          id: 1700,
          name: "Chinantla",
          group_id: 21,
        },
        {
          id: 1607,
          name: "Coatepec",
          group_id: 21,
        },
        {
          id: 1681,
          name: "Coatzingo",
          group_id: 21,
        },
        {
          id: 1690,
          name: "Cohetzala",
          group_id: 21,
        },
        {
          id: 1669,
          name: "Cohuecan",
          group_id: 21,
        },
        {
          id: 1573,
          name: "Coronango",
          group_id: 21,
        },
        {
          id: 1784,
          name: "Coxcatlán",
          group_id: 21,
        },
        {
          id: 1785,
          name: "Coyomeapan",
          group_id: 21,
        },
        {
          id: 1707,
          name: "Coyotepec",
          group_id: 21,
        },
        {
          id: 1747,
          name: "Cuapiaxtla de Madero",
          group_id: 21,
        },
        {
          id: 1626,
          name: "Cuautempan",
          group_id: 21,
        },
        {
          id: 1737,
          name: "Cuautinchán",
          group_id: 21,
        },
        {
          id: 1574,
          name: "Cuautlancingo",
          group_id: 21,
        },
        {
          id: 1697,
          name: "Cuayuca de Andrade",
          group_id: 21,
        },
        {
          id: 1622,
          name: "Cuetzalan del Progreso",
          group_id: 21,
        },
        {
          id: 1644,
          name: "Cuyoaco",
          group_id: 21,
        },
        {
          id: 1653,
          name: "Domingo Arenas",
          group_id: 21,
        },
        {
          id: 1778,
          name: "Eloxochitlán",
          group_id: 21,
        },
        {
          id: 1679,
          name: "Epatlán",
          group_id: 21,
        },
        {
          id: 1754,
          name: "Esperanza",
          group_id: 21,
        },
        {
          id: 1579,
          name: "Francisco Z. Mena",
          group_id: 21,
        },
        {
          id: 1733,
          name: "General Felipe Ángeles",
          group_id: 21,
        },
        {
          id: 1705,
          name: "Guadalupe",
          group_id: 21,
        },
        {
          id: 1720,
          name: "Guadalupe Victoria",
          group_id: 21,
        },
        {
          id: 1605,
          name: "Hermenegildo Galeana",
          group_id: 21,
        },
        {
          id: 1587,
          name: "Honey",
          group_id: 21,
        },
        {
          id: 1663,
          name: "Huaquechula",
          group_id: 21,
        },
        {
          id: 1745,
          name: "Huatlatlauca",
          group_id: 21,
        },
        {
          id: 1589,
          name: "Huauchinango",
          group_id: 21,
        },
        {
          id: 1613,
          name: "Huehuetla",
          group_id: 21,
        },
        {
          id: 1688,
          name: "Huehuetlán el Chico",
          group_id: 21,
        },
        {
          id: 1742,
          name: "Huehuetlán el Grande",
          group_id: 21,
        },
        {
          id: 1652,
          name: "Huejotzingo",
          group_id: 21,
        },
        {
          id: 1639,
          name: "Hueyapan",
          group_id: 21,
        },
        {
          id: 1624,
          name: "Hueytamalco",
          group_id: 21,
        },
        {
          id: 1609,
          name: "Hueytlalpan",
          group_id: 21,
        },
        {
          id: 1611,
          name: "Huitzilan de Serdán",
          group_id: 21,
        },
        {
          id: 1760,
          name: "Huitziltepec",
          group_id: 21,
        },
        {
          id: 1693,
          name: "Ixcamilpa de Guerrero",
          group_id: 21,
        },
        {
          id: 1706,
          name: "Ixcaquixtla",
          group_id: 21,
        },
        {
          id: 1632,
          name: "Ixtacamaxtitlán",
          group_id: 21,
        },
        {
          id: 1614,
          name: "Ixtepec",
          group_id: 21,
        },
        {
          id: 1666,
          name: "Izúcar de Matamoros",
          group_id: 21,
        },
        {
          id: 1582,
          name: "Jalpan",
          group_id: 21,
        },
        {
          id: 1687,
          name: "Jolalpan",
          group_id: 21,
        },
        {
          id: 1619,
          name: "Jonotla",
          group_id: 21,
        },
        {
          id: 1594,
          name: "Jopala",
          group_id: 21,
        },
        {
          id: 1572,
          name: "Juan C. Bonilla",
          group_id: 21,
        },
        {
          id: 1591,
          name: "Juan Galindo",
          group_id: 21,
        },
        {
          id: 1765,
          name: "Juan N. Méndez",
          group_id: 21,
        },
        {
          id: 1743,
          name: "La Magdalena Tlatlauquitepec",
          group_id: 21,
        },
        {
          id: 1722,
          name: "Lafragua",
          group_id: 21,
        },
        {
          id: 1635,
          name: "Libres",
          group_id: 21,
        },
        {
          id: 1746,
          name: "Los Reyes de Juárez",
          group_id: 21,
        },
        {
          id: 1729,
          name: "Mazapiltepec de Juárez",
          group_id: 21,
        },
        {
          id: 1739,
          name: "Mixtla",
          group_id: 21,
        },
        {
          id: 1761,
          name: "Molcaxac",
          group_id: 21,
        },
        {
          id: 1588,
          name: "Naupan",
          group_id: 21,
        },
        {
          id: 1621,
          name: "Nauzontla",
          group_id: 21,
        },
        {
          id: 1657,
          name: "Nealtican",
          group_id: 21,
        },
        {
          id: 1770,
          name: "Nicolás Bravo",
          group_id: 21,
        },
        {
          id: 1728,
          name: "Nopalucan",
          group_id: 21,
        },
        {
          id: 1634,
          name: "Ocotepec",
          group_id: 21,
        },
        {
          id: 1577,
          name: "Ocoyucan",
          group_id: 21,
        },
        {
          id: 1606,
          name: "Olintla",
          group_id: 21,
        },
        {
          id: 1718,
          name: "Oriental",
          group_id: 21,
        },
        {
          id: 1586,
          name: "Pahuatlán",
          group_id: 21,
        },
        {
          id: 1751,
          name: "Palmar de Bravo",
          group_id: 21,
        },
        {
          id: 1580,
          name: "Pantepec",
          group_id: 21,
        },
        {
          id: 1713,
          name: "Petlalcingo",
          group_id: 21,
        },
        {
          id: 1704,
          name: "Piaxtla",
          group_id: 21,
        },
        {
          id: 1569,
          name: "Puebla",
          group_id: 21,
        },
        {
          id: 1749,
          name: "Quecholac",
          group_id: 21,
        },
        {
          id: 1724,
          name: "Quimixtlán",
          group_id: 21,
        },
        {
          id: 1716,
          name: "Rafael Lara Grajales",
          group_id: 21,
        },
        {
          id: 1576,
          name: "San Andrés Cholula",
          group_id: 21,
        },
        {
          id: 1772,
          name: "San Antonio Cañada",
          group_id: 21,
        },
        {
          id: 1664,
          name: "San Diego la Mesa Tochimiltzingo",
          group_id: 21,
        },
        {
          id: 1650,
          name: "San Felipe Teotlalcingo",
          group_id: 21,
        },
        {
          id: 1601,
          name: "San Felipe Tepatlán",
          group_id: 21,
        },
        {
          id: 1774,
          name: "San Gabriel Chilac",
          group_id: 21,
        },
        {
          id: 1659,
          name: "San Gregorio Atzompa",
          group_id: 21,
        },
        {
          id: 1658,
          name: "San Jerónimo Tecuanipan",
          group_id: 21,
        },
        {
          id: 1711,
          name: "San Jerónimo Xayacatlán",
          group_id: 21,
        },
        {
          id: 1717,
          name: "San José Chiapa",
          group_id: 21,
        },
        {
          id: 1783,
          name: "San José Miahuatlán",
          group_id: 21,
        },
        {
          id: 1735,
          name: "San Juan Atenco",
          group_id: 21,
        },
        {
          id: 1744,
          name: "San Juan Atzompa",
          group_id: 21,
        },
        {
          id: 1646,
          name: "San Martín Texmelucan",
          group_id: 21,
        },
        {
          id: 1677,
          name: "San Martín Totoltepec",
          group_id: 21,
        },
        {
          id: 1648,
          name: "San Matías Tlalancaleca",
          group_id: 21,
        },
        {
          id: 1714,
          name: "San Miguel Ixitlán",
          group_id: 21,
        },
        {
          id: 1571,
          name: "San Miguel Xoxtla",
          group_id: 21,
        },
        {
          id: 1719,
          name: "San Nicolás Buenos Aires",
          group_id: 21,
        },
        {
          id: 1655,
          name: "San Nicolás de los Ranchos",
          group_id: 21,
        },
        {
          id: 1702,
          name: "San Pablo Anicano",
          group_id: 21,
        },
        {
          id: 1575,
          name: "San Pedro Cholula",
          group_id: 21,
        },
        {
          id: 1712,
          name: "San Pedro Yeloixtlahuaca",
          group_id: 21,
        },
        {
          id: 1732,
          name: "San Salvador el Seco",
          group_id: 21,
        },
        {
          id: 1649,
          name: "San Salvador el Verde",
          group_id: 21,
        },
        {
          id: 1748,
          name: "San Salvador Huixcolotla",
          group_id: 21,
        },
        {
          id: 1780,
          name: "San Sebastián Tlacotepec",
          group_id: 21,
        },
        {
          id: 1682,
          name: "Santa Catarina Tlaltempan",
          group_id: 21,
        },
        {
          id: 1698,
          name: "Santa Inés Ahuatempan",
          group_id: 21,
        },
        {
          id: 1662,
          name: "Santa Isabel Cholula",
          group_id: 21,
        },
        {
          id: 1769,
          name: "Santiago Miahuatlán",
          group_id: 21,
        },
        {
          id: 1740,
          name: "Santo Tomás Hueyotlipan",
          group_id: 21,
        },
        {
          id: 1730,
          name: "Soltepec",
          group_id: 21,
        },
        {
          id: 1738,
          name: "Tecali de Herrera",
          group_id: 21,
        },
        {
          id: 1750,
          name: "Tecamachalco",
          group_id: 21,
        },
        {
          id: 1703,
          name: "Tecomatlán",
          group_id: 21,
        },
        {
          id: 1766,
          name: "Tehuacán",
          group_id: 21,
        },
        {
          id: 1696,
          name: "Tehuitzingo",
          group_id: 21,
        },
        {
          id: 1616,
          name: "Tenampulco",
          group_id: 21,
        },
        {
          id: 1676,
          name: "Teopantlán",
          group_id: 21,
        },
        {
          id: 1686,
          name: "Teotlalco",
          group_id: 21,
        },
        {
          id: 1767,
          name: "Tepanco de López",
          group_id: 21,
        },
        {
          id: 1603,
          name: "Tepango de Rodríguez",
          group_id: 21,
        },
        {
          id: 1726,
          name: "Tepatlaxco de Hidalgo",
          group_id: 21,
        },
        {
          id: 1736,
          name: "Tepeaca",
          group_id: 21,
        },
        {
          id: 1670,
          name: "Tepemaxalco",
          group_id: 21,
        },
        {
          id: 1665,
          name: "Tepeojuma",
          group_id: 21,
        },
        {
          id: 1600,
          name: "Tepetzintla",
          group_id: 21,
        },
        {
          id: 1672,
          name: "Tepexco",
          group_id: 21,
        },
        {
          id: 1685,
          name: "Tepexi de Rodríguez",
          group_id: 21,
        },
        {
          id: 1645,
          name: "Tepeyahualco",
          group_id: 21,
        },
        {
          id: 1759,
          name: "Tepeyahualco de Cuauhtémoc",
          group_id: 21,
        },
        {
          id: 1628,
          name: "Tetela de Ocampo",
          group_id: 21,
        },
        {
          id: 1640,
          name: "Teteles de Avila Castillo",
          group_id: 21,
        },
        {
          id: 1636,
          name: "Teziutlán",
          group_id: 21,
        },
        {
          id: 1661,
          name: "Tianguismanalco",
          group_id: 21,
        },
        {
          id: 1673,
          name: "Tilapa",
          group_id: 21,
        },
        {
          id: 1721,
          name: "Tlachichuca",
          group_id: 21,
        },
        {
          id: 1764,
          name: "Tlacotepec de Benito Juárez",
          group_id: 21,
        },
        {
          id: 1584,
          name: "Tlacuilotepec",
          group_id: 21,
        },
        {
          id: 1647,
          name: "Tlahuapan",
          group_id: 21,
        },
        {
          id: 1570,
          name: "Tlaltenango",
          group_id: 21,
        },
        {
          id: 1756,
          name: "Tlanepantla",
          group_id: 21,
        },
        {
          id: 1592,
          name: "Tlaola",
          group_id: 21,
        },
        {
          id: 1595,
          name: "Tlapacoya",
          group_id: 21,
        },
        {
          id: 1671,
          name: "Tlapanalá",
          group_id: 21,
        },
        {
          id: 1637,
          name: "Tlatlauquitepec",
          group_id: 21,
        },
        {
          id: 1583,
          name: "Tlaxco",
          group_id: 21,
        },
        {
          id: 1660,
          name: "Tochimilco",
          group_id: 21,
        },
        {
          id: 1757,
          name: "Tochtepec",
          group_id: 21,
        },
        {
          id: 1709,
          name: "Totoltepec de Guerrero",
          group_id: 21,
        },
        {
          id: 1695,
          name: "Tulcingo",
          group_id: 21,
        },
        {
          id: 1617,
          name: "Tuzamapan de Galeana",
          group_id: 21,
        },
        {
          id: 1741,
          name: "Tzicatlacoyan",
          group_id: 21,
        },
        {
          id: 1581,
          name: "Venustiano Carranza",
          group_id: 21,
        },
        {
          id: 1776,
          name: "Vicente Guerrero",
          group_id: 21,
        },
        {
          id: 1708,
          name: "Xayacatlán de Bravo",
          group_id: 21,
        },
        {
          id: 1585,
          name: "Xicotepec",
          group_id: 21,
        },
        {
          id: 1691,
          name: "Xicotlán",
          group_id: 21,
        },
        {
          id: 1643,
          name: "Xiutetelco",
          group_id: 21,
        },
        {
          id: 1629,
          name: "Xochiapulco",
          group_id: 21,
        },
        {
          id: 1678,
          name: "Xochiltepec",
          group_id: 21,
        },
        {
          id: 1612,
          name: "Xochitlán de Vicente Suárez",
          group_id: 21,
        },
        {
          id: 1762,
          name: "Xochitlán Todos Santos",
          group_id: 21,
        },
        {
          id: 1638,
          name: "Yaonáhuac",
          group_id: 21,
        },
        {
          id: 1763,
          name: "Yehualtepec",
          group_id: 21,
        },
        {
          id: 1684,
          name: "Zacapala",
          group_id: 21,
        },
        {
          id: 1630,
          name: "Zacapoaxtla",
          group_id: 21,
        },
        {
          id: 1597,
          name: "Zacatlán",
          group_id: 21,
        },
        {
          id: 1773,
          name: "Zapotitlán",
          group_id: 21,
        },
        {
          id: 1610,
          name: "Zapotitlán de Méndez",
          group_id: 21,
        },
        {
          id: 1631,
          name: "Zaragoza",
          group_id: 21,
        },
        {
          id: 1633,
          name: "Zautla",
          group_id: 21,
        },
        {
          id: 1593,
          name: "Zihuateutla",
          group_id: 21,
        },
        {
          id: 1782,
          name: "Zinacatepec",
          group_id: 21,
        },
        {
          id: 1604,
          name: "Zongozotla",
          group_id: 21,
        },
        {
          id: 1620,
          name: "Zoquiapan",
          group_id: 21,
        },
        {
          id: 1779,
          name: "Zoquitlán",
          group_id: 21,
        },
      ],
    },
    {
      id: 22,
      name: "Querétaro",
      var_id: 237,
      show_header: true,
      answer_options: [
        {
          id: 1801,
          name: "Amealco de Bonfil",
          group_id: 22,
        },
        {
          id: 1792,
          name: "Arroyo Seco",
          group_id: 22,
        },
        {
          id: 1794,
          name: "Cadereyta de Montes",
          group_id: 22,
        },
        {
          id: 1788,
          name: "Colón",
          group_id: 22,
        },
        {
          id: 1802,
          name: "Corregidora",
          group_id: 22,
        },
        {
          id: 1787,
          name: "El Marqués",
          group_id: 22,
        },
        {
          id: 1797,
          name: "Ezequiel Montes",
          group_id: 22,
        },
        {
          id: 1803,
          name: "Huimilpan",
          group_id: 22,
        },
        {
          id: 1790,
          name: "Jalpan de Serra",
          group_id: 22,
        },
        {
          id: 1791,
          name: "Landa de Matamoros",
          group_id: 22,
        },
        {
          id: 1798,
          name: "Pedro Escobedo",
          group_id: 22,
        },
        {
          id: 1793,
          name: "Peñamiller",
          group_id: 22,
        },
        {
          id: 1789,
          name: "Pinal de Amoles",
          group_id: 22,
        },
        {
          id: 1786,
          name: "Querétaro",
          group_id: 22,
        },
        {
          id: 1795,
          name: "San Joaquín",
          group_id: 22,
        },
        {
          id: 1800,
          name: "San Juan del Río",
          group_id: 22,
        },
        {
          id: 1799,
          name: "Tequisquiapan",
          group_id: 22,
        },
        {
          id: 1796,
          name: "Tolimán",
          group_id: 22,
        },
      ],
    },
    {
      id: 23,
      name: "Quintana Roo",
      var_id: 237,
      show_header: true,
      answer_options: [
        {
          id: 1814,
          name: "Bacalar",
          group_id: 23,
        },
        {
          id: 1808,
          name: "Benito Juárez",
          group_id: 23,
        },
        {
          id: 1810,
          name: "Cozumel",
          group_id: 23,
        },
        {
          id: 1805,
          name: "Felipe Carrillo Puerto",
          group_id: 23,
        },
        {
          id: 1807,
          name: "Isla Mujeres",
          group_id: 23,
        },
        {
          id: 1813,
          name: "José María Morelos",
          group_id: 23,
        },
        {
          id: 1806,
          name: "Lázaro Cárdenas",
          group_id: 23,
        },
        {
          id: 1804,
          name: "Othón P. Blanco",
          group_id: 23,
        },
        {
          id: 1809,
          name: "Puerto Morelos",
          group_id: 23,
        },
        {
          id: 1811,
          name: "Solidaridad",
          group_id: 23,
        },
        {
          id: 1812,
          name: "Tulum",
          group_id: 23,
        },
      ],
    },
    {
      id: 24,
      name: "San Luis Potosí",
      var_id: 237,
      show_header: true,
      answer_options: [
        {
          id: 1818,
          name: "Ahualulco",
          group_id: 24,
        },
        {
          id: 1842,
          name: "Alaquines",
          group_id: 24,
        },
        {
          id: 1856,
          name: "Aquismón",
          group_id: 24,
        },
        {
          id: 1836,
          name: "Armadillo de los Infante",
          group_id: 24,
        },
        {
          id: 1868,
          name: "Axtla de Terrazas",
          group_id: 24,
        },
        {
          id: 1843,
          name: "Cárdenas",
          group_id: 24,
        },
        {
          id: 1823,
          name: "Catorce",
          group_id: 24,
        },
        {
          id: 1822,
          name: "Cedral",
          group_id: 24,
        },
        {
          id: 1844,
          name: "Cerritos",
          group_id: 24,
        },
        {
          id: 1817,
          name: "Cerro de San Pedro",
          group_id: 24,
        },
        {
          id: 1824,
          name: "Charcas",
          group_id: 24,
        },
        {
          id: 1841,
          name: "Ciudad del Maíz",
          group_id: 24,
        },
        {
          id: 1852,
          name: "Ciudad Fernández",
          group_id: 24,
        },
        {
          id: 1837,
          name: "Ciudad Valles",
          group_id: 24,
        },
        {
          id: 1865,
          name: "Coxcatlán",
          group_id: 24,
        },
        {
          id: 1838,
          name: "Ebano",
          group_id: 24,
        },
        {
          id: 1840,
          name: "El Naranjo",
          group_id: 24,
        },
        {
          id: 1831,
          name: "Guadalcázar",
          group_id: 24,
        },
        {
          id: 1866,
          name: "Huehuetlán",
          group_id: 24,
        },
        {
          id: 1857,
          name: "Lagunillas",
          group_id: 24,
        },
        {
          id: 1828,
          name: "Matehuala",
          group_id: 24,
        },
        {
          id: 1872,
          name: "Matlapa",
          group_id: 24,
        },
        {
          id: 1819,
          name: "Mexquitic de Carmona",
          group_id: 24,
        },
        {
          id: 1832,
          name: "Moctezuma",
          group_id: 24,
        },
        {
          id: 1855,
          name: "Rayón",
          group_id: 24,
        },
        {
          id: 1851,
          name: "Rioverde",
          group_id: 24,
        },
        {
          id: 1825,
          name: "Salinas",
          group_id: 24,
        },
        {
          id: 1862,
          name: "San Antonio",
          group_id: 24,
        },
        {
          id: 1853,
          name: "San Ciro de Acosta",
          group_id: 24,
        },
        {
          id: 1815,
          name: "San Luis Potosí",
          group_id: 24,
        },
        {
          id: 1870,
          name: "San Martín Chalchicuautla",
          group_id: 24,
        },
        {
          id: 1846,
          name: "San Nicolás Tolentino",
          group_id: 24,
        },
        {
          id: 1861,
          name: "San Vicente Tancuayalab",
          group_id: 24,
        },
        {
          id: 1858,
          name: "Santa Catarina",
          group_id: 24,
        },
        {
          id: 1849,
          name: "Santa María del Río",
          group_id: 24,
        },
        {
          id: 1826,
          name: "Santo Domingo",
          group_id: 24,
        },
        {
          id: 1816,
          name: "Soledad de Graciano Sánchez",
          group_id: 24,
        },
        {
          id: 1854,
          name: "Tamasopo",
          group_id: 24,
        },
        {
          id: 1871,
          name: "Tamazunchale",
          group_id: 24,
        },
        {
          id: 1869,
          name: "Tampacán",
          group_id: 24,
        },
        {
          id: 1864,
          name: "Tampamolón Corona",
          group_id: 24,
        },
        {
          id: 1839,
          name: "Tamuín",
          group_id: 24,
        },
        {
          id: 1859,
          name: "Tancanhuitz",
          group_id: 24,
        },
        {
          id: 1860,
          name: "Tanlajás",
          group_id: 24,
        },
        {
          id: 1863,
          name: "Tanquián de Escobedo",
          group_id: 24,
        },
        {
          id: 1850,
          name: "Tierra Nueva",
          group_id: 24,
        },
        {
          id: 1821,
          name: "Vanegas",
          group_id: 24,
        },
        {
          id: 1833,
          name: "Venado",
          group_id: 24,
        },
        {
          id: 1834,
          name: "Villa de Arista",
          group_id: 24,
        },
        {
          id: 1820,
          name: "Villa de Arriaga",
          group_id: 24,
        },
        {
          id: 1830,
          name: "Villa de Guadalupe",
          group_id: 24,
        },
        {
          id: 1829,
          name: "Villa de la Paz",
          group_id: 24,
        },
        {
          id: 1827,
          name: "Villa de Ramos",
          group_id: 24,
        },
        {
          id: 1847,
          name: "Villa de Reyes",
          group_id: 24,
        },
        {
          id: 1835,
          name: "Villa Hidalgo",
          group_id: 24,
        },
        {
          id: 1845,
          name: "Villa Juárez",
          group_id: 24,
        },
        {
          id: 1867,
          name: "Xilitla",
          group_id: 24,
        },
        {
          id: 1848,
          name: "Zaragoza",
          group_id: 24,
        },
      ],
    },
    {
      id: 25,
      name: "Sinaloa",
      var_id: 237,
      show_header: true,
      answer_options: [
        {
          id: 1879,
          name: "Ahome",
          group_id: 25,
        },
        {
          id: 1881,
          name: "Angostura",
          group_id: 25,
        },
        {
          id: 1875,
          name: "Badiraguato",
          group_id: 25,
        },
        {
          id: 1882,
          name: "Choix",
          group_id: 25,
        },
        {
          id: 1887,
          name: "Concordia",
          group_id: 25,
        },
        {
          id: 1876,
          name: "Cosalá",
          group_id: 25,
        },
        {
          id: 1873,
          name: "Culiacán",
          group_id: 25,
        },
        {
          id: 1883,
          name: "El Fuerte",
          group_id: 25,
        },
        {
          id: 1888,
          name: "Elota",
          group_id: 25,
        },
        {
          id: 1886,
          name: "Escuinapa",
          group_id: 25,
        },
        {
          id: 1878,
          name: "Guasave",
          group_id: 25,
        },
        {
          id: 1885,
          name: "Mazatlán",
          group_id: 25,
        },
        {
          id: 1877,
          name: "Mocorito",
          group_id: 25,
        },
        {
          id: 1874,
          name: "Navolato",
          group_id: 25,
        },
        {
          id: 1889,
          name: "Rosario",
          group_id: 25,
        },
        {
          id: 1880,
          name: "Salvador Alvarado",
          group_id: 25,
        },
        {
          id: 1890,
          name: "San Ignacio",
          group_id: 25,
        },
        {
          id: 1884,
          name: "Sinaloa",
          group_id: 25,
        },
      ],
    },
    {
      id: 26,
      name: "Sonora",
      var_id: 237,
      show_header: true,
      answer_options: [
        {
          id: 1937,
          name: "Aconchi",
          group_id: 26,
        },
        {
          id: 1911,
          name: "Agua Prieta",
          group_id: 26,
        },
        {
          id: 1961,
          name: "Alamos",
          group_id: 26,
        },
        {
          id: 1898,
          name: "Altar",
          group_id: 26,
        },
        {
          id: 1957,
          name: "Arivechi",
          group_id: 26,
        },
        {
          id: 1926,
          name: "Arizpe",
          group_id: 26,
        },
        {
          id: 1900,
          name: "Atil",
          group_id: 26,
        },
        {
          id: 1919,
          name: "Bacadéhuachi",
          group_id: 26,
        },
        {
          id: 1956,
          name: "Bacanora",
          group_id: 26,
        },
        {
          id: 1915,
          name: "Bacerac",
          group_id: 26,
        },
        {
          id: 1928,
          name: "Bacoachi",
          group_id: 26,
        },
        {
          id: 1944,
          name: "Bácum",
          group_id: 26,
        },
        {
          id: 1935,
          name: "Banámichi",
          group_id: 26,
        },
        {
          id: 1938,
          name: "Baviácora",
          group_id: 26,
        },
        {
          id: 1914,
          name: "Bavispe",
          group_id: 26,
        },
        {
          id: 1946,
          name: "Benito Juárez",
          group_id: 26,
        },
        {
          id: 1903,
          name: "Benjamín Hill",
          group_id: 26,
        },
        {
          id: 1897,
          name: "Caborca",
          group_id: 26,
        },
        {
          id: 1941,
          name: "Cajeme",
          group_id: 26,
        },
        {
          id: 1925,
          name: "Cananea",
          group_id: 26,
        },
        {
          id: 1893,
          name: "Carbó",
          group_id: 26,
        },
        {
          id: 1927,
          name: "Cucurpe",
          group_id: 26,
        },
        {
          id: 1920,
          name: "Cumpas",
          group_id: 26,
        },
        {
          id: 1930,
          name: "Divisaderos",
          group_id: 26,
        },
        {
          id: 1947,
          name: "Empalme",
          group_id: 26,
        },
        {
          id: 1945,
          name: "Etchojoa",
          group_id: 26,
        },
        {
          id: 1912,
          name: "Fronteras",
          group_id: 26,
        },
        {
          id: 1896,
          name: "General Plutarco Elías Calles",
          group_id: 26,
        },
        {
          id: 1918,
          name: "Granados",
          group_id: 26,
        },
        {
          id: 1948,
          name: "Guaymas",
          group_id: 26,
        },
        {
          id: 1891,
          name: "Hermosillo",
          group_id: 26,
        },
        {
          id: 1916,
          name: "Huachinera",
          group_id: 26,
        },
        {
          id: 1921,
          name: "Huásabas",
          group_id: 26,
        },
        {
          id: 1943,
          name: "Huatabampo",
          group_id: 26,
        },
        {
          id: 1934,
          name: "Huépac",
          group_id: 26,
        },
        {
          id: 1907,
          name: "Imuris",
          group_id: 26,
        },
        {
          id: 1950,
          name: "La Colorada",
          group_id: 26,
        },
        {
          id: 1909,
          name: "Magdalena",
          group_id: 26,
        },
        {
          id: 1951,
          name: "Mazatán",
          group_id: 26,
        },
        {
          id: 1922,
          name: "Moctezuma",
          group_id: 26,
        },
        {
          id: 1910,
          name: "Naco",
          group_id: 26,
        },
        {
          id: 1917,
          name: "Nácori Chico",
          group_id: 26,
        },
        {
          id: 1913,
          name: "Nacozari de García",
          group_id: 26,
        },
        {
          id: 1942,
          name: "Navojoa",
          group_id: 26,
        },
        {
          id: 1906,
          name: "Nogales",
          group_id: 26,
        },
        {
          id: 1960,
          name: "Onavas",
          group_id: 26,
        },
        {
          id: 1933,
          name: "Opodepe",
          group_id: 26,
        },
        {
          id: 1901,
          name: "Oquitoa",
          group_id: 26,
        },
        {
          id: 1905,
          name: "Pitiquito",
          group_id: 26,
        },
        {
          id: 1895,
          name: "Puerto Peñasco",
          group_id: 26,
        },
        {
          id: 1959,
          name: "Quiriego",
          group_id: 26,
        },
        {
          id: 1940,
          name: "Rayón",
          group_id: 26,
        },
        {
          id: 1958,
          name: "Rosario",
          group_id: 26,
        },
        {
          id: 1953,
          name: "Sahuaripa",
          group_id: 26,
        },
        {
          id: 1939,
          name: "San Felipe de Jesús",
          group_id: 26,
        },
        {
          id: 1949,
          name: "San Ignacio Río Muerto",
          group_id: 26,
        },
        {
          id: 1954,
          name: "San Javier",
          group_id: 26,
        },
        {
          id: 1894,
          name: "San Luis Río Colorado",
          group_id: 26,
        },
        {
          id: 1892,
          name: "San Miguel de Horcasitas",
          group_id: 26,
        },
        {
          id: 1929,
          name: "San Pedro de la Cueva",
          group_id: 26,
        },
        {
          id: 1924,
          name: "Santa Ana",
          group_id: 26,
        },
        {
          id: 1908,
          name: "Santa Cruz",
          group_id: 26,
        },
        {
          id: 1902,
          name: "Sáric",
          group_id: 26,
        },
        {
          id: 1955,
          name: "Soyopa",
          group_id: 26,
        },
        {
          id: 1952,
          name: "Suaqui Grande",
          group_id: 26,
        },
        {
          id: 1931,
          name: "Tepache",
          group_id: 26,
        },
        {
          id: 1904,
          name: "Trincheras",
          group_id: 26,
        },
        {
          id: 1899,
          name: "Tubutama",
          group_id: 26,
        },
        {
          id: 1936,
          name: "Ures",
          group_id: 26,
        },
        {
          id: 1923,
          name: "Villa Hidalgo",
          group_id: 26,
        },
        {
          id: 1932,
          name: "Villa Pesqueira",
          group_id: 26,
        },
        {
          id: 1962,
          name: "Yécora",
          group_id: 26,
        },
      ],
    },
    {
      id: 27,
      name: "Tabasco",
      var_id: 237,
      show_header: true,
      answer_options: [
        {
          id: 1978,
          name: "Balancán",
          group_id: 27,
        },
        {
          id: 1968,
          name: "Cárdenas",
          group_id: 27,
        },
        {
          id: 1972,
          name: "Centla",
          group_id: 27,
        },
        {
          id: 1963,
          name: "Centro",
          group_id: 27,
        },
        {
          id: 1966,
          name: "Comalcalco",
          group_id: 27,
        },
        {
          id: 1970,
          name: "Cunduacán",
          group_id: 27,
        },
        {
          id: 1979,
          name: "Emiliano Zapata",
          group_id: 27,
        },
        {
          id: 1967,
          name: "Huimanguillo",
          group_id: 27,
        },
        {
          id: 1975,
          name: "Jalapa",
          group_id: 27,
        },
        {
          id: 1964,
          name: "Jalpa de Méndez",
          group_id: 27,
        },
        {
          id: 1973,
          name: "Jonuta",
          group_id: 27,
        },
        {
          id: 1971,
          name: "Macuspana",
          group_id: 27,
        },
        {
          id: 1965,
          name: "Nacajuca",
          group_id: 27,
        },
        {
          id: 1969,
          name: "Paraíso",
          group_id: 27,
        },
        {
          id: 1976,
          name: "Tacotalpa",
          group_id: 27,
        },
        {
          id: 1974,
          name: "Teapa",
          group_id: 27,
        },
        {
          id: 1977,
          name: "Tenosique",
          group_id: 27,
        },
      ],
    },
    {
      id: 28,
      name: "Tamaulipas",
      var_id: 237,
      show_header: true,
      answer_options: [
        {
          id: 1992,
          name: "Abasolo",
          group_id: 28,
        },
        {
          id: 2016,
          name: "Aldama",
          group_id: 28,
        },
        {
          id: 2015,
          name: "Altamira",
          group_id: 28,
        },
        {
          id: 2021,
          name: "Antiguo Morelos",
          group_id: 28,
        },
        {
          id: 2012,
          name: "Burgos",
          group_id: 28,
        },
        {
          id: 2000,
          name: "Bustamante",
          group_id: 28,
        },
        {
          id: 2008,
          name: "Camargo",
          group_id: 28,
        },
        {
          id: 1983,
          name: "Casas",
          group_id: 28,
        },
        {
          id: 2014,
          name: "Ciudad Madero",
          group_id: 28,
        },
        {
          id: 1987,
          name: "Cruillas",
          group_id: 28,
        },
        {
          id: 2020,
          name: "El Mante",
          group_id: 28,
        },
        {
          id: 2019,
          name: "Gómez Farías",
          group_id: 28,
        },
        {
          id: 2017,
          name: "González",
          group_id: 28,
        },
        {
          id: 2005,
          name: "Guerrero",
          group_id: 28,
        },
        {
          id: 2007,
          name: "Gustavo Díaz Ordaz",
          group_id: 28,
        },
        {
          id: 1982,
          name: "Güémez",
          group_id: 28,
        },
        {
          id: 1994,
          name: "Hidalgo",
          group_id: 28,
        },
        {
          id: 1998,
          name: "Jaumave",
          group_id: 28,
        },
        {
          id: 1990,
          name: "Jiménez",
          group_id: 28,
        },
        {
          id: 1981,
          name: "Llera",
          group_id: 28,
        },
        {
          id: 1995,
          name: "Mainero",
          group_id: 28,
        },
        {
          id: 1984,
          name: "Matamoros",
          group_id: 28,
        },
        {
          id: 2011,
          name: "Méndez",
          group_id: 28,
        },
        {
          id: 2006,
          name: "Mier",
          group_id: 28,
        },
        {
          id: 2004,
          name: "Miguel Alemán",
          group_id: 28,
        },
        {
          id: 1999,
          name: "Miquihuana",
          group_id: 28,
        },
        {
          id: 2003,
          name: "Nuevo Laredo",
          group_id: 28,
        },
        {
          id: 2022,
          name: "Nuevo Morelos",
          group_id: 28,
        },
        {
          id: 2002,
          name: "Ocampo",
          group_id: 28,
        },
        {
          id: 1993,
          name: "Padilla",
          group_id: 28,
        },
        {
          id: 2001,
          name: "Palmillas",
          group_id: 28,
        },
        {
          id: 2009,
          name: "Reynosa",
          group_id: 28,
        },
        {
          id: 2010,
          name: "Río Bravo",
          group_id: 28,
        },
        {
          id: 1991,
          name: "San Carlos",
          group_id: 28,
        },
        {
          id: 1986,
          name: "San Fernando",
          group_id: 28,
        },
        {
          id: 1988,
          name: "San Nicolás",
          group_id: 28,
        },
        {
          id: 1989,
          name: "Soto la Marina",
          group_id: 28,
        },
        {
          id: 2013,
          name: "Tampico",
          group_id: 28,
        },
        {
          id: 1997,
          name: "Tula",
          group_id: 28,
        },
        {
          id: 1985,
          name: "Valle Hermoso",
          group_id: 28,
        },
        {
          id: 1980,
          name: "Victoria",
          group_id: 28,
        },
        {
          id: 1996,
          name: "Villagrán",
          group_id: 28,
        },
        {
          id: 2018,
          name: "Xicoténcatl",
          group_id: 28,
        },
      ],
    },
    {
      id: 29,
      name: "Tlaxcala",
      var_id: 237,
      show_header: true,
      answer_options: [
        {
          id: 2078,
          name: "Acuamanala de Miguel Hidalgo",
          group_id: 29,
        },
        {
          id: 2059,
          name: "Amaxac de Guerrero",
          group_id: 29,
        },
        {
          id: 2058,
          name: "Apetatitlán de Antonio Carvajal",
          group_id: 29,
        },
        {
          id: 2038,
          name: "Apizaco",
          group_id: 29,
        },
        {
          id: 2039,
          name: "Atlangatepec",
          group_id: 29,
        },
        {
          id: 2053,
          name: "Atltzayanca",
          group_id: 29,
        },
        {
          id: 2033,
          name: "Benito Juárez",
          group_id: 29,
        },
        {
          id: 2031,
          name: "Calpulalpan",
          group_id: 29,
        },
        {
          id: 2074,
          name: "Chiautempan",
          group_id: 29,
        },
        {
          id: 2062,
          name: "Contla de Juan Cuamatzi",
          group_id: 29,
        },
        {
          id: 2054,
          name: "Cuapiaxtla",
          group_id: 29,
        },
        {
          id: 2061,
          name: "Cuaxomulco",
          group_id: 29,
        },
        {
          id: 2055,
          name: "El Carmen Tequexquitla",
          group_id: 29,
        },
        {
          id: 2052,
          name: "Emiliano Zapata",
          group_id: 29,
        },
        {
          id: 2037,
          name: "Españita",
          group_id: 29,
        },
        {
          id: 2049,
          name: "Huamantla",
          group_id: 29,
        },
        {
          id: 2034,
          name: "Hueyotlipan",
          group_id: 29,
        },
        {
          id: 2024,
          name: "Ixtacuixtla de Mariano Matamoros",
          group_id: 29,
        },
        {
          id: 2056,
          name: "Ixtenco",
          group_id: 29,
        },
        {
          id: 2075,
          name: "La Magdalena Tlaltelulco",
          group_id: 29,
        },
        {
          id: 2051,
          name: "Lázaro Cárdenas",
          group_id: 29,
        },
        {
          id: 2080,
          name: "Mazatecochco de José María Morelos",
          group_id: 29,
        },
        {
          id: 2040,
          name: "Muñoz de Domingo Arenas",
          group_id: 29,
        },
        {
          id: 2036,
          name: "Nanacamilpa de Mariano Arista",
          group_id: 29,
        },
        {
          id: 2064,
          name: "Natívitas",
          group_id: 29,
        },
        {
          id: 2026,
          name: "Panotla",
          group_id: 29,
        },
        {
          id: 2073,
          name: "Papalotla de Xicohténcatl",
          group_id: 29,
        },
        {
          id: 2067,
          name: "San Damián Texóloc",
          group_id: 29,
        },
        {
          id: 2076,
          name: "San Francisco Tetlanohcan",
          group_id: 29,
        },
        {
          id: 2068,
          name: "San Jerónimo Zacualpan",
          group_id: 29,
        },
        {
          id: 2048,
          name: "San José Teacalco",
          group_id: 29,
        },
        {
          id: 2030,
          name: "San Juan Huactzinco",
          group_id: 29,
        },
        {
          id: 2070,
          name: "San Lorenzo Axocomanitla",
          group_id: 29,
        },
        {
          id: 2043,
          name: "San Lucas Tecopilco",
          group_id: 29,
        },
        {
          id: 2082,
          name: "San Pablo del Monte",
          group_id: 29,
        },
        {
          id: 2032,
          name: "Sanctórum de Lázaro Cárdenas",
          group_id: 29,
        },
        {
          id: 2025,
          name: "Santa Ana Nopalucan",
          group_id: 29,
        },
        {
          id: 2065,
          name: "Santa Apolonia Teacalco",
          group_id: 29,
        },
        {
          id: 2071,
          name: "Santa Catarina Ayometla",
          group_id: 29,
        },
        {
          id: 2079,
          name: "Santa Cruz Quilehtla",
          group_id: 29,
        },
        {
          id: 2060,
          name: "Santa Cruz Tlaxcala",
          group_id: 29,
        },
        {
          id: 2029,
          name: "Santa Isabel Xiloxoxtla",
          group_id: 29,
        },
        {
          id: 2081,
          name: "Tenancingo",
          group_id: 29,
        },
        {
          id: 2077,
          name: "Teolocholco",
          group_id: 29,
        },
        {
          id: 2063,
          name: "Tepetitla de Lardizábal",
          group_id: 29,
        },
        {
          id: 2028,
          name: "Tepeyanco",
          group_id: 29,
        },
        {
          id: 2050,
          name: "Terrenate",
          group_id: 29,
        },
        {
          id: 2041,
          name: "Tetla de la Solidaridad",
          group_id: 29,
        },
        {
          id: 2066,
          name: "Tetlatlahuca",
          group_id: 29,
        },
        {
          id: 2023,
          name: "Tlaxcala",
          group_id: 29,
        },
        {
          id: 2035,
          name: "Tlaxco",
          group_id: 29,
        },
        {
          id: 2046,
          name: "Tocatlán",
          group_id: 29,
        },
        {
          id: 2027,
          name: "Totolac",
          group_id: 29,
        },
        {
          id: 2047,
          name: "Tzompantepec",
          group_id: 29,
        },
        {
          id: 2045,
          name: "Xaloztoc",
          group_id: 29,
        },
        {
          id: 2042,
          name: "Xaltocan",
          group_id: 29,
        },
        {
          id: 2072,
          name: "Xicohtzinco",
          group_id: 29,
        },
        {
          id: 2044,
          name: "Yauhquemehcan",
          group_id: 29,
        },
        {
          id: 2069,
          name: "Zacatelco",
          group_id: 29,
        },
        {
          id: 2057,
          name: "Ziltlaltépec de Trinidad Sánchez Santos",
          group_id: 29,
        },
      ],
    },
    {
      id: 30,
      name: "Veracruz de Ignacio de la Llave",
      var_id: 237,
      show_header: true,
      answer_options: [
        {
          id: 2091,
          name: "Acajete",
          group_id: 30,
        },
        {
          id: 2100,
          name: "Acatlán",
          group_id: 30,
        },
        {
          id: 2275,
          name: "Acayucan",
          group_id: 30,
        },
        {
          id: 2104,
          name: "Actopan",
          group_id: 30,
        },
        {
          id: 2252,
          name: "Acula",
          group_id: 30,
        },
        {
          id: 2215,
          name: "Acultzingo",
          group_id: 30,
        },
        {
          id: 2289,
          name: "Agua Dulce",
          group_id: 30,
        },
        {
          id: 2145,
          name: "Álamo Temapache",
          group_id: 30,
        },
        {
          id: 2187,
          name: "Alpatláhuac",
          group_id: 30,
        },
        {
          id: 2103,
          name: "Alto Lucero de Gutiérrez Barrios",
          group_id: 30,
        },
        {
          id: 2168,
          name: "Altotonga",
          group_id: 30,
        },
        {
          id: 2246,
          name: "Alvarado",
          group_id: 30,
        },
        {
          id: 2253,
          name: "Amatitlán",
          group_id: 30,
        },
        {
          id: 2232,
          name: "Amatlán de los Reyes",
          group_id: 30,
        },
        {
          id: 2267,
          name: "Angel R. Cabada",
          group_id: 30,
        },
        {
          id: 2109,
          name: "Apazapan",
          group_id: 30,
        },
        {
          id: 2214,
          name: "Aquila",
          group_id: 30,
        },
        {
          id: 2219,
          name: "Astacinga",
          group_id: 30,
        },
        {
          id: 2221,
          name: "Atlahuilco",
          group_id: 30,
        },
        {
          id: 2233,
          name: "Atoyac",
          group_id: 30,
        },
        {
          id: 2206,
          name: "Atzacan",
          group_id: 30,
        },
        {
          id: 2167,
          name: "Atzalan",
          group_id: 30,
        },
        {
          id: 2087,
          name: "Ayahualulco",
          group_id: 30,
        },
        {
          id: 2089,
          name: "Banderilla",
          group_id: 30,
        },
        {
          id: 2141,
          name: "Benito Juárez",
          group_id: 30,
        },
        {
          id: 2201,
          name: "Boca del Río",
          group_id: 30,
        },
        {
          id: 2186,
          name: "Calcahualco",
          group_id: 30,
        },
        {
          id: 2196,
          name: "Camarón de Tejeda",
          group_id: 30,
        },
        {
          id: 2213,
          name: "Camerino Z. Mendoza",
          group_id: 30,
        },
        {
          id: 2249,
          name: "Carlos A. Carrillo",
          group_id: 30,
        },
        {
          id: 2235,
          name: "Carrillo Puerto",
          group_id: 30,
        },
        {
          id: 2148,
          name: "Castillo de Teayo",
          group_id: 30,
        },
        {
          id: 2269,
          name: "Catemaco",
          group_id: 30,
        },
        {
          id: 2149,
          name: "Cazones de Herrera",
          group_id: 30,
        },
        {
          id: 2132,
          name: "Cerro Azul",
          group_id: 30,
        },
        {
          id: 2259,
          name: "Chacaltianguis",
          group_id: 30,
        },
        {
          id: 2123,
          name: "Chalma",
          group_id: 30,
        },
        {
          id: 2122,
          name: "Chiconamel",
          group_id: 30,
        },
        {
          id: 2175,
          name: "Chiconquiaco",
          group_id: 30,
        },
        {
          id: 2144,
          name: "Chicontepec",
          group_id: 30,
        },
        {
          id: 2274,
          name: "Chinameca",
          group_id: 30,
        },
        {
          id: 2129,
          name: "Chinampa de Gorostiza",
          group_id: 30,
        },
        {
          id: 2190,
          name: "Chocamán",
          group_id: 30,
        },
        {
          id: 2124,
          name: "Chontla",
          group_id: 30,
        },
        {
          id: 2151,
          name: "Chumatlán",
          group_id: 30,
        },
        {
          id: 2125,
          name: "Citlaltépetl",
          group_id: 30,
        },
        {
          id: 2096,
          name: "Coacoatzintla",
          group_id: 30,
        },
        {
          id: 2155,
          name: "Coahuitlán",
          group_id: 30,
        },
        {
          id: 2105,
          name: "Coatepec",
          group_id: 30,
        },
        {
          id: 2288,
          name: "Coatzacoalcos",
          group_id: 30,
        },
        {
          id: 2157,
          name: "Coatzintla",
          group_id: 30,
        },
        {
          id: 2227,
          name: "Coetzala",
          group_id: 30,
        },
        {
          id: 2177,
          name: "Colipa",
          group_id: 30,
        },
        {
          id: 2194,
          name: "Comapa",
          group_id: 30,
        },
        {
          id: 2209,
          name: "Córdoba",
          group_id: 30,
        },
        {
          id: 2250,
          name: "Cosamaloapan de Carpio",
          group_id: 30,
        },
        {
          id: 2107,
          name: "Cosautlán de Carvajal",
          group_id: 30,
        },
        {
          id: 2188,
          name: "Coscomatepec",
          group_id: 30,
        },
        {
          id: 2284,
          name: "Cosoleacaque",
          group_id: 30,
        },
        {
          id: 2236,
          name: "Cotaxtla",
          group_id: 30,
        },
        {
          id: 2152,
          name: "Coxquihui",
          group_id: 30,
        },
        {
          id: 2156,
          name: "Coyutla",
          group_id: 30,
        },
        {
          id: 2230,
          name: "Cuichapa",
          group_id: 30,
        },
        {
          id: 2229,
          name: "Cuitláhuac",
          group_id: 30,
        },
        {
          id: 2128,
          name: "El Higo",
          group_id: 30,
        },
        {
          id: 2108,
          name: "Emiliano Zapata",
          group_id: 30,
        },
        {
          id: 2158,
          name: "Espinal",
          group_id: 30,
        },
        {
          id: 2154,
          name: "Filomeno Mata",
          group_id: 30,
        },
        {
          id: 2208,
          name: "Fortín",
          group_id: 30,
        },
        {
          id: 2161,
          name: "Gutiérrez Zamora",
          group_id: 30,
        },
        {
          id: 2291,
          name: "Hidalgotitlán",
          group_id: 30,
        },
        {
          id: 2185,
          name: "Huatusco",
          group_id: 30,
        },
        {
          id: 2136,
          name: "Huayacocotla",
          group_id: 30,
        },
        {
          id: 2268,
          name: "Hueyapan de Ocampo",
          group_id: 30,
        },
        {
          id: 2217,
          name: "Huiloapan de Cuauhtémoc",
          group_id: 30,
        },
        {
          id: 2245,
          name: "Ignacio de la Llave",
          group_id: 30,
        },
        {
          id: 2137,
          name: "Ilamatlán",
          group_id: 30,
        },
        {
          id: 2263,
          name: "Isla",
          group_id: 30,
        },
        {
          id: 2126,
          name: "Ixcatepec",
          group_id: 30,
        },
        {
          id: 2086,
          name: "Ixhuacán de los Reyes",
          group_id: 30,
        },
        {
          id: 2143,
          name: "Ixhuatlán de Madero",
          group_id: 30,
        },
        {
          id: 2192,
          name: "Ixhuatlán del Café",
          group_id: 30,
        },
        {
          id: 2286,
          name: "Ixhuatlán del Sureste",
          group_id: 30,
        },
        {
          id: 2205,
          name: "Ixhuatlancillo",
          group_id: 30,
        },
        {
          id: 2251,
          name: "Ixmatlahuacan",
          group_id: 30,
        },
        {
          id: 2207,
          name: "Ixtaczoquitlán",
          group_id: 30,
        },
        {
          id: 2166,
          name: "Jalacingo",
          group_id: 30,
        },
        {
          id: 2179,
          name: "Jalcomulco",
          group_id: 30,
        },
        {
          id: 2281,
          name: "Jáltipan",
          group_id: 30,
        },
        {
          id: 2199,
          name: "Jamapa",
          group_id: 30,
        },
        {
          id: 2292,
          name: "Jesús Carranza",
          group_id: 30,
        },
        {
          id: 2097,
          name: "Jilotepec",
          group_id: 30,
        },
        {
          id: 2260,
          name: "José Azueta",
          group_id: 30,
        },
        {
          id: 2264,
          name: "Juan Rodríguez Clara",
          group_id: 30,
        },
        {
          id: 2102,
          name: "Juchique de Ferrer",
          group_id: 30,
        },
        {
          id: 2113,
          name: "La Antigua",
          group_id: 30,
        },
        {
          id: 2189,
          name: "La Perla",
          group_id: 30,
        },
        {
          id: 2174,
          name: "Landero y Coss",
          group_id: 30,
        },
        {
          id: 2293,
          name: "Las Choapas",
          group_id: 30,
        },
        {
          id: 2169,
          name: "Las Minas",
          group_id: 30,
        },
        {
          id: 2092,
          name: "Las Vigas de Ramírez",
          group_id: 30,
        },
        {
          id: 2247,
          name: "Lerdo de Tejada",
          group_id: 30,
        },
        {
          id: 2240,
          name: "Los Reyes",
          group_id: 30,
        },
        {
          id: 2225,
          name: "Magdalena",
          group_id: 30,
        },
        {
          id: 2210,
          name: "Maltrata",
          group_id: 30,
        },
        {
          id: 2198,
          name: "Manlio Fabio Altamirano",
          group_id: 30,
        },
        {
          id: 2204,
          name: "Mariano Escobedo",
          group_id: 30,
        },
        {
          id: 2163,
          name: "Martínez de la Torre",
          group_id: 30,
        },
        {
          id: 2153,
          name: "Mecatlán",
          group_id: 30,
        },
        {
          id: 2271,
          name: "Mecayapan",
          group_id: 30,
        },
        {
          id: 2200,
          name: "Medellín de Bravo",
          group_id: 30,
        },
        {
          id: 2099,
          name: "Miahuatlán",
          group_id: 30,
        },
        {
          id: 2290,
          name: "Minatitlán",
          group_id: 30,
        },
        {
          id: 2173,
          name: "Misantla",
          group_id: 30,
        },
        {
          id: 2239,
          name: "Mixtla de Altamirano",
          group_id: 30,
        },
        {
          id: 2287,
          name: "Moloacán",
          group_id: 30,
        },
        {
          id: 2285,
          name: "Nanchital de Lázaro Cárdenas del Río",
          group_id: 30,
        },
        {
          id: 2098,
          name: "Naolinco",
          group_id: 30,
        },
        {
          id: 2226,
          name: "Naranjal",
          group_id: 30,
        },
        {
          id: 2127,
          name: "Naranjos Amatlán",
          group_id: 30,
        },
        {
          id: 2172,
          name: "Nautla",
          group_id: 30,
        },
        {
          id: 2211,
          name: "Nogales",
          group_id: 30,
        },
        {
          id: 2278,
          name: "Oluta",
          group_id: 30,
        },
        {
          id: 2228,
          name: "Omealca",
          group_id: 30,
        },
        {
          id: 2202,
          name: "Orizaba",
          group_id: 30,
        },
        {
          id: 2256,
          name: "Otatitlán",
          group_id: 30,
        },
        {
          id: 2283,
          name: "Oteapan",
          group_id: 30,
        },
        {
          id: 2119,
          name: "Ozuluama de Mascareñas",
          group_id: 30,
        },
        {
          id: 2273,
          name: "Pajapan",
          group_id: 30,
        },
        {
          id: 2115,
          name: "Pánuco",
          group_id: 30,
        },
        {
          id: 2160,
          name: "Papantla",
          group_id: 30,
        },
        {
          id: 2112,
          name: "Paso de Ovejas",
          group_id: 30,
        },
        {
          id: 2234,
          name: "Paso del Macho",
          group_id: 30,
        },
        {
          id: 2088,
          name: "Perote",
          group_id: 30,
        },
        {
          id: 2121,
          name: "Platón Sánchez",
          group_id: 30,
        },
        {
          id: 2261,
          name: "Playa Vicente",
          group_id: 30,
        },
        {
          id: 2159,
          name: "Poza Rica de Hidalgo",
          group_id: 30,
        },
        {
          id: 2116,
          name: "Pueblo Viejo",
          group_id: 30,
        },
        {
          id: 2110,
          name: "Puente Nacional",
          group_id: 30,
        },
        {
          id: 2203,
          name: "Rafael Delgado",
          group_id: 30,
        },
        {
          id: 2090,
          name: "Rafael Lucio",
          group_id: 30,
        },
        {
          id: 2212,
          name: "Río Blanco",
          group_id: 30,
        },
        {
          id: 2255,
          name: "Saltabarranca",
          group_id: 30,
        },
        {
          id: 2223,
          name: "San Andrés Tenejapan",
          group_id: 30,
        },
        {
          id: 2265,
          name: "San Andrés Tuxtla",
          group_id: 30,
        },
        {
          id: 2276,
          name: "San Juan Evangelista",
          group_id: 30,
        },
        {
          id: 2164,
          name: "San Rafael",
          group_id: 30,
        },
        {
          id: 2262,
          name: "Santiago Sochiapan",
          group_id: 30,
        },
        {
          id: 2266,
          name: "Santiago Tuxtla",
          group_id: 30,
        },
        {
          id: 2277,
          name: "Sayula de Alemán",
          group_id: 30,
        },
        {
          id: 2183,
          name: "Sochiapa",
          group_id: 30,
        },
        {
          id: 2279,
          name: "Soconusco",
          group_id: 30,
        },
        {
          id: 2216,
          name: "Soledad Atzompa",
          group_id: 30,
        },
        {
          id: 2197,
          name: "Soledad de Doblado",
          group_id: 30,
        },
        {
          id: 2270,
          name: "Soteapan",
          group_id: 30,
        },
        {
          id: 2131,
          name: "Tamalín",
          group_id: 30,
        },
        {
          id: 2135,
          name: "Tamiahua",
          group_id: 30,
        },
        {
          id: 2117,
          name: "Tampico Alto",
          group_id: 30,
        },
        {
          id: 2134,
          name: "Tancoco",
          group_id: 30,
        },
        {
          id: 2130,
          name: "Tantima",
          group_id: 30,
        },
        {
          id: 2120,
          name: "Tantoyuca",
          group_id: 30,
        },
        {
          id: 2272,
          name: "Tatahuicapan de Juárez",
          group_id: 30,
        },
        {
          id: 2170,
          name: "Tatatila",
          group_id: 30,
        },
        {
          id: 2162,
          name: "Tecolutla",
          group_id: 30,
        },
        {
          id: 2238,
          name: "Tehuipango",
          group_id: 30,
        },
        {
          id: 2118,
          name: "Tempoal",
          group_id: 30,
        },
        {
          id: 2181,
          name: "Tenampa",
          group_id: 30,
        },
        {
          id: 2171,
          name: "Tenochtitlán",
          group_id: 30,
        },
        {
          id: 2106,
          name: "Teocelo",
          group_id: 30,
        },
        {
          id: 2193,
          name: "Tepatlaxco",
          group_id: 30,
        },
        {
          id: 2101,
          name: "Tepetlán",
          group_id: 30,
        },
        {
          id: 2133,
          name: "Tepetzintla",
          group_id: 30,
        },
        {
          id: 2222,
          name: "Tequila",
          group_id: 30,
        },
        {
          id: 2139,
          name: "Texcatepec",
          group_id: 30,
        },
        {
          id: 2241,
          name: "Texhuacán",
          group_id: 30,
        },
        {
          id: 2280,
          name: "Texistepec",
          group_id: 30,
        },
        {
          id: 2242,
          name: "Tezonapa",
          group_id: 30,
        },
        {
          id: 2243,
          name: "Tierra Blanca",
          group_id: 30,
        },
        {
          id: 2147,
          name: "Tihuatlán",
          group_id: 30,
        },
        {
          id: 2142,
          name: "Tlachichilco",
          group_id: 30,
        },
        {
          id: 2257,
          name: "Tlacojalpan",
          group_id: 30,
        },
        {
          id: 2094,
          name: "Tlacolulan",
          group_id: 30,
        },
        {
          id: 2254,
          name: "Tlacotalpan",
          group_id: 30,
        },
        {
          id: 2184,
          name: "Tlacotepec de Mejía",
          group_id: 30,
        },
        {
          id: 2244,
          name: "Tlalixcoyan",
          group_id: 30,
        },
        {
          id: 2084,
          name: "Tlalnelhuayocan",
          group_id: 30,
        },
        {
          id: 2180,
          name: "Tlaltetela",
          group_id: 30,
        },
        {
          id: 2165,
          name: "Tlapacoyan",
          group_id: 30,
        },
        {
          id: 2218,
          name: "Tlaquilpa",
          group_id: 30,
        },
        {
          id: 2224,
          name: "Tlilapan",
          group_id: 30,
        },
        {
          id: 2191,
          name: "Tomatlán",
          group_id: 30,
        },
        {
          id: 2095,
          name: "Tonayán",
          group_id: 30,
        },
        {
          id: 2182,
          name: "Totutla",
          group_id: 30,
        },
        {
          id: 2248,
          name: "Tres Valles",
          group_id: 30,
        },
        {
          id: 2146,
          name: "Tuxpan",
          group_id: 30,
        },
        {
          id: 2258,
          name: "Tuxtilla",
          group_id: 30,
        },
        {
          id: 2111,
          name: "Ursulo Galván",
          group_id: 30,
        },
        {
          id: 2294,
          name: "Uxpanapa",
          group_id: 30,
        },
        {
          id: 2178,
          name: "Vega de Alatorre",
          group_id: 30,
        },
        {
          id: 2114,
          name: "Veracruz",
          group_id: 30,
        },
        {
          id: 2093,
          name: "Villa Aldama",
          group_id: 30,
        },
        {
          id: 2083,
          name: "Xalapa",
          group_id: 30,
        },
        {
          id: 2085,
          name: "Xico",
          group_id: 30,
        },
        {
          id: 2220,
          name: "Xoxocotla",
          group_id: 30,
        },
        {
          id: 2231,
          name: "Yanga",
          group_id: 30,
        },
        {
          id: 2176,
          name: "Yecuatla",
          group_id: 30,
        },
        {
          id: 2140,
          name: "Zacualpan",
          group_id: 30,
        },
        {
          id: 2282,
          name: "Zaragoza",
          group_id: 30,
        },
        {
          id: 2195,
          name: "Zentla",
          group_id: 30,
        },
        {
          id: 2237,
          name: "Zongolica",
          group_id: 30,
        },
        {
          id: 2138,
          name: "Zontecomatlán de López y Fuentes",
          group_id: 30,
        },
        {
          id: 2150,
          name: "Zozocolco de Hidalgo",
          group_id: 30,
        },
      ],
    },
    {
      id: 31,
      name: "Yucatán",
      var_id: 237,
      show_header: true,
      answer_options: [
        {
          id: 2377,
          name: "Abalá",
          group_id: 31,
        },
        {
          id: 2308,
          name: "Acanceh",
          group_id: 31,
        },
        {
          id: 2400,
          name: "Akil",
          group_id: 31,
        },
        {
          id: 2318,
          name: "Baca",
          group_id: 31,
        },
        {
          id: 2322,
          name: "Bokobá",
          group_id: 31,
        },
        {
          id: 2345,
          name: "Buctzotz",
          group_id: 31,
        },
        {
          id: 2321,
          name: "Cacalchén",
          group_id: 31,
        },
        {
          id: 2360,
          name: "Calotmul",
          group_id: 31,
        },
        {
          id: 2314,
          name: "Cansahcab",
          group_id: 31,
        },
        {
          id: 2391,
          name: "Cantamayec",
          group_id: 31,
        },
        {
          id: 2305,
          name: "Celestún",
          group_id: 31,
        },
        {
          id: 2347,
          name: "Cenotillo",
          group_id: 31,
        },
        {
          id: 2397,
          name: "Chacsinkín",
          group_id: 31,
        },
        {
          id: 2362,
          name: "Chankom",
          group_id: 31,
        },
        {
          id: 2383,
          name: "Chapab",
          group_id: 31,
        },
        {
          id: 2368,
          name: "Chemax",
          group_id: 31,
        },
        {
          id: 2363,
          name: "Chichimilá",
          group_id: 31,
        },
        {
          id: 2297,
          name: "Chicxulub Pueblo",
          group_id: 31,
        },
        {
          id: 2394,
          name: "Chikindzonot",
          group_id: 31,
        },
        {
          id: 2374,
          name: "Chocholá",
          group_id: 31,
        },
        {
          id: 2388,
          name: "Chumayel",
          group_id: 31,
        },
        {
          id: 2299,
          name: "Conkal",
          group_id: 31,
        },
        {
          id: 2366,
          name: "Cuncunul",
          group_id: 31,
        },
        {
          id: 2337,
          name: "Cuzamá",
          group_id: 31,
        },
        {
          id: 2382,
          name: "Dzán",
          group_id: 31,
        },
        {
          id: 2312,
          name: "Dzemul",
          group_id: 31,
        },
        {
          id: 2326,
          name: "Dzidzantún",
          group_id: 31,
        },
        {
          id: 2342,
          name: "Dzilam de Bravo",
          group_id: 31,
        },
        {
          id: 2341,
          name: "Dzilam González",
          group_id: 31,
        },
        {
          id: 2351,
          name: "Dzitás",
          group_id: 31,
        },
        {
          id: 2348,
          name: "Dzoncauich",
          group_id: 31,
        },
        {
          id: 2358,
          name: "Espita",
          group_id: 31,
        },
        {
          id: 2378,
          name: "Halachó",
          group_id: 31,
        },
        {
          id: 2334,
          name: "Hocabá",
          group_id: 31,
        },
        {
          id: 2324,
          name: "Hoctún",
          group_id: 31,
        },
        {
          id: 2338,
          name: "Homún",
          group_id: 31,
        },
        {
          id: 2340,
          name: "Huhí",
          group_id: 31,
        },
        {
          id: 2301,
          name: "Hunucmá",
          group_id: 31,
        },
        {
          id: 2298,
          name: "Ixil",
          group_id: 31,
        },
        {
          id: 2333,
          name: "Izamal",
          group_id: 31,
        },
        {
          id: 2306,
          name: "Kanasín",
          group_id: 31,
        },
        {
          id: 2352,
          name: "Kantunil",
          group_id: 31,
        },
        {
          id: 2365,
          name: "Kaua",
          group_id: 31,
        },
        {
          id: 2303,
          name: "Kinchil",
          group_id: 31,
        },
        {
          id: 2375,
          name: "Kopomá",
          group_id: 31,
        },
        {
          id: 2387,
          name: "Mama",
          group_id: 31,
        },
        {
          id: 2381,
          name: "Maní",
          group_id: 31,
        },
        {
          id: 2371,
          name: "Maxcanú",
          group_id: 31,
        },
        {
          id: 2389,
          name: "Mayapán",
          group_id: 31,
        },
        {
          id: 2295,
          name: "Mérida",
          group_id: 31,
        },
        {
          id: 2319,
          name: "Mocochá",
          group_id: 31,
        },
        {
          id: 2317,
          name: "Motul",
          group_id: 31,
        },
        {
          id: 2379,
          name: "Muna",
          group_id: 31,
        },
        {
          id: 2320,
          name: "Muxupip",
          group_id: 31,
        },
        {
          id: 2373,
          name: "Opichén",
          group_id: 31,
        },
        {
          id: 2385,
          name: "Oxkutzcab",
          group_id: 31,
        },
        {
          id: 2343,
          name: "Panabá",
          group_id: 31,
        },
        {
          id: 2393,
          name: "Peto",
          group_id: 31,
        },
        {
          id: 2296,
          name: "Progreso",
          group_id: 31,
        },
        {
          id: 2350,
          name: "Quintana Roo",
          group_id: 31,
        },
        {
          id: 2357,
          name: "Río Lagartos",
          group_id: 31,
        },
        {
          id: 2380,
          name: "Sacalum",
          group_id: 31,
        },
        {
          id: 2372,
          name: "Samahil",
          group_id: 31,
        },
        {
          id: 2344,
          name: "San Felipe",
          group_id: 31,
        },
        {
          id: 2339,
          name: "Sanahcat",
          group_id: 31,
        },
        {
          id: 2386,
          name: "Santa Elena",
          group_id: 31,
        },
        {
          id: 2336,
          name: "Seyé",
          group_id: 31,
        },
        {
          id: 2315,
          name: "Sinanché",
          group_id: 31,
        },
        {
          id: 2355,
          name: "Sotuta",
          group_id: 31,
        },
        {
          id: 2346,
          name: "Sucilá",
          group_id: 31,
        },
        {
          id: 2353,
          name: "Sudzal",
          group_id: 31,
        },
        {
          id: 2330,
          name: "Suma",
          group_id: 31,
        },
        {
          id: 2395,
          name: "Tahdziú",
          group_id: 31,
        },
        {
          id: 2325,
          name: "Tahmek",
          group_id: 31,
        },
        {
          id: 2390,
          name: "Teabo",
          group_id: 31,
        },
        {
          id: 2376,
          name: "Tecoh",
          group_id: 31,
        },
        {
          id: 2332,
          name: "Tekal de Venegas",
          group_id: 31,
        },
        {
          id: 2328,
          name: "Tekantó",
          group_id: 31,
        },
        {
          id: 2399,
          name: "Tekax",
          group_id: 31,
        },
        {
          id: 2354,
          name: "Tekit",
          group_id: 31,
        },
        {
          id: 2367,
          name: "Tekom",
          group_id: 31,
        },
        {
          id: 2311,
          name: "Telchac Pueblo",
          group_id: 31,
        },
        {
          id: 2313,
          name: "Telchac Puerto",
          group_id: 31,
        },
        {
          id: 2327,
          name: "Temax",
          group_id: 31,
        },
        {
          id: 2359,
          name: "Temozón",
          group_id: 31,
        },
        {
          id: 2331,
          name: "Tepakán",
          group_id: 31,
        },
        {
          id: 2304,
          name: "Tetiz",
          group_id: 31,
        },
        {
          id: 2329,
          name: "Teya",
          group_id: 31,
        },
        {
          id: 2384,
          name: "Ticul",
          group_id: 31,
        },
        {
          id: 2307,
          name: "Timucuy",
          group_id: 31,
        },
        {
          id: 2361,
          name: "Tinum",
          group_id: 31,
        },
        {
          id: 2364,
          name: "Tixcacalcupul",
          group_id: 31,
        },
        {
          id: 2323,
          name: "Tixkokob",
          group_id: 31,
        },
        {
          id: 2396,
          name: "Tixmehuac",
          group_id: 31,
        },
        {
          id: 2309,
          name: "Tixpéhual",
          group_id: 31,
        },
        {
          id: 2356,
          name: "Tizimín",
          group_id: 31,
        },
        {
          id: 2349,
          name: "Tunkás",
          group_id: 31,
        },
        {
          id: 2398,
          name: "Tzucacab",
          group_id: 31,
        },
        {
          id: 2370,
          name: "Uayma",
          group_id: 31,
        },
        {
          id: 2302,
          name: "Ucú",
          group_id: 31,
        },
        {
          id: 2310,
          name: "Umán",
          group_id: 31,
        },
        {
          id: 2369,
          name: "Valladolid",
          group_id: 31,
        },
        {
          id: 2335,
          name: "Xocchel",
          group_id: 31,
        },
        {
          id: 2392,
          name: "Yaxcabá",
          group_id: 31,
        },
        {
          id: 2300,
          name: "Yaxkukul",
          group_id: 31,
        },
        {
          id: 2316,
          name: "Yobaín",
          group_id: 31,
        },
      ],
    },
    {
      id: 32,
      name: "Zacatecas",
      var_id: 237,
      show_header: true,
      answer_options: [
        {
          id: 2456,
          name: "Apozol",
          group_id: 32,
        },
        {
          id: 2455,
          name: "Apulco",
          group_id: 32,
        },
        {
          id: 2447,
          name: "Atolinga",
          group_id: 32,
        },
        {
          id: 2449,
          name: "Benito Juárez",
          group_id: 32,
        },
        {
          id: 2414,
          name: "Calera",
          group_id: 32,
        },
        {
          id: 2413,
          name: "Cañitas de Felipe Pescador",
          group_id: 32,
        },
        {
          id: 2434,
          name: "Chalchihuites",
          group_id: 32,
        },
        {
          id: 2404,
          name: "Concepción del Oro",
          group_id: 32,
        },
        {
          id: 2420,
          name: "Cuauhtémoc",
          group_id: 32,
        },
        {
          id: 2441,
          name: "El Plateado de Joaquín Amaro",
          group_id: 32,
        },
        {
          id: 2407,
          name: "El Salvador",
          group_id: 32,
        },
        {
          id: 2430,
          name: "Fresnillo",
          group_id: 32,
        },
        {
          id: 2419,
          name: "Genaro Codina",
          group_id: 32,
        },
        {
          id: 2416,
          name: "General Enrique Estrada",
          group_id: 32,
        },
        {
          id: 2410,
          name: "General Francisco R. Murguía",
          group_id: 32,
        },
        {
          id: 2422,
          name: "General Pánfilo Natera",
          group_id: 32,
        },
        {
          id: 2417,
          name: "Guadalupe",
          group_id: 32,
        },
        {
          id: 2444,
          name: "Huanusco",
          group_id: 32,
        },
        {
          id: 2442,
          name: "Jalpa",
          group_id: 32,
        },
        {
          id: 2436,
          name: "Jerez",
          group_id: 32,
        },
        {
          id: 2435,
          name: "Jiménez del Teul",
          group_id: 32,
        },
        {
          id: 2408,
          name: "Juan Aldama",
          group_id: 32,
        },
        {
          id: 2457,
          name: "Juchipila",
          group_id: 32,
        },
        {
          id: 2424,
          name: "Loreto",
          group_id: 32,
        },
        {
          id: 2423,
          name: "Luis Moya",
          group_id: 32,
        },
        {
          id: 2406,
          name: "Mazapil",
          group_id: 32,
        },
        {
          id: 2405,
          name: "Melchor Ocampo",
          group_id: 32,
        },
        {
          id: 2453,
          name: "Mezquital del Oro",
          group_id: 32,
        },
        {
          id: 2409,
          name: "Miguel Auza",
          group_id: 32,
        },
        {
          id: 2446,
          name: "Momax",
          group_id: 32,
        },
        {
          id: 2437,
          name: "Monte Escobedo",
          group_id: 32,
        },
        {
          id: 2402,
          name: "Morelos",
          group_id: 32,
        },
        {
          id: 2458,
          name: "Moyahua de Estrada",
          group_id: 32,
        },
        {
          id: 2454,
          name: "Nochistlán de Mejía",
          group_id: 32,
        },
        {
          id: 2426,
          name: "Noria de Ángeles",
          group_id: 32,
        },
        {
          id: 2421,
          name: "Ojocaliente",
          group_id: 32,
        },
        {
          id: 2415,
          name: "Pánuco",
          group_id: 32,
        },
        {
          id: 2428,
          name: "Pinos",
          group_id: 32,
        },
        {
          id: 2411,
          name: "Río Grande",
          group_id: 32,
        },
        {
          id: 2432,
          name: "Sain Alto",
          group_id: 32,
        },
        {
          id: 2451,
          name: "Santa María de la Paz",
          group_id: 32,
        },
        {
          id: 2431,
          name: "Sombrerete",
          group_id: 32,
        },
        {
          id: 2438,
          name: "Susticacán",
          group_id: 32,
        },
        {
          id: 2443,
          name: "Tabasco",
          group_id: 32,
        },
        {
          id: 2448,
          name: "Tepechitlán",
          group_id: 32,
        },
        {
          id: 2440,
          name: "Tepetongo",
          group_id: 32,
        },
        {
          id: 2450,
          name: "Teúl de González Ortega",
          group_id: 32,
        },
        {
          id: 2445,
          name: "Tlaltenango de Sánchez Román",
          group_id: 32,
        },
        {
          id: 2418,
          name: "Trancoso",
          group_id: 32,
        },
        {
          id: 2452,
          name: "Trinidad García de la Cadena",
          group_id: 32,
        },
        {
          id: 2433,
          name: "Valparaíso",
          group_id: 32,
        },
        {
          id: 2403,
          name: "Vetagrande",
          group_id: 32,
        },
        {
          id: 2412,
          name: "Villa de Cos",
          group_id: 32,
        },
        {
          id: 2427,
          name: "Villa García",
          group_id: 32,
        },
        {
          id: 2425,
          name: "Villa González Ortega",
          group_id: 32,
        },
        {
          id: 2429,
          name: "Villa Hidalgo",
          group_id: 32,
        },
        {
          id: 2439,
          name: "Villanueva",
          group_id: 32,
        },
        {
          id: 2401,
          name: "Zacatecas",
          group_id: 32,
        },
      ],
    },
  ];
};

export const closeMedia = () => {
  if (fromStorage("START-VIDEO")) {
    navigator.mediaDevices
      .getUserMedia({
        audio: true,
        video: true,
      })
      .then(function (mediaStream) {
        mediaStream.getTracks().map((e) => e.stop());

        const video = document.querySelector("video");
        if (video !== undefined && typeof video === "object") {
          video.srcObject = mediaStream;
          video.onloadedmetadata = () => {
            video.stop();
          };
        }
      })
      .catch((error) => {});
  }
};

if (localStorage.getItem("window.dataLayer")) {
  window.dataLayer = JSON.parse(localStorage.getItem("window.dataLayer"));
  if (window.dataLayer.length > 100) {
    localStorage.removeItem("window.dataLayer");
    window.dataLayer = window.dataLayer || [];
  }
} else window.dataLayer = window.dataLayer || [];

export const addAnalitycsEvents = (
  price,
  transactionId,
  name,
  item_id,
  item_category,
  item_category2,
  dynamicName = false
) => {
  

  let id = "";
  try {
    if (dynamicName) {
      let p = name.split(" ");
      p.forEach((e) => {
        id += e.charAt(0).toUpperCase();
      });
    }
  } catch (error) {}

  const item = {
    item_name: name,
    item_id: id + item_id,
    price,
    item_category,
    item_category2: "Comercio electronico",
    quality: 1,
  };

  window.dataLayer.push({
    event: "purchase",
    ecommerce: {
      currency: "MXN",
      value: price,
      transaction_id: transactionId,
      items: [item],
    },
  });
  localStorage.setItem("window.dataLayer", JSON.stringify(window.dataLayer));
  localStorage.removeItem("selectedContract");
 };
