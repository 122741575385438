import { notification } from "antd";
import PropTypes from "prop-types";
import { createContext, useReducer } from "react";
import { PATH_PAGE } from "../routes/paths";
// utils
import axiosInstance, { processActionService } from "../utils/axios";
import { OPENPAY_ERROR } from "../utils/openpay-errors";

// ----------------------------------------------------------------------

const initialState = {
  payPending: false,
  servicePrice: null,
  servicePricePending: false,
  payInfo: null,
};

const handlers = {
  START_FETCH_RESPONSE_TIME: (state, action) => {
    return {
      ...state,
      payPending: true,
    };
  },

  STOP_FETCH_RESPONSE_TIME: (state, action) => {
    return {
      ...state,
      payPending: false,
    };
  },
  STOP: (state, action) => {
    return {
      ...state,
      payInfo: action.payload,
      payPending: false,
    };
  },

  START_FETCH_COST: (state, action) => {
    return {
      ...state,
      servicePrice: 0,
      servicePricePending: true,
    };
  },

  STOP_FETCH_COST: (state, action) => {
    return {
      ...state,
      servicePricePending: false,
      servicePrice: action.payload,
    };
  },
  RESET_FETCH_COST: (state, action) => {
    return {
      ...state,
      servicePricePending: false,
      servicePrice: null,
    };
  },
};

const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

const PayContext = createContext({
  ...initialState,
});

// ----------------------------------------------------------------------

PayProvider.propTypes = {
  children: PropTypes.node,
};

function PayProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const proccessPay = async (formData, callback) => {
    dispatch({
      type: "START_FETCH_RESPONSE_TIME",
      payload: {},
    });
    console.log(formData);
    processActionService(
      `/payment/${formData.service_type}/${formData.service_id}/pay`,
      "post",
      formData
    )
      .then((response) => {
        const { success, message, result } = response;
        if (success) {
          if (result && result.redirect_url) {
            if (typeof callback === "function")
              callback({
                link: true,
                url: result.redirect_url,
              });
          } else if (typeof callback === "function") {
            notification.success({
              message: "Enhorabuena",
              description: message,
            });
            callback({
              link: false,
              url: PATH_PAGE.create_contract_successfull,
            });
          }
          dispatch({
            type: "STOP_FETCH_RESPONSE_TIME",
            payload: {},
          });
        }
      })
      .catch((e) => {
        
       
        notification.error({
          message: "Error en el proceso de pago",
          description: OPENPAY_ERROR[e.message]
            ? OPENPAY_ERROR[e.message]
            : e.message,
        });
        
        dispatch({
          type: "STOP_FETCH_RESPONSE_TIME",
          payload: {},
        });
      });
  };

  const checkPay = async (transactionID, callback) => {
    dispatch({
      type: "START_FETCH_RESPONSE_TIME",
      payload: {},
    });

    processActionService(`/payment/${transactionID}/get-charge`, "get", {})
      .then((response) => {
        const { success, message, result } = response;
        dispatch({
          type: "STOP",
          payload: result,
        });
      })
      .catch((e) => {
        notification.error({
          message: "Error en el proceso de pago",
          description: OPENPAY_ERROR[e.message]
            ? OPENPAY_ERROR[e.message]
            : e.message,
        });

        dispatch({
          type: "STOP",
          payload: e,
        });
      });
  };

  const fetchPrice = async (service_type, response_time) => {
    dispatch({
      type: "START_FETCH_COST",
      payload: {},
    });
    let url = `/services/${service_type}/cost`;
    if (response_time) url += `?response_time=${response_time}`;
    processActionService(url, "get", {})
      .then((response) => {
        const { result } = response;
        console.log(response);
        dispatch({
          type: "STOP_FETCH_COST",
          payload: result.cost,
        });
      })
      .catch((e) => {
        /*
        notification.error({
          message: "Error en el proceso de pago",
          description: e.message.message,
        });
        */
        dispatch({
          type: "STOP_FETCH_COST",
          payload: 0,
        });
      });
  };

  const resetPrice = async (service_type, response_time) => {
    dispatch({
      type: "RESET_FETCH_COST",
    });
  };
  return (
    <PayContext.Provider
      value={{
        ...state,
        proccessPay,
        checkPay,
        fetchPrice,resetPrice
      }}
    >
      {children}
    </PayContext.Provider>
  );
}

export { PayContext, PayProvider };
