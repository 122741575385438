import { Link, Link as RouterLink } from "react-router-dom";

import logo from "../../assets/images/logo-2.webp";
import { PATH_PAGE } from "../../routes/paths";

import facebook from "../../assets/images/facebook.webp";
import instagran from "../../assets/images/instagran.webp";
import useResponsive from "../../hooks/useResponsive";

// ----------------------------------------------------------------------

const xs = {
  with:60,
  marginBottom:10
}

const lg = {
  position: 'absolute',
  left: '10'
}

export default function MainFooter() {
  const { islg, isxs, issm, ismd } = useResponsive();

  return (
    <footer className="bg-dark text-white py-5 ">
     {ismd && <img src={logo} width="250" height="auto" alt="logo" style={lg} />}
      <div className="container">
        <div className="small text-center text-white">
        {!ismd && <img src={logo} width="250" height="auto" alt="logo" style={xs} />}
          <p style={{ textAlign: 'center', marginBottom: 10 }}>
            <a href="https://www.facebook.com/milic.legal/" style={{ marginRight: 10 }} ><img src={facebook} width="40" height="auto" alt="logo" /></a>
            <a href="https://www.instagram.com/milic.legal/" style={{ marginLeft: 10 }} > <img src={instagran} width="40" height="auto" alt="logo" /></a>
          </p>
          <Link to={PATH_PAGE.term}>Términos y condiciones </Link> |{" "}
          <Link to={PATH_PAGE.privacy}> Aviso de privavidad </Link>
          <br />
          Copyright © {new Date().getFullYear()} -{" "}
          <a href="https://softok2.com">Softok2</a>{" "}
        </div>
      </div>
    </footer>
  );
}

