/* eslint-disable */
// ----------------------------------------------------------------------

import { STORAGE_KEY } from "../config";

export const fromStorage = (key) => {
  return localStorage.getItem(STORAGE_KEY + "-" + key.toLowerCase());
};

export const toStorage = (key, value) => {
  return localStorage.setItem(STORAGE_KEY + "-" + key.toLowerCase(), value);
};

export const cleanStorage = (key) => {
  return localStorage.removeItem(STORAGE_KEY + "-" + key.toLowerCase());
};
