// @antd
import { Button, Col, Result, Row, Typography } from "antd";
import { useEffect } from "react";
import Page from "../../components/Page";
import { PATH_PAGE } from "../../routes/paths";
import { cleanStorage } from "../../utils/storage";

export default function ReviewSuccessCreate() {
  useEffect(() => {
    console.log("--------");
    console.log(window.dataLayer);
    console.log("--------");
    cleanStorage("FORM");
    cleanStorage("selectedCard");
  }, []);

  return (
    <Page title="Mi-LIC | Contrato Creado">
      <Row>
        <Col style={{ margin: "auto" }} xl={12} lg={12} md={24} sm={24} xs={24}>
          <section
            style={{
              marginTop: 30,
              marginBottom: 30,
              padding: "4% 10% 4% 10%",
            }}
          >
            <div>
              <Result
                status="success"
                title="Documentación enviada exitosamente"
                subTitle={
                  <>
                   
                    <Typography.Text style={{ fontSize: 16 }}>
                      Tu documentación fue enviada para cotización de nuestros expertos.
                      Por favor revisa constantemente tu correo electrónico en tu bandeja de entrada y/o
                       correo no deseado para encontrar las indicaciones de nuestros abogados. Recuerda registrarnos como una dirección segura.
                    </Typography.Text>
                    <br /> <br />
                    <Typography.Text style={{ fontSize: 16 }}>
                      ¡Gracias por tu confianza y por utilizar los servicios de
                      mi-lic!
                    </Typography.Text>
                  </>
                }
                extra={[
                  <Button href={"/"} type="primary" key="console">
                    <Typography.Text style={{ color: "#FFF" }}>
                      {" "}
                      Ir al inicio{" "}
                    </Typography.Text>
                  </Button>,
                  <Button href={PATH_PAGE.review} key="buy">
                    Revisar otro documento
                  </Button>,
                ]}
              />
            </div>
          </section>
        </Col>
      </Row>
    </Page>
  );
}
