import { useContext } from "react";
import { PayContext } from "../contexts/PayContext";

const usePay = () => {
  const context = useContext(PayContext);
  if (!context) throw new Error("Auth context must be use inside AuthProvider");

  return context;
};

export default usePay;
