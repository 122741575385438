import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";

import "antd/dist/antd.min.css";
import { Provider as ReduxProvider } from "react-redux";
import { PersistGate } from "redux-persist/lib/integration/react";
import { store, persistor } from "./redux/store";
import { AuthProvider } from "./contexts/JWTContext";
import { cleanStorage } from "./utils/storage";
import "antd/dist/antd.variable.min.css";
import { ConfigProvider } from "antd";
import "./index.css";
import "moment/locale/es-mx";
import locale from "antd/es/locale/es_ES";

ConfigProvider.config({
  theme: {
    primaryColor: "#EFAc51",
  },
});

cleanStorage("FORM");
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ConfigProvider locale={locale}>
      <AuthProvider>
        <BrowserRouter>
          <HelmetProvider>
            <ReduxProvider store={store}>
              <PersistGate loading={null} persistor={persistor}>
                <App />
              </PersistGate>
            </ReduxProvider>
          </HelmetProvider>
        </BrowserRouter>
      </AuthProvider>
    </ConfigProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
